.company {
  .sec-00 {
    background: #f5f5f9;
    padding: 150px 0;
    position: relative;
    z-index: -2;
    @include mq {
      padding: 80px 0;
    }
    &::after {
      content: "";
      background: url(/img/company/company_bg02.png) no-repeat left top;
      background-size: 100% 100%;
      height: 563px;
      width: 938px;
      position: absolute;
      top: 0;
      right: 0;
      @include mq {
        background: url(/img/company/company_bg03.png) no-repeat left top;
        background-size: 100% auto;
        height: 525px;
        width: 238px;
        left: 0;
        bottom: 0;
        top: auto;
        right: auto;
        z-index: -1;
      }
    }
    dl {
      align-items: center;
      .txt01 {
        font-size: 3rem;
        font-weight: 200;
        @include lh(20, 38);
        @include ls(75);
        margin: 60px 0 30px;
        @include mq {
          font-size: 2.5rem;
          margin: 40px 0 20px;
        }
      }
      .txt02 {
        @include ls(75);
        line-height: 2;
        @include mq {
          width: 100%;
        }
      }
    }
  }
  .sec-01 {
    background: #0b0b0b;
    padding: 150px 0 135px;
    position: relative;
    @include mq {
      padding: 80px 0 0;
    }
    .blc {
      color: $white;
      line-height: 30px;
      width: 40%;
      @include mq {
        width: 100%;
      }
      .ttl01 .en {
        white-space: nowrap;
        @include mq {
          white-space: inherit;
          line-height: 50px;
        }
      }
    }
    .txt01 {
      font-size: 2rem;
      font-weight: 400;
      @include lh(20, 38);
      @include ls(75);
      margin: 50px 0;
      @include mq {
        font-size: 2.5rem;
        margin: 30px 0;
      }
    }
    .txt02 {
      @include ls(50);
      font-weight: 100;
      @include mq {
        font-size: 1.6rem;
        line-height: 2;
      }
    }
    .name-txt {
      padding: 30px 0 0;
      width: 181px;
      @include mq {
        padding-top: 30px;
        width: 170px;
      }
    }
    .person-img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: auto;
      height: 100%;
      overflow: hidden;
      @include mq {
        position: static;
        right: auto;
        bottom: auto;
        margin: 20px auto 0;
        width: 100%;
        text-align: center;
      }
    }
  }
  .sec-02 {
    background: url(/img/company/company_bg01.jpg) no-repeat center bottom;
    background-size: cover;
    padding: 15vh 0 55vh;
    color: $white;
    text-align: center;
    @include mq {
      background: url(/img/company/company_bg01_sp.jpg) no-repeat center bottom;
      background-size: cover;
      padding: 10vh 0 50vh;
      text-align: left;
    }
    .txt01 {
      font-size: 1.6rem;
      font-weight: 100;
      @include ls(50);
      @include lh(14, 30);
    }
    .txt02 {
      font-family: $font-min;
      font-size: 2rem;
      @include ls(50);
      @include lh(20, 38);
      padding: 60px 0;
      @include mq {
        font-size: 1.8rem;
        padding: 35px 0;
      }
    }
    .txt03 {
      font-family: $font-min;
      font-weight: 100;
      @include ls(50);
      font-size: 1.5rem;
    }
  }
  .sec-03 {
    background: #f5f5f9;
    padding: 140px 0;
    position: relative;
    @include mq {
      padding: 100px 0 50px;
      z-index: -2;
    }
    &::after {
      content: "";
      background: url(/img/company/company_bg02.png) no-repeat left top;
      background-size: 100% 100%;
      height: 563px;
      width: 938px;
      position: absolute;
      top: 0;
      right: 0;
      @include mq {
        background: url(/img/company/company_bg03.png) no-repeat left top;
        background-size: 100% auto;
        height: 525px;
        width: 238px;
        left: 0;
        bottom: 0;
        top: auto;
        right: auto;
        z-index: -1;
      }
    }
    .history-box {
      .item {
        border-bottom: 1px solid #ccc;
        position: relative;
        @include mq {
          border-bottom: none;
          border-left: 1px solid #ccc;
          padding: 0 0 60px 35px;
        }
        .ttl {
          color: #d9d9e4;
          font-size: 6rem;
          font-family: $font-en;
          font-weight: 100;
          font-style: italic;
          line-height: 70px;
          position: absolute;
          left: 0;
          bottom: 15px;
          z-index: 2;
          @include mq {
            font-size: 3rem;
            line-height: 40px;
            position: relative;
            left: auto;
            bottom: auto;
          }
          &::after {
            content: "";
            background: #2167a0;
            border-radius: 50%;
            height: 10px;
            width: 10px;
            position: absolute;
            left: 171px;
            bottom: -20px;
            @include mq {
              height: 8px;
              width: 8px;
              left: -39.5px;
              top: 18px;
              bottom: auto;
            }
          }
        }
        .box {
          border-left: 1px solid #ccc;
          margin-left: 175px;
          padding: 50px 0 50px 130px;
          @include mq {
            border-left: none;
            margin: 0;
            padding: 0;
          }
          position: relative;
          &.fl-item {
            align-items: center;
            .box-txt {
              width: 40%;
              @include mq {
                width: 100%;
              }
            }
          }
          .img {
            background: #f5f5f9;
            color: $white;
            height: 280px;
            width: 45%;
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            @include mq {
              margin-top: 25px;
              height: 160px;
              width: 100%;
            }
            img {
              vertical-align: top;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              margin: 0 auto;
              top: 50%;
              position: relative;
            }
          }
        }
        .box-txt {
          font-size: 1.4rem;
          line-height: 22px;
          .txt {
            font-size: 2rem;
            line-height: 30px;
            color: #2167a0;
            padding-bottom: 20px;
            @include mq {
              font-size: 1.8rem;
              padding: 10px 0 5px;
            }
          }
        }
      }
    }
    .history-ttl {
      background: $white;
      border: 1px solid #2167a0;
      color: #2167a0;
      font-family: $font-en;
      padding: 15px 0;
      margin-bottom: 30px;
      position: relative;
      text-align: center;
      transition: ease 0.3s;
      &::after,
      &::before {
        content: "";
        background: #2167a0;
        height: 2px;
        width: 15px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        transition: ease 0.3s;
      }
      &::after {
        transform: translateY(-50%) rotate(90deg);
      }
      &.active {
        background: #2167a0;
        border: 1px solid $white;
        color: $white;
        &::before {
          background: $white;
        }
        &::after {
          display: none;
        }
      }
    }
    .history-item {
      display: block;
      @include mq {
        display: none;
        margin: 0 15px 20px;
      }
      &.first {
        .item {
          &:nth-child(1) {
            .box {
              @include mq(pc) {
                background: url(/img/common/dot_bg.png) repeat-y left top;
                border-left: none;
                padding-top: 0;
              }
            }
          }
        }
      }
      &.last {
        .item {
          @include mq(pc) {
            &:last-child {
              &::after {
                content: "";
                background: url(/img/common/dot_bg.png) repeat-y left top;
                background-size: 100%;
                width: 3px;
                height: 55px;
                position: absolute;
                left: 174px;
                bottom: -55px;
              }
            }
          }
        }
      }
    }
  }
  .sec-04 {
    padding: 140px 0;
    @include mq {
      padding: 70px 0;
    }
    .tab-list01 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 60px;
      @include mq {
        margin-bottom: 30px;
      }
      li {
        width: 50%;
        a {
          background: $white;
          border: 1px solid #2167a0;
          color: #2167a0;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          font-size: 1.6rem;
          height: 60px;
          justify-content: center;
          text-align: center;
          width: 100%;
          transition: ease 0.3s;
          @include mq {
            font-size: 1.7rem;
            height: 50px;
          }
          &.active,
          &:hover {
            background: #2167a0;
            color: $white;
          }
        }
      }
    }
    .tab-list02 {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
      @include mq {
        margin-bottom: 30px;
      }
      li {
        margin-right: 30px;
        a {
          font-size: 1.8rem;
          color: #babcbd;
          transition: ease 0.3s;
          padding-bottom: 5px;
          position: relative;
          @include mq {
            font-size: 1.6rem;
          }
          &::after {
            content: "";
            background: #2167a0;
            height: 2px;
            width: 0;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: ease 0.3s;
          }
          &.active,
          &:hover {
            color: #2167a0;
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
    .info-box {
      dl {
        font-size: 1.6rem;
        line-height: 1.8;
        @include mq {
          font-size: 1.4rem;
        }
        dt {
          border-bottom: 1px solid #ccc;
          color: #2167a0;
          line-height: 26px;
          padding: 20px 0;
          width: 16%;
          @include mq {
            line-height: 24px;
            padding: 10px 0;
            width: 26%;
          }
          &:first-child {
            border-top: 1px solid #ccc;
          }
        }
        dd {
          border-top: 1px solid #ccc;
          padding: 20px 0;
          width: 82%;
          @include mq {
            width: 70%;
            padding: 10px 0;
            font-size: 1.5rem;
            line-height: 2;
          }
          &:last-child {
            border-bottom: 1px solid #ccc;
          }
        }
      }
    }
    // .tab-item {
    //     display: none;
    //     &.tab-item01.current,
    //     &.tab-item02.current {
    //         display: block;
    //     }
    // }
    .diagram-box {
      background: #f5f5f9;
      padding: 70px;
      margin-top: 85px;
      @include mq {
        padding: 50px 35px;
        margin: 50px -35px 0;
      }
      h3 {
        color: #2167a0;
        font-size: 2rem;
        font-weight: normal;
        line-height: 30px;
        padding-bottom: 60px;
        text-align: center;
        @include mq {
          text-align: center;
          padding-bottom: 30px;
          font-size: 1.8rem;
        }
      }
      p {
        img {
          backface-visibility: hidden;
        }
      }
      .img-jp {
        display: inherit;
      }
      .img-en {
        display: none;
      }
    }
  }
  .sec-05 {
    .map {
      height: 600px;
      position: relative;
      @include mq {
        height: auto;
        padding-bottom: 250px;
      }
      .gm-svpc,
      .gmnoprint {
        display: none;
      }
      #js-map {
        height: 100%;
        @include mq {
          height: 400px;
        }
      }
      .js-map iframe {
        border: 0;
        width: 100%;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
      }
      .company-box {
        background: $white;
        padding: 42px 40px 35px;
        position: absolute;
        transition: .3s ease opacity;
        opacity: 0;
        pointer-events: none;
        @include mq(pc) {
          width: 280px;
          top: 50%;
          right: 7%;
          transform: translateY(-50%);
        }
        @include mq {
          text-align: center;
          width: calc(100% - 40px);
          padding: 20px;
          top: auto;
          bottom: 50px;
          left: 20px;
        }
        &.active {
          opacity: 1;
          pointer-events: auto;
        }
        &._ghana {
          p {
            font-size: 1.3rem;
          }
        }
        img {
          width: 180px;
          height: 180px;
          margin: 0 auto 25px;
          display: block;
          @include mq {
            width: 90px;
            height: 90px;
            margin-bottom: 10px;
          }
        }
        h3 {
          color: $main-color;
          font-size: 2rem;
          @include ls(100);
          text-align: center;
          margin-bottom: 21px;
          @include mq {
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 1.5rem;
          @include mq {
            font-size: 1.8rem;
          }
          &:nth-of-type(1) {
            @include ls(50);
            margin-bottom: 13px;
          }
          &:nth-of-type(2) {
            color: $main-color;
            font-family: $font-en;
            font-weight: 600;
            @include ls(100);
            line-height: 1.35;
          }
        }
      }
    }
  }
  &.en_lang {
    .sec-04 {
      .diagram-box {
        .img-jp {
          display: none;
        }
        .img-en {
          display: inherit;
        }
      }
    }
  }
}
