.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: $pc-header-height;
  @include transition;
  @media screen and (max-height: 700px) and (min-width: 769px) {
    height: 75px;
  }
  @include mq {
    height: $sp-header-height;
  }
  &.fixed {
    background: $white;
  }
  &.ptn2,
  &.active {
    .h-inner {
      @include mq {
        padding: 10px 15px 0;
      }
      .logo {
        a {
          img {
            opacity: 0;
          }
          &:after {
            opacity: 1;
          }
        }
      }
      .h-nav {
        ul li a {
          @include link_color($white);
          svg path {
            fill: $white;
          }
        }
      }
    }
  }
  &.ptn3 {
    height: 80px;
    background: $white;
    @include mq {
      height: 75px;
    }
    .menu {
      background: $main-color;
      .icon {
        span {
          background: $white;
        }
      }
    }
  }
  &.ptn4 {
    .h-inner {
      .h-nav {
        ul li a {
          @include link_color($white);
          svg path {
            fill: $white;
          }
        }
      }
    }
  }
  .h-inner {
    padding: 0 40px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq(spc) {
      padding-left: 20px;
    }
    @include mq {
      padding: 0 15px;
    }
    .logo {
      max-width: 290px;
      @include mq(spc) {
        padding-right: 20px;
      }
      a {
        height: 24px;
        display: block;
        position: relative;
        img {
          transition: 1s ease opacity;
        }
        &:after {
          content: '';
          width: 100%;
          height: 24px;
          background: url(/img/common/logo02.png) no-repeat;
          background-size: contain;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          z-index: 1;
          transition: 1s ease opacity;
        }
      }
    }
    .nav-box {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .h-nav {
      margin-left: 20px;
      height: 50%;
      display: flex;
      ul {
        height: 100%;
        display: flex;
        li {
          @include mq(pc) {
            width: 40px;
          }
          @include mq {
            margin-top: 5px;
          }
          &.contact,
          &.document {
            @include mq {
              display: none;
            }
          }
          &.lang {
            font-family: $font-en;
          }
          +li {
            margin-left: 20px;
          }
          a {
            @include ls(100);
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &:hover {
              >span:after {
                background: #2469a1;
              }
            }
            svg {
              width: 23px;
              height: 23px;
              margin-bottom: 7px;
              @include mq {
                margin-bottom: 4px;
              }
              path {
                transition: .3s ease;
              }
            }
            >span {
              font-size: 1rem;
              font-weight: bold;
              position: relative;
              &:after {
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: transparent;
                position: absolute;
                bottom: -9px;
                left: calc(50% - 2px);
                transition: .3s ease background;
              }
            }
          }
        }
      }
    }
  }
  .menu {
    background: $white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: .3s ease;
    @include mq {
      margin-left: 20px;
      width: 45px;
      height: 45px;
    }
    &:hover {
      @include mq(pc) {
        background: #3ca0e6;
        .icon {
          span {
            background: $white;
          }
        }
      }
    }
    &.active {
      .icon {
        transform: skew(0, 0);
        span {
          &:nth-child(1) {
            width: 20px;
            top: 6px;
            left: -2px;
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            width: 0;
          }
          &:nth-child(3) {
            width: 20px;
            top: 6px;
            left: -2px;
            transform: rotate(-45deg);
          }
        }
      }
    }
    .icon {
      width: 17px;
      height: 15px;
      transform: skew(0, -20deg);
      transition: .3s ease;
      position: relative;
      span {
        background: $main-color;
        width: 100%;
        height: 2px;
        display: block;
        position: absolute;
        left: 0;
        transition: .3s ease;
        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 6px;
        }
        &:nth-child(3) {
          top: 13px;
        }
      }
    }
  }
  .menu-conts {
    color: $white;
    background: $main-color;
    width: 100%;
    height: 100%;
    padding-top: $pc-header-height;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    transition: .3s ease opacity;
    @include mq(pc) {
      padding-right: 50vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include mq {
      align-items: flex-start;
      padding: 90px 40px 0;
    }
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
    .nav-list {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding-bottom: 10vw;
      @include mq {
        width: 100%;
        flex-direction: column;
        padding-bottom: 0;
      }
      li {
        &.li-news {
          @include mq {
            display: none;
          }
        }
        &.li-recruit {
          @include mq(pc) {
            display: none;
          }
        }
        &._ex {
          a {
            dd {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              &:after {
                content: '';
                width: 15px;
                height: 14px;
                background: url(/img/common/icon_exlink.svg) no-repeat;
                margin-left: 15px;
                display: block;
              }
            }
          }
        }
        +li {
          margin-top: 8%;
          @include mq {
            margin-top: 10%;
          }
        }
        a {
          @include link_color($white);
          opacity: 1;
          display: block;
          &:hover {
            opacity: .5;
          }
        }
        dl {
          display: flex;
          align-items: baseline;
        }
        dt {
          font-family: $font-en;
          font-size: 4.5rem;
          font-style: italic;
          letter-spacing: 0.04em;
          @include ls(80);
          @include mq {
            display: none;
          }
        }
        dd {
          @include ls(50);
          @include mq(pc) {
            margin-left: 20px;
            font-size: 1.4rem;
          }
          @include mq {
            font-size: 2rem;
          }
        }
      }
    }
    .menu-movie {
      width: 50vw;
      height: 100vh;
      position: absolute;
      top: 0;
      right: 0;
      overflow: hidden;
      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        min-width: 100%;
        min-height: 100%;
      }
    }
    .other-nav {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      li {
        width: calc(50% - 5px);
        height: 50px;
        a {
          font-size: 1.8rem;
          width: 100%;
          height: 100%;
          border: 1px solid $white;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            margin: 2px 11px 0 0;
          }
        }
        &.contact {
          a {
            @include link_color($main-color);
            background: $white;
            svg {
              width: 20px;
              path {
                fill: $main-color;
              }
            }
          }
        }
        &.document {
          a {
            @include link_color($white);
            svg {
              width: 12px;
            }
          }
        }
      }
    }
    .sns-def {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      dt {
        color: #67a0ce;
        font-size: 1.4rem;
        @include ls(50);
      }
      dd {
        margin-left: 20px;
        a {
          font-size: 2.4rem;
          @include link_color($white);
          display: block;
        }
      }
    }
    .news-box {
      background: #2c7aba;
      padding: 38px 50px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      .ttl-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      h3 {
        font-size: 2rem;
        font-weight: 500;
        @include ls(120);
      }
      .more {
        a {
          @include link_color($white);
          font-family: $font-en;
          font-size: 1.2rem;
          font-weight: 500;
          @include ls(75);
          width: 90px;
          height: 30px;
          border: 1px solid $white;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:after {
            content: '';
            width: 10px;
            height: 10px;
            background: url(/img/common/icon_arrow.svg) no-repeat;
            background-size: contain;
            margin-left: 11px;
            display: block;
          }
        }
      }
      .news-conts {
        font-size: 1.6rem;
        margin-top: 20px;
        .date {
          font-family: $font-en;
          font-weight: 500;
          @include ls(100);
        }
        .text {
          margin-top: 6px;
          a {
            @include link_color($white);
            @include lh(12, 20);
            text-decoration: underline;
          }
        }
      }
    }
    .recruit {
      width: 300px;
      height: 135px;
      position: fixed;
      right: 0;
      bottom: 0;
      a {
        display: block;
        &:hover {
          @include mq(pc) {
            transform: scale(1.05);
          }
        }
      }
      .bnr-jp {
        display: inherit;
      }
      .bnr-en {
        display: none;
      }
    }
    .business {
      width: 300px;
      height: 135px;
      position: fixed;
      right: 0;
      bottom: 135px;
      cursor: pointer;
      transition: .3s ease;
        &:hover {
          @include mq(pc) {
            transform: scale(1.05);
            transition: .3s ease;
          }
        }
      .bnr-jp {
        display: inherit;
      }
      .bnr-en {
        display: none;
      }
    }
    .sns-list {
      position: fixed;
      left: 50px;
      bottom: 50px;
      z-index: 999;
      li {
        +li {
          margin-top: 30px;
        }
        a {
          font-size: 2.2rem;
          @include link_color($white);
          &:hover {
            opacity: .6;
          }
        }
        &:first-child {
          a i {
            font-family: $font-awesome4;
            font-weight: 300;
          }
        }
      }
    }
  }
}
.en_lang {
  .header {
    .menu-conts {
      .nav-list {
        li {
          dd {
            @include mq(pc) {
              display: none;
            }
          }
        }
      }
      .recruit.pc {
        .bnr-jp {
          display: none;
        }
        .bnr-en {
          display: inherit;
        }
      }
    }
  }
}
.header-active {
  .h-inner {
    .logo {
      a {
        img {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
    }
    .sns-list {
      li {
        a {
          color: $white;
        }
      }
    }
    .lang-list {
      li {
        &:not(:first-child) {
          border-left-color: $white;
        }
        a {
          color: $white;
        }
      }
    }
  }
}
.sns-modal {
  text-align: center;
  background: $main-color;
  transition: .3s ease opacity;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  .close-btn {
    background: $white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: .3s ease;
    position: fixed;
    right: 50px;
    top: 50px;
    @include mq {
      width: 45px;
      height: 45px;
      right: 15px;
      top: 18px;
    }
    &:hover {
      @include mq(pc) {
        background: #3ca0e6;
        .icon {
          span {
            background: $white;
          }
        }
      }
    }
    .icon {
      width: 17px;
      height: 15px;
      transition: .3s ease;
      position: relative;
      span {
        background: $main-color;
        width: 100%;
        height: 2px;
        display: block;
        position: absolute;
        left: 0;
        transition: .3s ease;
        &:nth-child(1) {
          width: 20px;
          top: 6px;
          left: -2px;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          width: 20px;
          top: 6px;
          left: -2px;
          transform: rotate(-45deg);
        }
      }
    }
  }
  h4 {
    font-family: $font-en;
    font-size: 2.4rem;
    font-weight: 100;
    color: $white;
    @include ls(100);
    @include mq {
      font-size: 2.2rem;
    }
    span {
      margin-right: 5px;
    }
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    @include mq {
      margin-top: 20px;
    }
    li {
      &:first-child {
        margin-right: 60px;
        @include mq {
          margin-right: 30px;
        }
      }
      a {
        font-size: 1.6rem;
        letter-spacing: 0.05em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $white;
        @include mq {
          font-size: 1.4rem;
        }
        &:hover {
          color: #4a97d5;
        }
        &::before {
          content: '\f105';
          color: $white;
          font-family: "FontAwesome";
          font-size: 1.8rem;
          font-weight: 100;
          width: 24px;
          height: 24px;
          padding-left: 2px;
          margin-right: 10px;
          border-radius: 50%;
          border: 1px solid #d8dae3;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          transition: .3s ease background;
        }
      }
    }
  }
}
