$breakpoints: (
  "pc": "screen and (min-width: 769px)",
  "mpc": "screen and (max-width: 1400px) and (min-width: 769px)",
  "lpc": "screen and (max-width: 1200px) and (min-width: 769px)",
  "spc": "screen and (max-width: 1080px) and (min-width: 769px)",
  "sspc": "screen and (max-width: 980px) and (min-width: 769px)",
  "lsp": "screen and (max-width: 768px)",
  "msp": "screen and (max-width: 450px)",
  "ssp": "screen and (max-width: 320px)",
) !default;
@mixin mq($breakpoint: lsp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

$main-color: #2167a0;
$key-color: #000;
$text-color: #333;

/* メモ：下のこのへん消す */

$gray01: #4e5c6b;
$gray02: #707f90;
$gray03: #acacac;
$color-bg01: #f7f7f7;
$color-bg02: #f6fbf7;
$white: #ffffff;
$black01: #000;
$blue01: #2a44c2;
$blue02: #3ca0e6;
$color-yt: #cd201f;
$color-fb: #3b5998;
$color-tw: #55acee;
$color-ln: #00b900;
$red: #bc002e;
$hover-white: rgba(255, 255, 255, 0.15);
$hover-main: #124d7d;
$main-width: 1360px;
$pc-min-width: 1080px;
$pc-header-height: 125px;
$sp-header-height: 70px;
$font-sans_serif: "Noto Sans JP", "游ゴシック体", "Yu Gothic", YuGothic,
  "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo,
  "MS Pゴシック", "MS PGothic", sans-serif;
$font-min: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro",
  "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
$font-yugothic: 游ゴシック, "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;
$font-en: "Ubuntu", "Noto Sans JP", sans-serif;
$font-awesome: "Font Awesome 5 Free";
$font-awesome4: "FontAwesome";
@mixin inner {
  width: 100%;
  max-width: $main-width + 40;
  margin: 0 auto;
  padding: 0 40px;
  @include mq {
    padding: 0;
    width: 100%;
    padding: 0 35px;
  }
}

@mixin lh($fontSize, $height) {
  line-height: $height / $fontSize;
}

@mixin ls($tracking) {
  letter-spacing: #{$tracking / 1000}em;
}

@mixin transition($prop: all, $dur: 0.5, $func: ease, $delay: 0) {
  transition: $prop #{$dur}s $func #{$delay}s;
}

@mixin link_color($color) {
  &,
  &:visited,
  &:hover {
    color: $color;
  }
}

@mixin arrow($width, $bold, $color, $deg) {
  border-style: solid;
  border-width: 0 #{$bold}px #{$bold}px 0;
  content: "";
  display: inline-block;
  border-color: $color;
  @if ($deg== "left") {
    transform: rotate(135deg);
  } @else if ($deg== "top") {
    transform: rotate(225deg);
  } @else if ($deg== "right") {
    transform: rotate(-45deg);
  } @else if ($deg== "bottom") {
    transform: rotate(45deg);
  }
  height: #{$width}px;
  width: #{$width}px;
}

@mixin object-fit($option) {
  width: 100%;
  height: 100%;
  object-fit: $option;
  font-family: "object-fit: " + $option + ";";
}
