.topics {
    .topics-ttl {
        display: block;
        padding-bottom: 10px;
        .category-txt {
            background: #fff;
            border: 1px solid #2167a0;
            border-radius: 30px;
            -webkit-border-radius: 30px;
            color: #2167a0;
            display: inline-block;
            font-size: 12px;
            line-height: 16px;
            padding: 3px 7px;
        }
        .date-txt {
            color: #b7b7c0;
            font-family: $font-en;
            margin-right: 10px;
        }
    }
    .sec-01 {
        background: #fafafa;
        padding: 120px 0 140px;
        @include mq {
            padding: 60px 0 70px;
        }
        .tab-list {
            margin-bottom: 50px;
            justify-content: flex-start!important;
            @include mq {
                margin-bottom: 25px;
            }
            li {
                width: 140px;
                @include mq {
                    width: 25%;
                }
                &:nth-child(4) {
                  @include mq {
                    border-right: 1px solid #2167a0;
                  }
                }
                a {
                    background: #fafafa;
                    border: 1px solid #2167a0;
                    border-right: none;
                    color: #2167a0;
                    font-size: 1.5rem;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 60px;
                    width: 100%;
                    text-align: center;
                    @include mq {
                        font-size: 12px;
                        line-height: 20px;
                        padding: 0 5px;
                        height: 50px;
                    }
                    &.active,
                    &:hover {
                        background: #2167a0;
                        color: $white;
                    }
                }
                &:last-child {
                    a {
                        border-right: 1px solid #2167a0;
                    }
                }
            }
        }
        .tab-item {
            background: $white;
            .topics-list {
                border-top: 1px solid #dbdbdb;
                li {
                    a {
                        border-bottom: 1px solid #dbdbdb;
                        display: block;
                        padding: 30px 35px;
                        position: relative;
                        @include mq {
                            padding: 15px 25px 20px 20px;
                        }
                        &:hover {
                          background: #f5f5f5;
                        }
                        &::after {
                            content: "";
                            background: url(/img/common/icon_arrow04.png) no-repeat left top;
                            background-size: 100% 100%;
                            height: 14px;
                            width: 16px;
                            position: absolute;
                            right: 30px;
                            top: 50%;
                            transform: translateY(-50%);
                            @include mq {
                                background: url(/img/common/icon_arrow04.png) no-repeat left top;
                                background-size: 100% 100%;
                                height: 11px;
                                width: 12px;
                                right: 10px;
                            }
                        }
                        .txt {
                            font-size: 1.8rem;
                            line-height: 30px;
                            display: inline-block;
                            padding-bottom: 5px;
                            position: relative;
                            @include mq {
                                border-bottom: 1px solid #000;
                                display: inline;
                                font-size: 14px;
                                line-height: 24px;
                                padding-bottom: 2px;
                            }
                            &::after {
                              content: "";
                              background: #dbdbdb;
                              height: 2px;
                              width: 100%;
                              position: absolute;
                              left: 0;
                              bottom: 0;
                              transition: all 0.2s;
                                @include mq {
                                    display: none;
                                }
                            }
                        }
                        &:hover {
                            .txt {
                                @include mq {
                                    border-bottom: none;
                                }
                                &::after {
                                    width: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        h3 {
            font-size: 24px;
            font-weight: normal;
            line-height: 30px;
            margin-bottom: 40px;
            @include mq {
                font-size: 18px;
                margin-bottom: 20px;
            }
        }
        .topics-detail {
            line-height: 30px;
            background: $white;
            padding: 55px;
            @include mq {
                line-height: 24px;
                padding: 20px;
            }
            .img-list {
                margin-bottom: 50px;
                @include mq {
                    margin-bottom: 20px;
                }
                li {
                  width: 60%;
                  text-align: center;
                  margin: 0 auto;
                    @include mq {
                        width: 100%;
                        &:nth-child(n+2) {
                            margin-top: 20px;
                        }
                    }
                }
            }
            .btn-download {
                margin: 40px 0 30px;
                @include mq {
                    margin: 20px 0 15px;
                }
                a {
                    border: 1px solid #b7b7c0;
                    display: inline-block;
                    padding: 15px 0;
                    text-align: center;
                    width: 340px;
                    @include mq {
                        font-size: 12px;
                        line-height: 18px;
                        width: 100%;
                    }
                    svg {
                        fill: #2167a0;
                        margin-right: 10px;
                        vertical-align: middle;
                        height: 18px;
                        width: 15px;
                    }
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            .image {
                margin: 40px auto;
                position: relative;
                overflow: hidden;
                height: 293px;
                width: 480px;
                @include mq {
                    height: 40vw;
                    width: 100%;
                }
                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }
            }
            .txt_area {
              img {
                height: auto;
                width: auto;
                display: block;
                margin: 5px 0;
                @include mq {
                  width: 100%;
                }
              }
            }
        }
        .btn-list {
            margin-top: 40px;
            text-align: center;
            @include mq {
                margin-top: 20px;
            }
            a {
                background: #fafafa;
                border: 1px solid #d2d2d2;
                display: inline-block;
                font-size: 16px;
                padding: 20px 0;
                width: 380px;
                position: relative;
                @include mq {
                    font-size: 14px;
                    padding: 15px 0;
                    width: 100%;
                }
                &::after {
                    content: "";
                    background: url(/img/common/icon_arrow04.png) no-repeat left top;
                    background-size: 100% 100%;
                    height: 14px;
                    width: 16px;
                    position: absolute;
                    left: 30px;
                    top: 50%;
                    transform: translateY(-50%) rotate(180deg);
                    @include mq {
                        height: 11px;
                        width: 12px;
                        left: 15px;
                    }
                }
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}