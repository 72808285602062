.footer {
  font-size: 1.6rem;
  padding-top: 70px;
  @include mq(spc) {
    font-size: 1.4rem;
  }
  @include mq {
    font-size: 1.4rem;
    padding-top: 50px;
  }
  .f-inner {
    @include inner;
  }
  .f-top {
    padding-bottom: 80px;
    @include mq {
      padding-bottom: 40px;
    }
  }
  .f-col {
    @include mq(pc) {
      display: flex;
      justify-content: space-between;
    }
  }
  .logo {
    width: 350px;
    @include mq(spc) {
      width: 270px;
    }
    @include mq {
      width: 100%;
      max-width: 260px;
      margin: 0 auto;
    }
  }
  .f-info {
    color: #333;
    @include ls(25);
    margin-top: 40px;
    @include mq {
      margin-top: 25px;
      text-align: center;
      @include ls(100);
      font-size: 1.8rem;
    }
    p {
      @include mq {
        line-height: 20px;
      }
      +p {
        margin-top: 10px;
      }
    }
  }
  .sns-list {
    margin-top: 24px;
    display: flex;
    justify-content: flex-start;
    @include mq {
      justify-content: center;
      text-align: center;
    }
    li {
      +li {
        margin-left: 14px;
        @include mq {
          margin-left: 15px;
        }
      }
      a {
        @include link_color(#9ea5ab);
        font-size: 2rem;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #d8dae3;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq {
          font-size: 2.8rem;
          width: 50px;
          height: 50px;
        }
        &:hover {
          @include mq(pc) {
            background: #f1f1f1;
          }
        }
      }
      &:first-child {
        i {
          font-family: $font-awesome4;
          font-weight: 500;
        }
      }
    }
  }
  .link-list {
    display: flex;
    @include mq {
      display: none;
    }
    ul {
      +ul {
        margin-left: 65px;
        @include mq(spc) {
          margin-left: 30px;
        }
      }
    }
    li {
      +li {
        margin-top: 22px;
      }
      a {
        font-size: 1.4rem;
        @include ls(50);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:before {
          content: "\f054";
          color: #0766b4;
          font-family: $font-awesome4;
          font-size: 1.2rem;
          font-weight: 100;
          width: 24px;
          height: 24px;
          padding-left: 2px;
          margin-right: 10px;
          border-radius: 50%;
          border: 1px solid #d8dae3;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          transition: .3s ease background;
        }
        &:hover {
          color: $main-color;
          &:before {
            background: $main-color;
            color: $white;
            border: 1px solid $main-color;
          }
        }
      }
    }
  }
  .f-btm {
    border-top: 1px solid #d8dae3;
    @include mq(pc) {
      padding: 30px 0;
    }
    .f-inner {
      @include mq {
        padding: 0;
      }
    }
  }
  .link-list02 {
    font-size: 1.2rem;
    display: flex;
    @include mq {
      font-size: 1.3rem;
      padding: 19px 15px;
      justify-content: center;
    }
    li {
      border-left: 1px solid #cacbcf;
      padding-left: 20px;
      @include mq {
        padding-left: 15px;
      }
      &:last-of-type {
        padding-right: 20px;
        border-right: 1px solid #cacbcf;
        @include mq {
          padding-right: 15px;
        }
      }
      +li {
        margin-left: 20px;
        @include mq {
          margin-left: 15px;
        }
      }
      a {
        @include link_color(#868790);
        font-weight: 300;
        &:hover {
          color: $main-color;
        }
      }
    }
  }
  .copyright {
    @include mq {
      text-align: center;
      border-top: 1px solid #d8dae3;
      padding: 23px 10px;
    }
    small {
      color: #b1b2ba;
      font-family: $font-en;
      font-size: 1.2rem;
      font-weight: 500;
      @include ls(80);
      @include mq {
        font-size: 1rem;
      }
    }
  }
}
