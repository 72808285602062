.spMenu {
  @include mq(pc) {
    display: none;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-top: $sp-header-height;
  pointer-events: none;
  &.opened {
    pointer-events: auto;
  }
  &__container {
//    background: $black01;
//    padding-bottom: $sp-header-height;
//    min-height: 100%;
  }
  &-inner {
    padding: 20px 10px;
  }
}
