@font-face {
  font-family: "ZenM";
  src: url("../fonts/ZenKakuGothicNew-Medium.ttf");
}

.fibremax {
  color: $black01;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 28.8px;
  letter-spacing: 0;
  @include mq {
    font-size: 14px;
  }
  a {
    color: $black01;
  }
  &.ov {
    overflow: hidden;
  }
  .align_r {
    text-align: right;
  }
  .align_c {
    text-align: center;
  }
  .align_l {
    text-align: left;
  }

  /* header */
  #header {
    background: $white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 25px 2.083vw;
    z-index: 100;
    @include mq(spc) {
      padding: 15px 2.083vw;
    }
    @include mq(sspc) {
      padding: 15px 1.563vw;
    }
    @include mq {
      padding: 0 10px;
      height: 55px;
    }
    .header_inner {
      height: 100%;
      @include mq {
        justify-content: flex-start;
        width: 100%;
      }
    }
    .logo_area {
      justify-content: flex-start;
      align-items: center;
    }
    .logo {
      line-height: 1;
      img {
        @include mq(pc) {
          @include mq(mpc) {
            width: 322px;
          }
          @include mq(lpc) {
            width: 282px;            
          }
          @include mq(spc) {
            width: 252px;
          }
          @include mq(sspc) {
            width: auto;
            height: 16px;
          }
        }        
        @include mq(lsp) {
          height: auto;  
          width: 180px;                 
        }
        @include mq(ssp) {
          width: 160px;
        } 
      }
    }
    .logo_fm {
      margin: 0 1.563vw 0 3.646vw;
      @include mq(spc) {
        margin-left: 2.083vw;
      }
      @include mq {
        margin-left: 12px;
        margin-right: 0;
        line-height: 1;
      }
      img {
        @include mq(pc) {
          @include mq(mpc) {
            height: 40px;
          }
          @include mq(lpc) {
            height: 38px;            
          }
          @include mq(spc) {
            height: 32px;
          }
          @include mq(sspc) {
            height: 27px;
          }
        }        
        @include mq(lsp) {
          height: auto; 
          width: 68px;         
        }
        @include mq(ssp) {
          width: 58px;
        }
      }
    }
    .btn_contact a {
      background: $red;
      border-radius: 5px;
      color: $white;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      line-height: 20px;
      padding: 20px 12px;
      @include mq(pc) {
        @include mq(lpc) {
          font-size: 13px;          
        }
        @include mq(spc) {
          font-size: 12px;
          gap: 5px;
          padding: 15px 12px;
        }
        @include mq(sspc) {
          font-size: 10px;
          padding: 10px 12px;
        }
      }      
      @include mq {
        justify-content: center;
        font-size: 16px;
        line-height: 28.8px;
        max-width: 160px;
        margin: auto;
        padding: 12px;
      }
      &:hover {
        background: #a30028;
      }
      img {
        width: 20px;
        height: 14px;
        @include mq(spc) {
          width: 18px;
        }
        @include mq(sspc) {
          width: 16px;
        }
        @include mq {
          margin-top: 2px;
        }
      }
    }
    #sp_menu {
      cursor: pointer;
      @include mq {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 7px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease;
        span {
          background: $red;
          width: 35px;
          height: 4px;
          transition: all 0.3s ease;
          @include mq(ssp) {
            width: 32px;
            height: 3px;
          }
        }
        &.active {
          span {
            background: $black01;
            &:nth-child(1) {
              transform: rotate(45deg) translate(4px, 4px);
              @include mq(ssp) {
                transform: rotate(45deg) translate(3px, 3px);
              }
            }
            &:nth-child(2) {
              display: none;
            }
            &:nth-child(3) {
              transform: rotate(-45deg) translate(4px, -4px);
            }
          }
        }
      }
    }
  }
  #navi {
    @include mq {
      background: $white;
      display: block;
      position: fixed;
      top: 55px;
      left: 0;
      padding: 60px 20px;
      height: 0;
      width: 100%;
      opacity: 0;
      overflow-y: auto;
      text-align: center;
      transform: translateX(100%);
      transition: transform 0.4s ease, opacity 0.4s ease;
      z-index: -2;
      &.opened {
        height: auto;
        opacity: 1;
        transform: translateX(0);
        z-index: 100;
      }
      @media screen and(orientation:landscape) {
        &.opened {
          height: 90vh;
        }
      }
    }
    a {
      font-size: 16px;
      font-weight: bold;
      line-height: 28.8px;
      @include mq(pc) {
        @include mq(lpc) {
          font-size: 14px;          
        }
        @include mq(spc) {
          font-size: 13px;
        }
        @include mq(sspc) {
          font-size: 12px;
          line-height: 24px;
        }
      }      
      &:hover {
        opacity: 0.7;
        text-decoration: none;
      }
    }
    ul {
      gap: 3.125vw;
      @include mq(mpc) {
        gap: 2.083vw;
      }
      @include mq(sspc) {
        gap: 1.563vw;
      }
      @include mq {
        display: block;
      }
      li {
        @include mq {
          margin-bottom: 40px;
        }
        a {
          display: block;
          position: relative;
          &::after {
            @include mq(pc) {
              content: "";
              background: $red;
              height: 0;
              width: 0;
              position: absolute;
              bottom: -6px;
              left: 0;
              right: 0;
              margin: auto;
              transition: all 0.3s;
            }
          }
          &:hover, &.active,&.active1 {
            color: $red;
            opacity: 1;
          }
        }
      }
      li:not(.btn_contact) {
        a:hover::after {
          width: 24px;
          height: 4px;
        }
        a.active1::after,
        a.active::after {
          width: 24px;
          height: 4px;
        }
      }
    }
  }  
  #pagetop {
    position: absolute;
    right: 20px;
    bottom: -60px;
    z-index: 99;
    @include mq {
      bottom: -130px;
    }
    a {
      background: $white;
      border: 1px solid $red;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      color: $red;
      font-size: 12px;
      font-family: "Ubuntu", "Noto Sans JP", sans-serif;
      font-weight: bold;
      display: block;
      padding-top: 30px;
      height: 60px;
      width: 60px;
      position: relative;
      text-align: center;
      @include mq {
        border: none;
      }
      &::after {
        content: "";
        background: url(/img/fibremax/icon_up.svg) no-repeat right center;
        background-size: 100% 100%;
        height: 22px;
        width: 28px;
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translateX(-50%);
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
  /* footer */
  #footer {
    padding-top: 100px;
    position: relative;
    z-index: 9;
    @include mq {
      padding-top: 0;
    }
    .btn-red {
      padding-top: 95px;
    }
    #copyright {
      color: $white;
      font-family: $font-en;
      font-size: 11px;
      font-weight: bold;
      line-height: 12px;
      padding: 40px 0;
      text-align: center;
      @include mq {
        padding: 28px 0;
      }
    }
  }

  /* contents */
  #contents {
    margin-top: 110px;
    @include mq(spc) {
      margin-top: 80px;
    }
    @include mq(sspc) {
      margin-top: 70px;
    }
    @include mq {
      margin-top: 55px;
    }
    .txt_list {
      li {
        padding-left: 15px;
        position: relative;
        &::after {
          content: "";
          background: $red;
          height: 4px;
          width: 4px;
          position: absolute;
          left: 0;
          top: 15px;
          @include mq {
            top: 12px;
          }
        }
      }
    }
  }
  main {
    z-index: inherit;
  }
  main section {
    background: #fff;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .bg {
    background-color: #e9ecef;
    &-white {
      background: $white;
    }
    &-black {
      background: $black01;
    }
  }
  .inner {
    max-width: 1040px;
    padding: 0 20px;
  }
  .fl-item {
    align-items: center;
  }
  .pd {
    padding: 110px 0;
    @include mq {
      padding: 56px 0;
    }
  }
  .h2_ttl {
    display: flex;
    gap: 16px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
    @include mq {
      gap: 12px;
      margin-bottom: 28px;
    }
    .en_ttl {
      color: #222222;
      font-family: $font-en;
      font-size: 16px;
      line-height: 18px;
      position: relative;
      &::after {
        content: "";
        background: $red;
        height: 4px;
        width: 4px;
        position: absolute;
        right: -7px;
        bottom: 3px;
      }
    }
    .jp_ttl {
      color: $black01;
      font-size: 32px;
      line-height: 51.2px;
      @include mq {
        font-size: 24px;
        line-height: 35px;
      }
    }
  }
  .line {
    position: relative;
    &::after {
      content: "";
      background: $red;
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 5px;
      width: 120px;
      -webkit-transform: skewY(3deg) translateX(-50%);
      transform: skewY(3deg) translateX(-50%);
      @include mq {
        height: 3px;
      }
    }
  }
  .btn {
    &-red {
      text-align: center;
      a {
        background: $red;
        border-radius: 5px;
        color: $white;
        font-weight: bold;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 16px 12px;
        @include mq {
          padding: 10px;
        }
        img {
          margin-left: 10px;
          width: 17.5px;
        }
        &:hover {
          background: #a30028;
        }
      }
    }
  }
  .sec {
    &-skewed {
      padding: 110px 0;
      -webkit-transform: skewY(3deg);
      transform: skewY(3deg);
      @include mq {
        padding: 56px 0;
      }
    }
    &-block {
      -webkit-transform: skewY(-3deg);
      transform: skewY(-3deg);
    }
    &-mainv {
      background: url(../../img/fibremax/main_img.png) no-repeat center center;
      background-size: cover;
      overflow: hidden;
      min-height: 780px;
      width: 100%;
      @include mq {
        min-height: calc(80vh - 55px);
      }
      video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        -ms-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
      }
      .mainv-ttl {
        position: absolute;
        left: 9.375vw;
        top: 45%;
        transform: translateY(-50%);
        z-index: 5;
        @include mq {
          left: 20px;
          top: 56px;
          transform: none;
        }
        span {
          display: inline-block;
          background: $black01;
          color: $white;
          font-size: 56px;
          line-height: 105px;
          padding: 0 20px 0 15px;
          @include mq {
            font-size: 32px;
            line-height: 52.5px;
          }
          &:last-child {
            position: relative;
            @include mq {
              font-size: 28px;
              @include mq(ssp) {
                font-size: 24px;
              }
            }
            &::after {
              content: "";
              background: $red;
              height: 8px;
              width: 8px;
              position: absolute;
              bottom: 24px;
              right: 12px;
              @include mq {
                height: 4px;
                width: 4px;
                bottom: 12px;
                right: 15px;
              }
            }
          }
        }
      }
    }
    &-01 {
      margin-top: -50px;
      padding: 60px 0;
      @include mq {
        margin-top: -20px;
      }
      .txt {
        margin-bottom: 100px;
        padding-bottom: 50px;
        text-align: center;
        @include mq {
          margin-bottom: 50px;
          padding-bottom: 30px;
          text-align: left;
        }
        &-01 {
          font-size: 24px;
          font-weight: bold;
          line-height: 38.4px;
          margin-top: 40px;
          text-align: center;
          @include mq {
            font-size: 16px;
            line-height: 26px;
            margin-top: 20px;
            text-align: left;
          }
        }
      }
      .logo {
        width: 392px;
        @include mq {
          width: auto;
          height: 16px;
          @include mq(ssp) {
            height: 14px;
          }
        }
        &-area {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 40px;
          @include mq {
            gap: 16px;
          }
        }
        &-fm {
          width: 183px;
          @include mq {
            width: auto;
            height: 32px;
            @include mq(ssp) {
              height: 22px;
            }
          }
        }
      }
    }
    &-02 {
      .list_news {
        margin-bottom: 40px;
        @include mq {
          margin-bottom: 28px;
        }
        div {
          border-bottom: 3px solid $red;
          line-height: 25.6px;
          padding: 20px 0;
          @include mq {
            border-width: 2px;
            padding: 10px 0;
          }
          &:first-child {
            padding-top: 0;
          }
          a {
            background: url(/img/fibremax/icon_arrow.svg) no-repeat center right;
            background-size: 12px 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include mq {
              background-position: 100% 90%;
              align-items: baseline;
              flex-direction: column;
              line-height: 21px;
            }
            &:hover {
              opacity: 0.7;
            }
          }
        }
        dt {
          font-weight: bold;
          width: 150px;
          @include mq {
            margin-bottom: 8px;
          }
        }
        dd {
          padding-right: 20px;
        }
      }
    }
    &-slide {
      padding: 60px 0 13.5vw;
      @include mq {
        padding: 56px 0;
      }
      .swiper {
        height: 100%;  
        &-content-image {
          background-position-x: left;
          background-position-y: center;
          background-repeat: no-repeat;
          background-size: cover;
          object-fit: cover;
          position: absolute;
          left: 0;
          top: 0;
          width: 50%;
          height: 547px;
          transition: all 0.3s ease;
          @include mq(lpc) {
            height: 470px;
          }
          @include mq {
            height: 215px;
            width: 100%;
            position: unset;
          }
        }
        &-inner {
          position: relative;  
          height: 547px; 
          @include mq(lpc) {
            height: 470px;
          }
          @include mq {
            height: 215px;
          }      
        }
        &-slide {
          height: 100%;
        }
        &-image {
          background-position-x: left;
          background-position-y: center;
          background-repeat: no-repeat;
          background-size: cover;
          object-fit: cover;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          @include mq {
            position: unset;
          }
        }          
        &-content {
          display: flex;
          justify-content: end;          
        }            
        &-content-inner {
          padding-top: 3.125vw;
          max-width: 45%;
          font-weight: 400;
          line-height: 25.6px;
          @include mq {
            line-height: 22.4px;
            max-width: 100%;
            padding-top: 20px;
          }
        }
      }
      .swiper-button {
        &-control {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 20px;
          padding-right: 20px;
          position: absolute;
          right: 0;
          bottom: -30px;
          height: 60px;
          width: 50%;
          z-index: 10;
          @include mq {
            bottom: auto;
            top: 195px;
            gap: 15px;
            height: 40px;
            width: 100%;
          }
        }
        &-next,
        &-prev {
          background: $red;
          border-radius: 5px;
          color: $white;
          margin: 0;
          position: unset;
          height: 60px;
          width: 60px;
          -webkit-transition: background-color 0.25s ease-out;
          transition: background-color 0.25s ease-out;
          @include mq {
            height: 40px;
            width: 40px;
          }
          &::after {
            font-size: 28px;
            font-weight: bold;
            @include mq {
              font-size: 18px;
            }
          }
          &:hover {
            background: #a30028;
          }
        }
      }
      .swiper-button-next.swiper-button-disabled,
      .swiper-button-prev.swiper-button-disabled {
        background: #d0d0d0;
        color: #6c757d;
        opacity: 1;
      }  
      .h2_ttl {
        align-items: flex-start;
        .jp_ttl {
          @include mq(lpc) {
            font-size: 22px;
            line-height: 40px;
            margin-bottom: 25px;
          }
          @include mq {
            font-size: 28px;
            line-height: 44px;
            @include mq(ssp) {
              font-size: 24px;
            }
          }
        }
      }
    }
    &-stage {
      // opacity: 0;
      // visibility: hidden;
      .sec-skewed {
        padding: 110px 20px;
        height: 100vh;
        width: 100%;
        position: relative;
        &::after {
          content: "";
          background-color: #e9ecef;
          position: absolute;
          left: 0;
          top: 0;
          height: 90%;
          width: 100%;
        }
        @include mq {
          padding: 56px 0;
        }
      }
      .stage {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        opacity: 0;
        transition: all 1s;
        transform: translateY(100px);
      }
      .scene {
        height: 58vh;
        position: absolute;
        left: 0;
        top: 20vh;
        visibility: hidden;
        overflow-y: auto;
        width: 100%;
        @include mq {
          height: 62vh;
          top: 25vh;
        }
        @include mq(msp) {
          top: 18vh;
        }
      }
      .scroller {
        position: relative;
        pointer-events: none;
        scroll-snap-type: mandatory;
        -webkit-scroll-snap-points-y: repeat(100px);
        scroll-snap-points-y: repeat(100px);
        -ms-scroll-snap-type: mandatory;
        -ms-scroll-snap-points-y: repeat(100px);
        -webkit-scroll-snap-type: mandatory;
        -webkit-scroll-snap-points-y: repeat(100vh);
        -webkit-scroll-snap-type: y mandatory;
        -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
        display: none;
        .scroller__item {
          height: 52vh;
          scroll-snap-align: center;
          scroll-snap-stop: always; 
        }
      }
      &.fixed {
        .stage {
          position: fixed;
          top: 0;
          left: 0;
          opacity: 1;
          transform: translateY(0);
        }
        .scroller {
          display: block;
          .scroller__item {
            height: 100vh;
          }
        }
      }
      &.reset {
        // opacity: 0;
        .scroller {
          display: block;
        }
      }
    }
    &-03 {
      margin: 0 auto;
      max-width: 1280px;
      padding: 150px 40px 60px;
      width: 100%;
      position: relative;
      overflow: hidden;
      z-index: 2;
      @include mq {
        padding: 80px 20px 40px;
      }
      .pagination_slider {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) skewY(-3deg);
        left: -40px;
        width: 40px;
        z-index: 99;
        @include mq {
          top: auto;
          transform: translateY(0) skewY(-3deg);
          left: auto;
          right: 0;
          bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: auto;
        }
        span {
          cursor: pointer;
          display: block;
          background: none;
          color: #6c757d;
          font-size: 16px;
          font-weight: bold;
          font-family: "Ubuntu", "Noto Sans JP", sans-serif;
          padding-left: 14px;
          opacity: 1;
          position: relative;
          width: 100%;
          @include mq {
            padding: 0 0 10px 0;
          }
          & + span {
          margin-top: 30px;
          @include mq {
            margin: 0 0 0 20px;
          }
            &::after {
              content: "";
              background: #6c757d;
              height: 24px;
              width: 1px;
              position: absolute;
              bottom: 30px;
              right: 16px;
              @include mq {
                height: 1px;
                width: 12px;
                bottom: 24px;
                right: 23px;
              }
            }
          }
          &.active {
            color: #222;
            &::before {
              content: "";
              background: $red;
              height: 8px;
              width: 8px;
              position: absolute;
              left: 0;
              top: 10px;
              @include mq {
                height: 4px;
                width: 4px;
                @include mq {
                  top: auto;
                  left: 8px;
                  bottom: 12px;
                }
              }
            }
          }
        }
      }
      .h2_ttl.line {
        padding-bottom: 30px;
        &::after {
          width: 240px;
          @include mq {
            height: 3px;
            width: 120px;
          }
        }
      }
      .features_block {
        width: 100%;
        .scene {
          &.active {
            .features_slide {
              .img {
                opacity: 1;
                transform: scale(1, 1);
                transition: all 0.5s;
              }
              .box_txt {
                opacity: 1;
                transform: translateY(0);
                // transition-delay: 2s;
                transition: all 1s;
              }
            }
          }
        }
        .features_slide {
          @include mq {
            padding:0 15px 20px;
          }
          .img {
            width: 45%;
            opacity: 0;
            transform: scale(0, 0);
            .img02 {
              max-width: 90%;
            }
            @include mq {
              margin: 0 auto;
              width: 100%;              
              .img02 {
                display: block;
                margin: 0 auto;
                width: 70%;
                max-width: 100%;
              }
            }
          }
          .box_txt {
            width: 50%;
            opacity: 0;
            transform: translateY(100px);
            @include mq {
              margin-top: 20px;
              width: 100%;
            }
            h3 {
              font-size: 32px;
              line-height: 42px;
              margin-bottom: 40px;
              @include mq {
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 16px;
              }
              span {
                font-family: "Ubuntu", "Noto Sans JP", sans-serif;
                font-size: 40px;
                margin-right: 20px;
                @include mq {
                  font-size: 24px;
                  margin-right: 10px;
                }
              }
            }
            .txt_list {
              margin-top: 40px;
              @include mq {
                font-size: 13px;
                line-height: 24px;
                margin-top: 20px;
              }
            }
          }
        }
        .features_tbl {
          width: 100%;
          th {
            font-size: 14px;
            font-weight: bold;
            font-family: "Ubuntu", "Noto Sans JP", sans-serif;
            padding: 5px;
            @include mq {
              padding: 3px;
              font-size: 8px;
              line-height: 15px;
            }
          }
          td {
            font-family: $font-yugothic;
            font-weight: bold;
            letter-spacing: -0.05em;
            padding: 5px 12px;
            vertical-align: middle;
            @include mq {
              font-size: 12px;
              line-height: 18px;
              padding: 3px 10px;
            }
            img {
              display: block;
              margin: 0 auto;
              width: auto;
              max-width: 100%;
              vertical-align: middle;
              @include mq {
                max-width: 15px;
              }
            }
          }
          tr:nth-child(even) td {
            background: #d9dde1;
          }
        }
      }
    }
    &-04 {
      padding-bottom: 0;
      opacity: 0;
      transition-delay: 1.5s;
      transition: all 1s;
      transform: translateY(100px);
      &.fadeIn {
        opacity: 1;
        transform: translateY(0);
      }
      @include mq {
        padding-bottom: 0;
      }
      .box {
        &-app {
          .bg {
            &-white {
              padding: 50px;
              @include mq {
                padding: 20px;
              }
            }
          }
          &-01 {
            .box-image {
              background-image: url(/img/fibremax/bg_sustaineble.png);
            }
          }
          &-02 {
            .box-image {
              background-image: url(/img/fibremax/bg_strong.png);
            }
          }
          &-03 {
            .box-image {
              background-image: url(/img/fibremax/bg_GRIJS.png);
            }
          }
          .h3_ttl {
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 30px;
            @include mq {
              font-size: 20px;
              line-height: 26px;
              margin-bottom: 16px;
            }
          }
          .btn-more {
            margin-top: -10px;
            padding: 0px 55px 40px;
            @include mq {
              margin-top: 0;
              padding: 25px 0 25px;
            }
            a {
              color: #222222;
              font-family: $font-en;
              font-weight: bold;
              text-transform: uppercase;
              @include mq {
                font-size: 16px;
              }
              &:hover {
                color: #bc002e;
              }
              span {
                display: inline-block;
                padding-right: 25px;
                position: relative;
                &::after {
                  content: "";
                  background: url(/img/fibremax/icon_up.svg) no-repeat right
                    center;
                  background-size: 17px 11px;
                  position: absolute;
                  top: 9px;
                  right: 0;
                  width: 17px;
                  height: 11px;
                  transform: rotate(180deg);
                  transition: all 0.3s ease;
                  @include mq {
                    background-size: 15px 9px;
                    width: 15px;
                    height: 9px;
                  }
                }
              }
              &.open {
                span {
                  &::after {
                    transform: rotate(0deg);
                  }
                }
              }
            }
          }
        }
        &-image {
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: cover;
          min-height: 453px;
          line-height: 32px;
          padding-top: 110px;
          position: relative;
          @include mq {
            min-height: auto;
            font-size: 14px;
            line-height: 22.4px;
            padding-top: 60px;
          }
          &::after {
            content: "";
            background: url(/img/fibremax/bg_path.svg) no-repeat bottom left;
            background-size: cover;
            width: 100%;
            height: 180px;
            position: absolute;
            left: 0;
            bottom: 0;
            @include mq {
              height: 74px;
            }
          }
        }
        &-hide {
          display: none;
          padding: 95px 0;
          position: relative;
          @include mq {
            padding: 50px 0;
          }
          &::before {
            content: "";
            background: $red;
            height: 4px;
            width: 220px;
            position: absolute;
            left: 50%;
            top: 12px;
            transform: skewY(3deg) translateX(-50%);
            -webkit-transform: skewY(3deg) translateX(-50%);
            @include mq {
              width: 110px;
              height: 2px;
            }
          }
          .col {
            @include mq {
              gap: 25px;
            }
            li {
              text-align: center;
              width: 48%;
              @include mq {
                line-height: 19px;
                width: 100%;
              }
              .img {
                margin-bottom: 15px;
                @include mq {
                  margin-bottom: 10px;
                }
              }
            }
          }
          .btn-red {
            margin-top: 40px;
            @include mq {
              margin-top: 28px;
            }
          }
        }
      }           
      .embed-responsive {
        display: block;
        height: 0;
        overflow: hidden;
        padding-bottom: 47%;
        padding-top: 30px;
        position: relative;
        @include mq {
          padding-bottom: 44%;
        }
        .video-bg {
          display: block;
          height: 100%;
          opacity: 1;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 1;
        }
        .player-overlay {
          -webkit-animation-name: fade;
          animation-name: fade;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          bottom: 0;
          height: 100%;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 20;
          &.fade {
            z-index: -1;
          }
        }
        .play {          
          margin: 0 auto;
          max-height: 66px;
          max-width: 66px;
          height: 100%;
          width: 100%;
          position: absolute;
          left: calc(50% - 33px);
          top: calc(50% - 33px);
          -webkit-transition: -webkit-transform 0.25s ease-in-out;
          transition: -webkit-transform 0.25s ease-in-out;
          transition: transform 0.25s ease-in-out;
          transition: transform 0.25s ease-in-out,
            -webkit-transform 0.25s ease-in-out;
          z-index: 20;
          @include mq {
            left: calc(50% - 20px);
            top: calc(50% - 20px);
            max-height: 40px;
            max-width: 40px;            
          }
          &:hover {
            cursor: pointer;
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
            -webkit-transition: -webkit-transform .25s ease-in-out;
            transition: -webkit-transform .25s ease-in-out;
            transition: transform .25s ease-in-out;
            transition: transform .25s ease-in-out,-webkit-transform .25s ease-in-out;
          }
          &.fade {
            display: none;
          }
          .fa-youtube {
            color: $red;
            font-size: 60px;
            @include mq {
              font-size: 40px;
            }
          }
        } 
        iframe {
          border: 0;
          bottom: 0;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
    &-05 {
      padding-bottom: 80px;
      @include mq {
        padding-bottom: 90px;
      }
      .slider {
        .slick-slide {
          margin: 0 30px;
          @include mq {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 15px;            
          }
          img {
            @include mq {
              width: 100%;
              height: auto;
            }
          }
        }
        .slick-track {
          display: flex;
          align-items: center;
          justify-content: center;
          @include mq {
            height: 70px;
          }
        }
      }
    }
    &-06 {
      padding-bottom: 0;
      .bg {
        &-white {
          margin-top: -50px;
          @include mq {
            margin-top: -20px;
          }
        }
      }
      .col {
        dt {
          width: 50%;
          @include mq {
            width: 100%;
          }
        }
        dd {
          width: 47%;
          @include mq {
            width: 100%;
            line-height: 22.4px;
          }
        }
        .h2_ttl {
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 30px;
          @include mq {
            margin: 28px 0 12px;
            .jp_ttl {
              font-size: 20px;
              line-height: 32px;
            }
          }
        }
        .btn-red {
          @include mq(pc) {
            text-align: left;
          }
        }
        .box-social {
          justify-content: flex-start;
          gap: 16px;
          margin: 40px 0 20px;
          @include mq {
            justify-content: center;
            margin: 20px 0;
          }
          i {
            font-size: 24px;
          }
          a {
            &:hover {
              opacity: 0.7;
            }
          }
        }
        &-01 {
          flex-direction: row-reverse;
          margin-bottom: 110px;
          @include mq {
            margin-bottom: 56px;
          }
          .h2_ttl {
            font-weight: 900;
            letter-spacing: 0.025em;
            @include mq {
              align-items: center;
            }
          }
          dt {
            @include mq {
              max-width: 230px;
              margin: auto;
            }
            img {
              @include mq {
                content: url(/img/fibremax/logo-fm.png);
              }
            }
          }
        }
      }
    }
    &-contact {
      margin-top: -120px;
      padding-top: 120px;
      position: relative;
      @include mq {
        margin-top: 0;
        margin-bottom: -50px;
        padding-top: 0;
      }
      &::after {
        content: "";
        background: $white;
        height: 280px;
        width: 100%;
        position: absolute;
        top: 280px;
        left: 0;
        -webkit-transform: skewY(3deg);
        transform: skewY(3deg);
        z-index: 1;
        @include mq {
          top: 100px;
        }
      }
      .box {
        &-contact {
          background: url(/img/fibremax/main_bg.jpg) no-repeat top center;
          background-size: cover;
          padding: 90px 80px 90px 60px;
          position: relative;
          top: 100px;
          z-index: 10;
          @include mq {
            top: 60px;
            padding: 40px 14px;
          }
          &::after {
            content: "";
            background: rgba($color: #000000, $alpha: 0.46);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .h2_ttl {
            color: $white;
            align-items: flex-start;
            gap: 0;
            margin-bottom: 0;
            position: relative;
            z-index: 20;
            @include mq {
              align-items: center;
              margin-bottom: 35px;
              text-align: center;
              width: 100%;
            }
            .en_ttl {
              background: $black01;
              color: $white;
              font-family: $font-en;
              font-size: 40px;
              font-weight: 300;
              line-height: 70px;
              padding: 0 12px;
              text-transform: uppercase;
              @include mq {
                font-size: 24px;
                line-height: 40px;
                margin-bottom: 20px;
                padding: 0 10px;
              }
              &::after {
                right: 12px;
                bottom: 20px;
                @include mq {
                  right: 6px;
                  bottom: 11px;
                }
              }
            }
            .jp_ttl {
              background: $black01;
              color: $white;
              line-height: 70px;
              padding: 0 12px;
              @include mq {
                font-size: 18px;
                line-height: 38px;
                padding: 0 5px;
              }
              .en {
                font-family: $font-en;
                font-size: 40px;
                font-weight: bolder;
                @include mq {
                  font-size: 22px;
                }
              }
            }
          }
          .txt {
            &-tel {
              display: flex;
              font-family: $font-en;
              font-size: 34px;
              font-weight: 500;
              line-height: 38px;
              letter-spacing: 0.075em;
              @include mq {
                font-size: 32px;
                line-height: 36px;
                @include mq(ssp) {
                  font-size: 24px;
                }
              }
              img {
                width: 24px;
                margin-right: 15px;
                @include mq {
                  margin-right: 10px;
                  @include mq(ssp) {
                    width: 20px;
                  }
                }
              }
            }
            &-time {
              display: block;
              font-family: "ZenM";
              font-size: 12px;
              line-height: 27px;
              letter-spacing: 0.05em;
              margin-bottom: 40px;
              @include mq {
                margin-bottom: 15px;
              }
            }
          }
          .btn-contact {
            margin: auto;
            max-width: 380px;
            width: 100%;
            a {
              background: $black01;
              border-radius: 5px;
              color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 24px;
              line-height: 36px;
              padding: 26px 10px 28px;
              @include mq {
                font-size: 16px;
                line-height: 26px;
                padding: 14px 10px 15px;
              }
              &:hover {
                background: $red;
              }
              img {
                width: 24px;
                margin-top: 2px;
                margin-right: 20px;
                @include mq {
                  width: 18px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
        &-detail {
          color: $white;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          max-width: 380px;
          width: 50%;
          position: relative;
          z-index: 20;
          @include mq {
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
.scene_001 .stage .scene[data-scene="001"],
.scene_002 .stage .scene[data-scene="002"],
.scene_003 .stage .scene[data-scene="003"],
.scene_004 .stage .scene[data-scene="004"],
.scene_005 .stage .scene[data-scene="005"] {
  visibility: visible;
  z-index: 5;
}
