// Base
@import 'base/base';
@import 'base/fontawesome';
// Mixin
@import 'mixin/mixin';
@import 'mixin/z-index';
@import 'mixin/animation';
// Plugin
@import 'plugin/slick';
@import 'plugin/modaal';
@import 'plugin/swiper-bundle.min';
// Fonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Ubuntu:wght@300;400;500;700&family=Zen+Kaku+Gothic+New:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Titillium Web');
// Layout
//----------------------------------------------
// ----------
// common
// ----------
//-----------
// header
//-----------
@import 'layout/header';
@import 'layout/btn-spmenu';
@import 'layout/sp-menu';
//-----------
// footer
//-----------
@import 'layout/footer';
// ------------------------------
// TOP
// ------------------------------
@import 'page/top';
// ------------------------------
// 下層 共通
// ------------------------------
@import 'page/parts';
@import 'page/strengths';
@import 'page/about';
@import 'page/company';
@import 'page/cases';
@import 'page/topics';
@import 'page/contact';
@import 'page/roder';
@import 'page/tekmar';
@import 'page/business_overview';
@import 'page/fibremax';