/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
#co {
    .slick-arrow {
        background: $white;
        border: 1px solid #2167a0;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        height: 45px;
        width: 45px;
        position: absolute;
        left: 0;
        bottom: -75px;
        z-index: 99;
        text-indent: -99999px;
        outline: none;
        @include mq {
            left: 40%;
            transform: translateX(-50%);
        }
        &::after {
            content: "";
            background: url(/img/common/icon_arrow04.png) no-repeat center center;
            background-size: 100% 100%;
            height: 14px;
            width: 14px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        &.slick-prev {
            &::after {
                transform: translate(-50%,-50%) rotate(180deg);
            }
        }
        &.slick-next {
            left: 75px;
            @include mq {
                left: 60%;
                transform: translateX(-50%);
            }
        }
        &:hover {
            background: #2167a0;
            &::after {
                content: "";
                background: url(/img/common/icon_arrow03.png) no-repeat center center;
                background-size: 100% 100%;
            }
        }
    }
    #business02 {
        .slick-arrow {
            left: auto;
            right: 75px;
            &.slick-next {
                left: auto;
                right: 0;
            }
            @include mq {
                right: auto;
                left: 40%;
                transform: translateX(-50%);
                &.slick-next {
                    left: 60%;
                    right: auto;
                }
            }
        }
    }
}
