@keyframes top-side-scroll {
  0% {
    width: 10px;
  }
  100% {
    width: 40px;
  }
}

@keyframes mainv-scroll {
  0% {
    height: 10px;
  }
  100% {
    height: 40px;
  }
}

@keyframes rotate-earth {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes img-drone {
  0% {
    transform: scale(.7) translate3d(10px, 10px, 10px);
  }
  100% {
    transform: scale(1) translate3d(30px, -10px, 10px);
  }
}
