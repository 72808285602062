.page {
  font-size: 1.4rem;
}
.inline-b {
  display: inline-block;
}
.wrapper {
  overflow: hidden;
  position: relative;
}
.ttl01 {
  &._white {
    color: $white;
    font-weight: 500;
    .en {
      color: $white;
    }
  }
  .en {
    color: $main-color;
    font-family: $font-en;
    font-size: 5.8rem;
    font-weight: 300;
    @include ls(50);
    display: block;
    @include mq {
      font-size: 4.5rem;
      line-height: 4.5rem;
    }
  }
  .jp {
    font-size: 1.8rem;
    font-weight: 500;
    @include ls(100);
    line-height: 1.6;
    margin-top: 30px;
    display: block;
    @include mq {
      font-size: 1.6rem;
      margin-top: 20px;
    }
  }
}
.ttl02 {
  color: $white;
  .en {
    font-family: $font-en;
    font-size: 9rem;
    font-weight: 300;
    font-style: italic;
    @include ls(40);
    display: block;
    @include mq {
      font-size: 5rem;
    }
  }
  .jp {
    font-size: 1.8rem;
    font-weight: 500;
    @include ls(80);
    display: block;
    margin-top: 12px;
    @include mq {
      font-size: 1.6rem;
      margin-top: 24px;
    }
  }
}
.ttl03 {
  margin-bottom: 55px;
  @include mq {
    margin-bottom: 40px;
  }
  .en {
    color: #2167a0;
    font-size: 58px;
    font-family: "Ubuntu", "Noto Sans JP", sans-serif;
    line-height: 68px;
    display: block;
    margin-bottom: 30px;
    font-weight: 300;
    @include ls(80);
    @include mq {
      font-size: 45px;
      line-height: 45px;
      margin-bottom: 15px;
    }
  }
  .jp {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 30px;
    display: block;
    @include ls(80);
    @include mq {
      font-size: 1.6rem;
    }
  }
  &._white {
    .en, .jp {
      color: $white;
    }
  }
}
.inner {
  @include inner;
  position: relative;
  z-index: 2;
}
.business-modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #2167A0E6;
  &-close {
    .icon {
      background: #fff;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-left: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      transition: .3s ease;
      top: 60px;
      right: 60px;
      @include mq() {
        top: 30px;
        right: 30px;
      }
      span {
        background: #2167a0;
        width: 20px;
        height: 2px;
        display: block;
        position: absolute;
        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    h1.ttl01 {
      color: $white;
      font-family: "Ubuntu", "Noto Sans JP", sans-serif;
      font-size: 5rem;
      font-style: italic;
      letter-spacing: 0.08em;
      margin-bottom: 50px;
      @include mq() {
        font-size: 3rem;
      }
    }
  }
  &-bnrbox {
    display: flex;
    justify-content: center;
    @include mq() {
      flex-direction: column;
    }
    a {
      margin: 0 30px;
      transition: .3s ease;
      @include mq() {
        margin: 30px 0;
      }
      img {
        width: 260px;
        height: 260px;
        @include mq() {
          width: 180px;
          height: 180px;
        }
      }
      &:hover {
        @include mq(pc) {
          transform: scale(1.05);
          transition: .3s ease;
        }
      }
    }
  }
}
.js-fadein {
  opacity: 0;
  transform: translateY(100px);
  transition: 1.5s ease;
  &.done {
    opacity: 1;
    transform: translateY(0);
  }
}
.js-fadein-order {
  >* {
    opacity: 0;
    transform: translateY(100px);
    transition: 1.5s ease;
    &.done {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.breadcrumb {
  color: $white;
  background: $main-color;
  padding: 50px 0 45px;
  @include mq {
    font-size: 1.2rem;
    padding: 30px 0 20px;
  }
  .inner {
    @include mq {
      padding: 0 0 0 20px;
    }
  }
  ul {
    display: flex;
    justify-content: flex-start;
    @include mq(pc) {
      flex-wrap: wrap;
    }
    @include mq {
      white-space: nowrap;
      overflow-x: scroll;
      padding-bottom: 10px;
    }
    li {
      @include ls(50);
      &:first-child {
        &:before {
          content: '';
          width: 13px;
          height: 18px;
          background: url(/img/common/icon_home.svg) no-repeat;
          background-size: contain;
          display: inline-block;
          vertical-align: middle;
          margin: -3px 5px 0 0;
        }
      }
      &:not(:last-child) {
        &:after {
          content: "\f054";
          color: rgba(255, 255, 255, .5);
          font-family: $font-awesome4;
          font-size: 1.4rem;
          font-weight: 100;
          margin: 0px 16px 0;
          display: inline-block;
          vertical-align: middle;
          @include mq {
            font-size: 2rem;
          }
        }
      }
      &:last-child {
        @include mq {
          padding-right: 35px;
        }
      }
      a {
        @include link_color($white);
        border-bottom: 1px solid currentColor;
        padding-bottom: 2px;
        display: inline-block;
        &:hover {
          opacity: .8;
        }
      }
    }
  }
}
.cmn-mainv {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    background: rgba(23, 28, 33, .6);
    position: absolute;
    top: 0;
    left: 0;
  }
  >video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
  }
  >img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    position: absolute;
    left: 0;
    top: 0;
  }
  h1 {
    color: $white;
    text-align: center;
    position: relative;
    z-index: 10;
    cursor: default;
    span {
      display: block;
    }
    .en {
      font-family: $font-en;
      font-size: 9rem;
      font-style: italic;
      font-weight: 300;
      line-height: 9.5rem;
      @include ls(80);
      @include mq {
        font-size: 5.8rem;
        line-height: 5.5rem;
      }
    }
    .jp {
      font-size: 1.7rem;
      margin-top: 25px;
      letter-spacing: .1rem;
      font-weight: 500;
      @include mq {
        font-size: 1.6rem;
        margin-top: 22px;
      }
    }
  }
  .scroll {
    color: $white;
    font-size: 1.1rem;
    font-family: $font-en;
    @include ls(120);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 10;
    cursor: pointer;
    @include mq {
      bottom: 170px;
      left: 37px;
    }
    .text {
      @include mq {
        transform: rotate(-90deg) translateX(20px);
      }
    }
    span {
      width: 1px;
      height: 40px;
      background: rgba(255, 255, 255, .6);
      display: block;
      margin: 8px auto 0;
      position: relative;
      &:after {
        content: '';
        background: $white;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        animation: mainv-scroll 3s ease infinite normal;
      }
    }
  }
  .sns-list {
    position: absolute;
    left: 50px;
    bottom: 50px;
    z-index: 10;
    @include mq {
      left: 30px;
      bottom: 30px;
    }
    li {
      +li {
        margin-top: 17px;
      }
      &:first-child {
        i {
          font-family: $font-awesome4;
          font-weight: 500;
        }
      }
      a {
        font-size: 2.2rem;
        @include link_color(#7c7c7c);
        &:hover {
          color: $white;
        }
      }
    }
  }
}
.check-style {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.check-style input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  display: none;
}
/* Create a custom checkbox */
.checkmark {
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #2167a0;
  border: 1px solid $white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}
/* On mouse-over, add a grey background color */
.check-style:hover input~.checkmark {
  background-color: none;
}
/* When the checkbox is checked, add a blue background */
.check-style input:checked~.checkmark {
  background-color: #237fca;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  position: absolute;
  display: none;
  left: 5.5px;
  top: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* Show the checkmark when checked */
.check-style input:checked~.checkmark:after {
  display: block;
}
#co {
  .page {
    color: $black01;
    font-size: 14px;
    line-height: 24px;
  }
}
.fl-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.pager-link {
  margin-top: 70px;
  @include mq {
    margin-top: 40px;
  }
  ul {
    justify-content: center;
    align-items: center;
    li {
      a {
        color: #8baecc;
        font-family: $font-en;
        font-weight: bold;
        display: block;
        padding: 0 20px 10px;
        position: relative;
        @include mq {
          padding: 0 10px 5px;
        }
        &::after {
          content: "";
          background: #2167a0;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          height: 5px;
          width: 5px;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          transition: all 0.3s;
          display: none;
        }
        &.active,
        &:hover {
          color: #2167a0;
          &::after {
            display: block;
          }
        }
      }
      &.prev,
      &.next {
        margin-left: 20px;
        @include mq {
          margin-left: 10px;
        }
        a {
          border: 1px solid #2167a0;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          padding: 17px;
          height: 50px;
          width: 50px;
          @include mq {
            padding: 10px;
            height: 35px;
            width: 35px;
          }
          &::after {
            display: none;
          }
        }
        &.prev {
          margin: 0 20px 0 0;
          @include mq {
            margin: 0 10px 0 0;
          }
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
.bnr-business {
  width: 300px;
  height: 135px;
  position: fixed;
  right: 0;
  bottom: 135px;
  z-index: 999;
  transition: .3s ease;
  cursor: pointer;
  .bnr-jp {
    display: inherit;
  }
  .bnr-en {
    display: none;
  }
  &._pc {
    @include mq {
      display: none;
    }
  }
  &._sp {
    @include mq(pc) {
      display: none;
    }
    @include mq {
      width: 210px;
      height: 83px;
      right: 10px;
      bottom: 103px;
      transition: .3s ease;
      .bnr-jp {
        display: inherit;
      }
      .bnr-en {
        display: none;
      }
    }
  }
  &:hover {
    @include mq(pc) {
      transform: scale(1.05);
      transition: .3s ease;
    }
  }
}
.bnr-recruit {
  width: 300px;
  height: 135px;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
  cursor: pointer;
  .bnr-jp {
    display: inherit;
  }
  .bnr-en {
    display: none;
  }
  &._pc {
    @include mq {
      display: none;
    }
  }
  &._sp {
    @include mq(pc) {
      display: none;
    }
    @include mq {
      width: 210px;
      height: 83px;
      right: 10px;
      bottom: 10px;
      transition: .3s ease opacity;
    }
  }
  a {
    display: block;
    &:hover {
      @include mq(pc) {
        transform: scale(1.05);
      }
    }
  }
}
.en_lang {
  img {
    &.img-jp {
      display: none;
    }
    &.img-en {
      display: inherit;
    }
  }
}
.en_lang {
  .cmn-mainv {
    h1 {
      .jp {
        display: none;
      }
    }
    a {
      .bnr-jp {
        display: none;
      }
      .bnr-en {
        display: inherit;
      }
    }
  }
}
