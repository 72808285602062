.about {
  .btn01,
  .btn02 {
    background: $white;
    border: 1px solid #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #2167a0;
    height: 70px;
    width: 365px;
    text-align: center;
    position: relative;
    font-size: 15px;
    @include mq {
      font-size: 14px;
      height: 60px;
      width: 100%;
    }
    &::after {
      content: "";
      background: url(/img/common/icon_arrow04.png) no-repeat center center;
      background-size: 100% 100%;
      height: 14px;
      width: 14px;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      transition: ease 0.3s;
      @include mq {
        right: 10px;
        height: 10px;
        width: 10px;
      }
    }
    &:hover {
      background: #2167a0;
      border: 1px solid #fff;
      color: $white;
      &::after {
        background: url(/img/common/icon_arrow03.png) no-repeat center center;
        background-size: 100% 100%;
      }
    }
  }
  .btn02 {
    background: #3ca0e6;
    border: 1px solid #3ca0e6;
    color: $white;
    height: 70px;
    width: 330px;
    @include mq {
      height: 60px;
      width: 100%;
    }
    &::after {
      background: url(/img/common/icon_arrow03.png) no-repeat center center;
      background-size: 100% 100%;
    }
    &:hover {
      background: #fff;
      border: 1px solid #fff;
      color: #2167a0;
      &::after {
        background: url(/img/common/icon_arrow04.png) no-repeat center center;
        background-size: 100% 100%;
      }
    }
  }
  .sec-01 {
    background: url(/img/about/about_bg01.jpg) no-repeat center bottom;
    background-size: cover;
    padding: 150px 0 200px;
    position: relative;
    @include mq {
      padding: 70px 0 200px;
    }
    .blc {
      color: #fff;
      position: relative;
      z-index: 2;
      justify-content: center;
      .btn01 {
        margin-top: 60px;
      }
      .box-img {
        width: 55%;
        @include mq {
          width: 100%;
        }
        h2 {
          width: 70%;
          @include mq {
            width: 90%;
          }
        }
        .img {
          margin: -15px 0 0 -85px;
          @include mq {
            margin: 0px 0 0 0;
          }
        }
      }
      .box-txt {
        line-height: 30px;
        width: 36%;
        padding-top: 60px;
        letter-spacing: .075rem;
        @include mq {
          font-size: 1.6rem;
          line-height: 2;
          padding-top: 20px;
          width: 100%;
        }
        .ttl {
          font-size: 20px;
          line-height: 38px;
          padding-bottom: 50px;
          @include mq {
            font-size: 18px;
            line-height: 36px;
            padding-bottom: 25px;
          }
        }
      }
    }
  }
  .sec-02 {
    background: #2167a0;
    padding: 150px 0;
    position: relative;
    @include mq {
      padding: 70px 0 90%;
    }
    h2 {
      font-size: 58px;
      line-height: 60px;
      font-family: $font-en;
      font-weight: normal;
      padding-bottom: 40px;
      @include mq {
        font-size: 40px;
        line-height: 50px;
        padding-bottom: 30px;
      }
    }
    .blc {
      color: #fff;
      line-height: 32px;
      width: 40%;
      @include mq {
        font-size: 1.4rem;
        line-height: 2;
        width: 100%;
      }
      .catch {
        font-size: 1.7rem;
        line-height: 30px;
        margin: 10px 0 50px;
        letter-spacing: .1rem;
        @include mq {
          line-height: 2;
          margin: 20px 0 30px;
          font-weight: 300;
          font-size: 2.5rem;
        }
      }
      .btn01 {
        margin-top: 60px;
      }
    }
    .global-img {
      position: absolute;
      right: 0;
      bottom: -60px;
      width: 53%;
      @include mq {
        width: 90%;
        bottom: -34%;
      }
      .img {
        position: relative;
        @include mq {
          padding: 70px 0 90%;
        }
      }
      .img_panel {
        position: absolute;
        left: 12%;
        bottom: 16%;
        width: 36%;
        z-index: 4;
        animation: panel 8s ease-in-out infinite alternate;
        @include mq {
          left: 6%;
          bottom: 60%;
        }
      }
      .img_ship {
        position: absolute;
        left: 0;
        bottom: 44%;
        width: 49%;
        z-index: 1;
        animation: ship 8s ease-in-out infinite alternate;
      }
      .img_drone {
        position: absolute;
        left: 44%;
        top: 8%;
        width: 10%;
        z-index: 4;
        animation: drone 3s ease-in-out infinite alternate;
        @include mq {
          top: 15%;
          width: 14%;
        }
      }
      .img_cloud01 {
        position: absolute;
        left: 10%;
        bottom: 10%;
        width: 49%;
        z-index: 4;
        animation: cloud 7s ease infinite alternate;
        @include mq {
          bottom: 55%;
        }
      }
      .img_cloud02 {
        position: absolute;
        right: -18%;
        top: 0;
        width: 49%;
        z-index: 4;
        @include mq {
          right: -13%;
          top: 8%;
        }
      }
      @keyframes panel {
        0% {
          transform: rotate(-3deg);
        }
        100% {
          transform: rotate(3deg);
        }
      }
      @keyframes ship {
        0% {
          transform: scale(.95) translate3d(10px, 0, 10px);
        }
        100% {
          transform: scale(1) translate3d(-10px, 0, 10px);
        }
      }
      @keyframes drone {
        0% {
          transform: scale(.9) translate3d(10px, -10px, 10px);
        }
        100% {
          transform: scale(1) translate3d(-20px, -10px, 10px);
        }
      }
      @keyframes cloud {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-20px);
        }
      }
    }
  }
  .sec-03 {
    padding: 130px 0 0;
    color: #000;
    @include mq {
      padding: 100px 0 0;
    }
    h2 {
      font-size: 58px;
      font-weight: normal;
      font-family: $font-en;
      line-height: 68px;
      margin-bottom: 40px;
      @include mq {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 20px;
      }
    }
    .txt01 {
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 60px;
      @include mq {
        font-size: 15px;
        line-height: 25px;
        padding-bottom: 30px;
      }
    }
    .link-list {
      margin-bottom: -50px;
      @include mq {
        margin-bottom: -30px;
      }
      li {
        width: 33.2%;
        a {
          background: #2167a0;
          color: $white;
          font-size: 18px;
          line-height: 28px;
          height: 100px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 100%;
          position: relative;
          @include mq {
            font-size: 10px;
            line-height: 20px;
            height: 95px;
          }
          &::after {
            content: "";
            border: solid #fff;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(45deg) translateX(-50%);
            position: absolute;
            left: 50%;
            bottom: 15px;
            @include mq {
              bottom: 10px;
              padding: 2px;
            }
          }
          &:hover {
            background: #00437a;
          }
        }
      }
    }
    .business-content {
      background: #f5f5f9;
      padding: 140px 0;
      @include mq {
        padding: 70px 0;
      }
      .business {
        position: relative;
        padding-bottom: 80px;
        .inner {
          height: 455px;
          display: flex;
          align-items: center;
          @include mq {
            height: auto;
            display: block;
          }
        }
        .link-list {
          margin-top: 60px;
          margin: 60px 0 -50px;
          @include mq {
            margin: 40px 0 -30px;
          }
          li {
            width: 33.2%;
            a {
              background: #2167a0;
              color: $white;
              font-size: 18px;
              line-height: 28px;
              height: 100px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              width: 100%;
              position: relative;
              @include mq {
                font-size: 10px;
                line-height: 20px;
                height: 60px;
              }
              &::after {
                content: "";
                border: solid #fff;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(45deg) translateX(-50%);
                position: absolute;
                left: 50%;
                bottom: 15px;
                @include mq {
                  bottom: 10px;
                  padding: 2px;
                }
              }
              &:hover {
                background: #00437a;
              }
            }
          }
        }
        .box-txt {
          line-height: 27px;
          width: 40%;
          @include mq {
            line-height: 24px;
            width: 100%;
          }
          h3 {
            font-size: 3.4rem;
            font-weight: normal;
            letter-spacing: .08rem;
            line-height: 44px;
            margin-bottom: 60px;
            @include mq {
              font-size: 20px;
              line-height: 30px;
              margin-bottom: 30px;
            }
            span {
              color: #3ca0e6;
              font-size: 1.6rem;
              font-weight: 600;
              line-height: 30px;
              letter-spacing: .08rem;
              display: block;
              padding-bottom: 10px;
              margin-bottom: 30px;
              position: relative;
              @include mq {
                font-size: 1.3rem;
                padding-bottom: 5px;
                margin-bottom: 15px;
              }
              &::after {
                content: "";
                background: #3ca0e6;
                height: 2px;
                width: 15px;
                position: absolute;
                left: 0;
                bottom: 0;
              }
            }
          }
          p {
            @include mq {
              font-size: 1.6rem;
            }
          }
          .btn02 {
            margin-top: 40px;
            @include mq {
              margin-top: 30px;
            }
          }
        }
        .slider-block {
          position: absolute;
          top: 0;
          right: 0;
          width: 48%;
          z-index: 6;
          @include mq {
            margin-top: 60px;
            position: static;
            width: 100%;
          }
          .slider {
            background: $white;
            margin-right: 50px;
            width: 500px;
            @include mq {
              margin-right: 20px;
              width: 250px;
            }
            span {
              display: block;
            }
            a {
              display: block;
              .img {
                position: relative;
                padding-bottom: 65%;
                width: 100%;
                overflow: hidden;
                transition: all 0.3s;
                img {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  transition: ease 0.5s;
                }
              }
              &:hover {
                .img img {
                  transform: translate(-50%, -50%) scale(1.2);
                  transition: ease-out 0.5s;
                }
              }
            }
          }
          .box {
            font-size: 16px;
            padding: 40px;
            @include mq {
              font-size: 14px;
              padding: 20px;
            }
            .label-box {
              justify-content: flex-start;
              margin-bottom: 10px;
              span {
                background: #2167a0;
                border-radius: 30px;
                -webkit-border-radius: 30px;
                color: $white;
                font-size: 12px;
                line-height: 18px;
                display: inline-block;
                text-align: center;
                padding: 3px 10px;
                &:not(:last-child) {
                  margin-right: 10px;
                }
                &.label02 {
                  background: $white;
                  border: 1px solid #2167a0;
                  color: #2167a0;
                }
              }
            }
            .txt02 {
              color: #a1a1a1;
              font-size: 13px;
              padding-top: 15px;
              @include mq {
                font-size: 12px;
                padding-top: 10px;
              }
            }
          }
        }
      }
      #business02 {
        margin: 140px 0;
        @include mq {
          margin: 80px 0;
        }
        .inner {
          justify-content: flex-end;
        }
        .slider-block {
          right: auto;
          left: 0;
          .slider {
            margin: 0 0 0 50px;
            @include mq {
              margin: 0 0 0 20px;
            }
          }
          .slick-track {
            margin-left: 39%;
            @include mq {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .sec-04 {
    background: #4e8abb;
    color: #fff;
    height: 440px;
    position: relative;
    padding: 30px 0 0;
    @include mq {
      height: 350px;
    }
    &::after {
      content: "";
      background: url(/img/about/about_img03.png) no-repeat left top;
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 418px;
      width: 646px;
      @include mq {
        height: 330px;
        left: -240px;
        width: 507px;
      }
    }
    .inner {
      height: 100%;
    }
    .blc {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      width: 100%;
      div {
        font-size: 19px;
        letter-spacing: .08rem;
        width: 55%;
        @include mq {
          font-size: 15px;
          width: 100%;
        }
      }
      h2 {
        font-size: 90px;
        line-height: 100px;
        font-family: $font-en;
        font-weight: normal;
        font-style: italic;
        letter-spacing: 3px;
        padding-bottom: 20px;
        @include mq {
          font-size: 40px;
          line-height: 50px;
          padding-bottom: 10px;
        }
      }
      .link {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        a {
          border: 1px solid #fff;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          height: 80px;
          width: 80px;
          padding: 30px;
          display: inline-flex;
          align-items: center;
          @include mq {
            height: 50px;
            width: 50px;
            padding: 15px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  &.en_lang {
    .btn01,
    .btn02 {
      font-size: 12px;
    }
    .btn02 {
      width: 430px;
      @include mq {
        width: 100%;
      }
    }
    .sec-03 {
      .link-list {
        li {
          a {
            @include mq {
              height: 115px;
            }
          }
        }
      }
      .business-content {
        #business02 {
          margin: 180px 0 280px;
        }
      }
    }
    .sec-04 {
      .blc {
        h2 {
          font-size: unquote("min(calc(60px + (100vw - 1200px) / 48), 75px)");
        }
      }
      div>div>div>p:nth-child(2) {
        display: none;
      }
    }
  }
}
_:-ms-fullscreen, :root .about .sec-03 #business02 .slider-block .slick-track {
  margin-left: 45%;
}
