.top {
  background: #e1e1e1;
  @include mq {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }
  &.ipad {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
  }
  >#wrapper {
    animation: fade 0.2s ease 0.3s 1 normal;
  }
  .cover_load {
    position: absolute;
    display: flex;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    left: 50%;
    transform: translate(-50%, 0);
    background: #e1e1e1;
  }
  .loadimg {
    display: inline;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: rotate-anime 5s linear infinite;
  }
  @keyframes rotate-anime {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .popup {
    position: absolute;
    display: flex;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    left: 50%;
    transform: translate(-50%, 0);
    overflow: hidden;
    align-content: center;
    background-color: #000000d0;
    video {
      width: 100%;
      object-fit: cover;
      @include mq {
        object-fit: contain;
      }
    }
    #skip_btn {
      position: absolute;
      color: #ffffff;
      font-size: 20px;
      left: 50%;
      bottom: 20%;
      font-family: "Ubuntu", "Noto Sans JP", sans-serif;
      transform: translate(-50%, 0);
      border: 2px solid #ffffff;
      padding: 2px 15px;
      animation: fade 0.2s ease 0.3s 1 normal;
      &:hover {
        color: #000000;
        border: 2px solid #ffffff;
        background-color: #ffffff;
        animation: fade 0.2s ease 0.3s 1 normal;
      }
      @keyframes fade {
        0% {
          opacity: 0
        }
        100% {
          opacity: 1
        }
      }
    }
  }
  .wheel {
    opacity: 0;
    position: fixed;
    left: 0;
    bottom: 0;
    pointer-events: none;
  }
  .side-nav {
    display: none !important;
    position: fixed;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    @include mq(spc) {
      right: 15px;
    }
    &.ptn2 {
      ul {
        li {
          &:first-child {
            a svg path {
              fill: $white;
            }
          }
          &:not(:first-child) {
            a {
              &:before {
                background: $white;
              }
            }
          }
          &.active {
            a {
              border-color: $white;
            }
          }
        }
      }
    }
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      li {
        width: 18px;
        height: 18px;
        margin: 4px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          padding: 3px;
          border: 2px solid transparent;
          border-radius: 50%;
        }
        &:first-child {
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              width: 8px;
              height: 8px;
              path {
                fill: #4e5c6b;
                transition: 1s ease;
              }
            }
          }
        }
        &:not(:first-child) {
          a {
            display: block;
            &:before {
              content: '';
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #4e5c6b;
              display: block;
              transition: 1s ease;
            }
          }
        }
        &.active {
          a {
            border-color: #4e5c6b;
          }
        }
      }
    }
  }
  .side-scroll {
    color: $main-color;
    font-family: $font-en;
    font-size: 1.2rem;
    @include ls(120);
    position: fixed;
    left: 7px;
    bottom: 210px;
    z-index: 999;
    transform: translateY(-50%) rotate(-90deg);
    transition: .3s ease;
    cursor: pointer;
    @include mq(spc) {
      left: -13px;
    }
    @include mq {
      font-size: 1rem;
      left: -28px;
      bottom: 58px;
    }
    &.end {
      opacity: 0;
      pointer-events: none;
    }
    &.ptn2 {
      color: $white;
      p {
        span {
          &:before,
          &:after {
            background: $white;
          }
        }
      }
    }
    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      transition: .3s ease;
      span {
        width: 40px;
        height: 1px;
        margin-right: 9px;
        display: block;
        position: relative;
        transform: rotate(180deg);
        &:before,
        &:after {
          content: '';
          height: 100%;
          background: $main-color;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          transition: .3s ease background;
        }
        &:before {
          width: 100%;
          opacity: .6;
        }
        &:after {
          animation: top-side-scroll 3s ease infinite normal;
        }
      }
    }
  }
  .side-sns-list {
    @include mq {
      display: none;
    }
    position: fixed;
    left: 48px;
    bottom: 50px;
    z-index: 999;
    @include mq(spc) {
      left: 27px;
    }
    &.ptn2 {
      li a {
        @include link_color($white);
        &:hover {
          @include link_color($white);
          opacity: .6;
        }
      }
    }
    li {
      +li {
        margin-top: 17px;
      }
      a {
        font-size: 2.2rem;
        @include link_color(#7c7c7c);
        &:hover {
          @include link_color($text-color);
        }
      }
      &:first-child {
        i {
          font-family: $font-awesome4;
          font-weight: 300;
        }
      }
    }
  }
  // top-cmn
  .ttl {
    .en {
      color: #3ca0e6;
      font-size: 2rem;
      font-family: $font-en;
      font-weight: bold;
      @include ls(25);
      position: relative;
      padding-bottom: 14px;
      margin-bottom: 35px;
      display: block;
      @include mq {
        font-size: 1.6rem;
        margin-bottom: 20px;
      }
      &:after {
        content: '';
        display: block;
        width: 15px;
        height: 2px;
        background: #3ca0e6;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .jp {
      font-size: 3.4rem;
      font-weight: 100;
      @include ls(25);
      @include lh(34, 56);
      @include mq {
        font-size: 2rem;
      }
    }
    .indent {
      display: inline-block;
      text-indent: -0.5em;
    }
  }
  .link-more {
    margin-top: 55px;
    @include mq {
      margin-top: 25px;
    }
    a {
      @include link_color($white);
      font-family: $font-en;
      font-weight: bold;
      @include ls(25);
      background: $blue02;
      width: 170px;
      height: 50px;
      padding-right: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      @include mq(lsp) {
        font-size: 1.5rem;
        width: 200px;
        height: 53px;
      }
      @include mq(msp) {
        width: 180px;
        font-weight: 500;
        @include ls(75);
        height: 43px;
        padding-right: 40px;
      }
      &:hover {
        &:after {
          transform: translateX(4px);
        }
      }
      &:after {
        content: '';
        width: 13px;
        height: 13px;
        background: url(/img/common/icon_arrow.svg) no-repeat;
        background-size: contain;
        position: absolute;
        right: 14px;
        top: calc(50% - 6px);
        transition: .3s ease;
      }
    }
    &._white {
      a {
        @include link_color($main-color);
        background: $white;
        &:after {
          background: url(/img/common/icon_arrow02.svg) no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .cmn-text {
    font-weight: 400;
    @include ls(25);
    @include lh(14, 27);
    margin-top: 40px;
    @include mq(lsp) {
      font-size: 1.3rem;
    }
    @include mq(msp) {
      font-size: 1.2rem;
      font-weight: 300;
      @include lh(13, 22);
      margin-top: 15px;
    }
  }
  .bg-movie {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    &:after {
      content: '';
      display: block;
      width: 100vw;
      height: 100vh;
      background: #031f37;
      opacity: .7;
      position: absolute;
      top: 0;
      left: 0;
    }
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
    }
  }
  .slide-arrow {
    width: 45px;
    height: 45px;
    background: $main-color;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s ease;
    @include mq {
      width: 40px;
      height: 40px;
    }
    &:before {
      content: '';
      width: 13px;
      height: 13px;
      background: url(/img/common/icon_arrow.svg) no-repeat;
      background-size: contain;
    }
    &:hover {
      background: $hover-main;
    }
    &._prev {
      left: 0;
      transform: rotate(180deg);
      &:hover {
        left: -3px;
      }
    }
    &._next {
      right: 0;
      &:hover {
        right: -3px;
      }
    }
    &._white {
      background: #fff0;
      border: 1px solid $main-color;
      &:before {
        background: url(/img/common/icon_arrow02.svg) no-repeat;
      }
      &:hover {
        background: $main-color;
      }
      &:hover:before {
        background: url(/img/common/icon_arrow.svg) no-repeat;
      }
    }
  }
  // /top-cmn
  .sec-wrap {
    width: 100%;
    height: 100vh;
    background: $main-color;
    position: relative;
    overflow: hidden;
    @include mq {
      height: calc(var(--vh, 1vh) * 100);
    }
  }
  .sec {
    width: 100%;
    height: 100vh;
    padding: 110px;
    background: #e1e1e1;
    position: absolute;
    top: 150%;
    left: -150%;
    overflow: hidden;
    @include mq(spc) {
      padding: 50px;
    }
    @include mq {
      height: 99vh;
      height: calc(var(--vh, 1vh) * 100);
      padding: 85px 35px 55px;
    }
    &._01 {
      z-index: 10;
      top: 0;
      left: 0;
      @include mq(pc) {
        padding: 0 67px;
      }
      @include mq {
        padding-left: 30px;
        padding-right: 30px;
      }
      &:not(.active) {
        .bnr-recruit,
        .bnr-business {
          &._sp {
            @include mq {
              opacity: 0;
              pointer-events: none;
            }
          }
        }
      }
      .earth {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        @include mq {
          top: 54%;
        }
        &:before,
        &:after {
          content: '';
          border-radius: 50%;
          width: 290px;
          height: 290px;
          border: 1px solid $white;
          display: block;
          position: absolute;
          @include mq {
            width: 145px;
            height: 145px;
          }
        }
        &:before {
          left: -12%;
          bottom: 5%;
          @include mq {
            left: -4%;
            bottom: -11%;
          }
        }
        &:after {
          right: -12%;
          top: 10%;
          z-index: -10;
          @include mq {
            right: -10%;
            top: 39%;
          }
        }
        img {
          position: absolute;
          width: auto;
          pointer-events: none;
        }
        .img-earth {
          width: 50vw;
          max-width: 676px;
          height: 50vw;
          max-height: 676px;
          position: relative;
          @include mq {
            width: calc(100vw - 35px);
            height: calc(100vw - 35px);
          }
        }
        .img-cloud {
          @media screen and (max-width: 1330px) {
            width: 55%;
          }
          &._01 {
            top: 15%;
            left: -25%;
            animation: cloud1 7s ease infinite alternate;
          }
          &._02 {
            bottom: 10%;
            left: 5%;
            animation: cloud2 7s ease infinite alternate;
            @include mq {
              bottom: -5%;
            }
          }
          &._03 {
            right: -30%;
            bottom: 38%;
            animation: cloud3 7s ease infinite alternate;
            @include mq {
              bottom: 28%;
            }
          }
          @keyframes cloud1 {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(20px);
            }
          }
          @keyframes cloud2 {
            0% {
              transform: translateX(0);
            }
            50% {
              transform: translateX(-10px);
            }
            100% {
              transform: translateX(5px);
            }
          }
          @keyframes cloud3 {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-20px);
            }
          }
        }
        .img-drone {
          position: absolute;
          top: 11%;
          left: -15%;
          animation: drone 3s ease-in-out infinite alternate;
          @include mq {
            width: 57px;
            top: 7%;
            left: 0;
          }
          @keyframes drone {
            0% {
              transform: scale(.7) translate3d(10px, -10px, 10px);
            }
            100% {
              transform: scale(1) translate3d(-30px, -10px, 10px);
            }
          }
        }
        .img-boy {
          top: 16%;
          right: -20%;
          @media screen and (max-width: 1330px) and (min-width: 769px) {
            width: 47%;
          }
          &02 {
            @include mq {
              width: 50%;
              top: -20%;
              right: -7%;
            }
          }
        }
        .img-rocket {
          width: 60%;
          top: -24%;
          left: 25%;
          transform: rotate(20deg);
          @include mq {
            width: 52%;
            top: -28%;
            left: 14%;
          }
          &02 {
            width: 60%;
            position: absolute;
            top: 8%;
            right: -45%;
            animation: rocket02 6s ease-in-out infinite alternate;
            z-index: -1;
            @keyframes rocket02 {
              0% {
                transform: scale(.95) translate3d(8px, 0, -8px);
              }
              100% {
                transform: scale(1) translate3d(-8px, 0, -8px);
              }
            }
          }
        }
        .img-vr {
          top: 30%;
          left: -35%;
          @media screen and (max-width: 1330px) {
            width: 40%;
          }
          @include mq {
            top: -20%;
            left: -6%;
          }
        }
        .img-shovel {
          top: 0;
          right: 3%;
          @media screen and (max-width: 1330px) {
            width: 25%;
          }
          @include mq {
            top: 76%;
            right: 0;
            transform: rotate(90deg);
          }
        }
        .img-windmill {
          top: 0;
          left: 10%;
          @media screen and (max-width: 1330px) {
            width: 22%;
          }
          @include mq {
            transform: rotate(90deg);
            top: 28%;
            left: auto;
            right: -6%;
          }
        }
        .img-ship {
          left: -18%;
          bottom: 15%;
          animation: ship 8s ease-in-out infinite alternate;
          @media screen and (max-width: 1330px) {
            width: 60%;
          }
          @include mq {
            width: 50%;
            bottom: -3%;
            left: -5%;
          }
          @keyframes ship {
            0% {
              transform: scale(.95) translate3d(10px, 0, 10px);
            }
            100% {
              transform: scale(1) translate3d(-10px, 0, 10px);
            }
          }
        }
        .img-panel {
          right: -20%;
          bottom: 11%;
          animation: panel 8s ease-in-out infinite alternate;
          @media screen and (max-width: 1330px) {
            width: 41%;
          }
          @include mq {
            display: none;
          }
          @keyframes panel {
            0% {
              transform: rotate(-3deg);
            }
            100% {
              transform: rotate(3deg);
            }
          }
        }
      }
      .catch {
        color: $white;
        text-align: center;
        white-space: nowrap;
        text-shadow: 0 0 10px #405673;
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: default;
        transform: translate(-50%, -50%);
        strong {
          font-size: 8rem;
          font-family: $font-en;
          font-style: italic;
          font-weight: 500;
          @include ls(50);
          display: block;
          @media screen and (max-width: 1330px) and (min-width: 769px) {
            font-size: 6vw;
          }
          @media screen and (min-width: 768px) {
            font-size: 7rem;
          }
          @include mq {
            font-size: 4.2rem;
          }
        }
        span {
          font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
          font-size: 1.7rem;
          font-weight: bold;
          margin-top: 20px;
          display: block;
          @include mq {
            font-size: 1.3rem;
          }
          @media screen and (min-width: 768px) {
            font-size: 1.8rem;
          }
        }
      }
      .link-movie {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translateX(-50%);
        a {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background: #22a4ff;
          background: linear-gradient(120deg, #0073d1 35%, #2fa9ff 100%);
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto 12px;
          box-shadow: 0 3px 6px 0 #3d4959;
          animation: movie_btn 0.8s ease 0s infinite alternate;
          &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 0 8px 16px;
            border-color: transparent transparent transparent #fff;
          }
          &:hover {
            background: #0e73ba;
            background: linear-gradient(120deg, #2fa9ff 35%, #0073d1 100%);
          }
        }
        span {
          color: $white;
          font-size: 1.2rem;
          @include ls(100);
          font-family: $font-en;
          font-weight: bold;
        }
      }
      @keyframes movie_btn {
        from {
          transform: scale(0.9, 0.9);
        }
        to {
          transform: scale(1, 1);
        }
      }
      @keyframes top-link_btn {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: 100% 0;
        }
      }
      @-webkit-keyframes top-link_btn {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: 100% 0;
        }
      }
      .news {
        @include mq {
          display: none;
        }
        display: flex;
        align-items: center;
        position: absolute;
        left: 100px;
        bottom: 25px;
        .date {
          color: #1a1a1a;
          font-family: $font-en;
          font-weight: 600;
          margin-right: 12px;
        }
        .text {
          margin-right: 20px;
          a {
            color: #1a1a1a;
            font-size: 1.4rem;
            @include ls(25);
            border-bottom: 2px solid #bbb;
            padding-bottom: 2px;
            &:hover {
              border-bottom-color: currentColor;
            }
          }
        }
        .more {
          a {
            font-family: $font-en;
            font-size: 1.3rem;
            font-weight: bold;
            @include ls(50);
            width: 60px;
            height: 26px;
            padding-bottom: 1px;
            border: 1px solid #828282;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 17px;
            &:hover {
              background: #cecbcb;
            }
          }
        }
      }
    }
    &._02 {
      background: $white;
      display: flex;
      align-items: center;
      @include mq(pc) {
        padding-right: 0;
      }
      .sec-inner {
        @include mq {
          width: 100%;
        }
      }
      .slide-num {
        margin-top: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        @include mq {
          margin-top: 25px;
          justify-content: flex-end;
        }
        .num {
          font-family: $font-en;
          margin: 0 16px;
        }
        .num1 {
          color: $main-color;
          padding-right: 10px;
          &:after {
            content: '/';
            padding-left: 10px;
            opacity: .6;
          }
        }
        .num2 {
          color: $main-color;
          opacity: .6;
        }
      }
      .slide-service {
        width: calc(100vw - 100px);
        counter-reset: number -1;
        position: relative;
        @include mq {
          width: 100%;
        }
        .slick-list {
          overflow: visible;
        }
        .slick-track {
          cursor: grab;
        }
        .slide-arrow {
          @include mq {
            display: none;
          }
        }
        .item {
          margin: 0 60px;
          @include mq(pc) {
            transform: translateX(10px);
          }
          @include mq(spc) {
            margin-right: 0;
          }
          @include mq {
            margin: 0 12px;
            width: calc(100vw - 70px);
          }
        }
        .item-inner {
          width: calc(100vw - 120px);
          position: relative;
          display: flex;
          align-items: center;
          @include mq(spc) {
            width: calc(100vw - 60px);
          }
          @include mq {
            width: 100%;
            flex-direction: column;
          }
          .text-area {
            width: 30%;
            padding-right: 50px;
            @include mq(mpc) {
              width: 40%;
            }
            @include mq(spc) {
              width: 50%;
            }
            @include mq {
              width: 100%;
              order: 2;
              padding-right: 0;
            }
            h3 {
              &:before {
                @include mq(pc) {
                  counter-increment: number;
                  content: counter(number, decimal-leading-zero);
                  font-family: $font-en;
                  color: #9f9f9f;
                  font-weight: 400;
                }
              }
              span {
                color: $main-color;
                font-size: 1.7rem;
                @include lh(17, 28);
                display: block;
                margin-top: 20px;
                @include ls(100);
                font-weight: 500;
                @include mq {
                  font-size: 1.2rem;
                  font-weight: 300;
                }
              }
              strong {
                font-size: 5.4rem;
                font-family: $font-en;
                font-weight: 500;
                @include ls(80);
                @include lh(54, 66);
                display: block;
                margin-top: 15px;
                @include mq(pc) {
                  font-style: italic;
                }
                @include mq {
                  font-size: 2.4rem;
                  font-weight: 200;
                  margin-top: 0;
                }
              }
            }
            p {
              margin-top: 35px;
              font-size: 1.4rem;
              @include lh(14, 27);
              @include mq {
                font-size: 1.2rem;
                margin-top: 15px;
              }
            }
          }
          .img {
            width: 70%;
            @include mq(pc) {
              height: 100vh;
            }
            @include mq(mpc) {
              width: 60%;
            }
            @include mq(spc) {
              width: 50%;
            }
            @include mq(lsp) {
              width: 100%;
              height: 40vh;
            }
            @include mq(msp) {
              height: 200px;
              order: 1;
            }
            img {
              @include object-fit(cover);
            }
          }
        }
      }
    }
    &._03 {
      color: $white;
      background: #005ba5;
      @include mq(pc) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .box {
        width: 470px;
        position: relative;
        @include mq {
          width: 100%;
          margin-top: 15vw;
        }
      }
    }
    &._04 {
      display: flex;
      @include mq(pc) {
        align-items: center;
      }
      @include mq {
        padding-bottom: 70vw;
        min-height: 100vh;
      }
      &:after {
        @include mq {
          content: '';
          width: 100vw;
          height: 100vh;
          background: url(/img/top/img_company_sp.png) no-repeat;
          background-size: contain;
          background-position: bottom center;
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      .box {
        width: 40%;
        position: relative;
        z-index: 1;
        @include mq {
          margin-top: 15vw;
          width: 100%;
        }
        &-inner {
          width: 440px;
          margin-left: auto;
          @include mq {
            width: 100%;
          }
        }
      }
      .earth {
        position: absolute;
        bottom: 0;
        right: 0;
        @include mq {
          display: none;
        }
        &:before,
        &:after {
          content: '';
          border-radius: 50%;
          width: 290px;
          height: 290px;
          border: 1px solid $white;
          display: block;
          position: absolute;
        }
        &:before {
          left: 8%;
          top: -10%;
        }
        &:after {
          left: -16%;
          bottom: 10%;
          z-index: -10;
        }
        img {
          position: absolute;
          width: auto;
          @media screen and (max-width: 1330px) and (min-width: 769px) {}
        }
        .img-earth {
          width: 50vw;
          max-width: 676px;
          height: 50vw;
          max-height: 676px;
          position: relative;
        }
        .img-cloud {
          @media screen and (max-width: 1330px) and (min-width: 769px) {
            width: 55%;
          }
          &._01 {
            top: 11%;
            left: 0%;
            animation: cloud1 7s ease infinite alternate;
          }
          &._02 {
            bottom: 10%;
            left: -10%;
            animation: cloud2 7s ease infinite alternate;
          }
          &._03 {
            right: -30%;
            bottom: 38%;
            animation: cloud3 7s ease infinite alternate;
          }
        }
        .img-shovel {
          width: 132px;
          top: 5%;
          right: -2%;
          @media screen and (max-width: 1330px) and (min-width: 769px) {
            width: 18%;
          }
        }
        .img-rocket {
          top: -16%;
          left: 1%;
          @media screen and (max-width: 1330px) and (min-width: 769px) {
            width: 53%;
          }
        }
        .img-vr {
          top: 30%;
          left: -32%;
          @media screen and (max-width: 1330px) and (min-width: 769px) {
            width: 40%;
          }
        }
        .img-build {
          top: -10%;
          right: 18%;
        }
        .img-windmill {
          top: 0;
          left: 10%;
          @media screen and (max-width: 1330px) and (min-width: 769px) {
            width: 22%;
          }
        }
        .img-drone {
          position: absolute;
          top: -17%;
          left: 20%;
          animation: drone 3s ease-in-out infinite alternate;
          @include mq {
            width: 57px;
            top: 2%;
            left: auto;
            right: 28%;
          }
        }
        .img-panel {
          left: -20%;
          bottom: 11%;
          animation: panel2 8s ease-in-out infinite alternate;
          @media screen and (max-width: 1330px) and (min-width: 769px) {
            width: 41%;
          }
        }
        @keyframes panel2 {
          0% {
            transform: rotate(-3deg) scale(-1, 1);
          }
          100% {
            transform: rotate(3deg) scale(-1, 1);
          }
        }
      }
    }
    &._05 {
      .bg-movie {
        &:after {
          background: #000;
          opacity: .5;
        }
      }
      color: $white;
      display: flex;
      @include mq(pc) {
        justify-content: flex-end;
        align-items: center;
      }
      .box {
        width: 390px;
        position: relative;
        z-index: 1;
        @include mq {
          margin-top: 15vw;
          width: 100%;
        }
      }
    }
    &._06 {
      display: flex;
      justify-content: center;
      align-items: center;
      &.done {
        .catch {
          animation: topRecruit-anim 5s ease normal forwards;
        }
        .link-wrap {
          animation: topRecruit-anim2 6.5s ease normal forwards;
        }
        .earth {
          animation: topRecruit-anim3 5.5s ease normal forwards;
        }
      }
      &.sp-done {
        .catch {
          animation: topRecruit-anim 4s ease normal forwards;
        }
        .link-wrap {
          animation: topRecruit-anim2 7s ease normal forwards;
        }
        .earth {
          animation: topRecruit-anim3 6s ease normal forwards;
        }
      }
      .img-jp {
        display: inherit;
      }
      .img-en {
        display: none;
      }
      .earth {
        width: 130vw;
        height: 65vh;
        position: absolute;
        left: 50%;
        opacity: 0;
        transform: translateX(-50%);
        @include mq {
          height: 30vh;
          z-index: 1;
        }
      }
      .link-wrap {
        max-width: 1171px;
        position: relative;
        opacity: 0;
        @include mq {
          margin: -60px -35px 0;
        }
        a {
          display: block;
          &:hover {
            @include mq(pc) {
              transform: scale(1.05);
            }
          }
        }
      }
      .catch {
        width: 100vw;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        opacity: 0;
        @include mq {}
      }
      @keyframes topRecruit-anim {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        60% {
          opacity: 1;
        }
        80% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      @keyframes topRecruit-anim2 {
        0% {
          opacity: 0;
        }
        85% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @keyframes topRecruit-anim3 {
        0% {
          bottom: -20%;
          opacity: 0;
        }
        70% {
          bottom: -20%;
          opacity: 0;
        }
        100% {
          bottom: 0;
          opacity: 1;
        }
      }
    }
    &._07 {
      color: $black01;
      background: #eee;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
        content: '';
        width: 100vw;
        max-width: 791px;
        height: 100vh;
        display: block;
        background: url(/img/top/bg_project.png) no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
      }
      .box {
        @include mq {
          width: 100%;
        }
      }
      .ttl-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $main-color;
      }
      h2 {
        font-size: 3.2rem;
        font-weight: 200;
        @include mq {
          font-size: 2rem;
        }
      }
      .slide-arrow-box {
        width: 110px;
        display: flex;
        justify-content: space-between;
        @include mq {
          margin: 13px auto 0;
        }
        &.pc {
          @include mq {
            display: none;
          }
        }
        &.sp {
          @include mq(pc) {
            display: none;
          }
        }
      }
      .slide-project {
        margin: 30px -25px 0;
        width: calc(100vw - 240px);
        @include mq(spc) {
          width: calc(100vw - 120px);
        }
        @include mq {
          margin: 17px 0 0;
          width: calc(100vw - 70px);
        }
        .slick-list {
          overflow: visible;
        }
        .item {
          margin: 0 25px;
          @include mq {
            width: calc(100vw - 70px);
          }
          .img {
            height: 30vh;
            @include mq(lsp) {
              width: 100%;
              height: 33vh;
            }
            @include mq(msp) {
              height: 23vh;
            }
            img {
              @include object-fit(contain);
            }
          }
          .place {
            font-size: 1.2rem;
            margin-top: 20px;
            >* {
              border-radius: 12px;
              padding: 4px 8px;
              border: 1px solid $main-color;
              color: $main-color;
              display: inline-block;
              &:not(:last-child) {
                margin-right: 8px;
              }
            }
            dt {
              color: $white;
              background: $main-color;
            }
          }
          p {
            font-size: 1.6rem;
            @include lh(16, 24);
            margin-top: 10px;
          }
          .info {
            font-size: 1.2rem;
            margin-top: 10px;
            opacity: .6;
            color: $gray03;
            li {
              @include ls(50);
              display: inline-block;
              &:not(:first-child) {
                &:before {
                  content: '|';
                  margin: 0 3px;
                }
              }
            }
          }
        }
      }
      .link-box {
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
        @include mq {
          margin-top: 12px;
          flex-direction: column;
          a {
            width: 100% !important;
            justify-content: flex-start !important;
            padding-left: 20px;
          }
        }
        .link-more {
          margin-top: 0;
        }
        .link-pdf {
          margin-left: 14px;
          @include mq {
            margin: 10px 0 0;
          }
          a {
            @include link_color($blue02);
            @include ls(25);
            width: 190px;
            height: 50px;
            border: 1px solid $blue02;
            display: flex;
            justify-content: center;
            align-items: center;
            @include mq {
              height: 43px;
            }
            &:before {
              content: '';
              width: 16px;
              height: 19px;
              margin-right: 10px;
              background: url(/img/common/icon_document_blue.svg) no-repeat;
              background-size: contain;
              display: block;
            }
            &:hover {
              background: $hover-white;
            }
          }
        }
      }
    }
    &._08 {
      background-image: url(/img/top/bg_roder.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 22% 75%;
      background-color: #eaeaea;
      padding-right: 180px;
      @include mq(pc) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      @include mq(spc) {
        padding-right: 60px;
      }
      @include mq {
        background-image: url(/img/top/bg_roder.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right bottom;
        min-height: 85vh;
        padding-bottom: 48vw;
        display: block;
        padding-right: 35px;
      }
      .img_ttl {
        width: 150px;
        height: 59px;
        @include mq {
          width: 120px;
          height: auto;
        }
      }
      .box {
        width: 100%;
        &-inner {
          width: 100%;
        }
        @include mq(lsp) {
          margin-top: 15vw;
        }
        @include mq(msp) {
          margin-top: 15vw;
        }
      }
      h2 {
        color: #0067ba;
        font-family: $font-en;
        font-size: 6rem;
        font-style: italic;
        font-weight: 500;
        @include ls(25);
        white-space: nowrap;
        @include mq {
          font-size: 3.5rem;
        }
      }
      .lead {
        font-size: 1.8rem;
        font-weight: 500;
        @include ls(80);
        @include lh(18, 34);
        margin-top: 30px;
        @include mq {
          font-size: 1.5rem;
          margin-top: 20px;
        }
      }
      .text {
        @include lh(14, 29);
        margin-top: 35px;
        @include mq {
          font-size: 1.1rem;
          width: 100%;
          margin-top: 20px;
        }
      }
      .img-ceo {
        width: auto;
        margin-top: 30px;
        @include mq {
          width: 110px;
          margin-top: 20px;
        }
      }
      .btn {
        margin-top: 40px;
        >a {
          @include ls(25);
          width: 380px;
          height: 70px;
          border: 1px solid #d2d2d2;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: .3s ease;
          @include mq {
            width: 134px;
            height: 50px;
          }
          &:after {
            content: '\f067';
            font-family: $font-awesome;
            font-size: 16px;
            font-weight: bold;
            margin-left: 19px;
          }
          &:hover {
            background: #f1f1f1;
          }
        }
      }
      .link-more {
        margin-top: 55px !important;
      }
      .top-footer {
        @include mq {
          display: none;
        }
        color: #c5c5c5;
        font-family: $font-en;
        font-size: 1rem;
        @include ls(100);
        font-weight: bold;
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 30px;
        left: 0;
      }
      h3 strong {
        font-size: 5.4rem;
        font-family: "Ubuntu", "Noto Sans JP", sans-serif;
        font-weight: 500;
        letter-spacing: 0.08em;
        line-height: 1.22222;
        display: block;
        margin-top: 15px;
        font-style: italic;
        color: $white;
        @include mq() {
          font-size: 2.4rem;
        }
      }
    }
    &._09 {
      background-image: url(/img/top/bg_tekmar.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 75%;
      background-color: #eaeaea;
      padding-right: 180px;
      @include mq(pc) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      @include mq(spc) {
        padding-right: 60px;
      }
      @include mq {
        background-image: url(/img/top/bg_tekmar.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position:  50% 75%;
        min-height: 85vh;
        padding-bottom: 48vw;
        display: block;
        padding-right: 35px;
      }
      .img_ttl {
        width: 269px;
        height: 121px;
        img {
          margin: 30px 30px -30px -30px;
        }
        @include mq {
          width: 180px;
          height: auto;
          img {
            margin: -15px -15px -15px -15px;
          }
        }
      }
      .box {
        width: 100%;
        &-inner {
          width: 100%;
        }
        @include mq(lsp) {
          margin-top: 15vw;
        }
        @include mq(msp) {
          margin-top: 15vw;
        }
      }
      h2 {
        color: #0067ba;
        font-family: $font-en;
        font-size: 6rem;
        font-style: italic;
        font-weight: 500;
        @include ls(25);
        white-space: nowrap;
        @include mq {
          font-size: 3.5rem;
        }
      }
      .lead {
        font-size: 1.8rem;
        font-weight: 500;
        @include ls(80);
        @include lh(18, 34);
        margin-top: 30px;
        @include mq {
          font-size: 1.5rem;
          margin-top: 20px;
        }
      }
      .text {
        @include lh(14, 29);
        margin-top: 35px;
        @include mq {
          font-size: 1.1rem;
          width: 100%;
          margin-top: 20px;
        }
      }
      .img-ceo {
        width: auto;
        margin-top: 30px;
        @include mq {
          width: 110px;
          margin-top: 20px;
        }
      }
      .btn {
        margin-top: 40px;
        >a {
          @include ls(25);
          width: 380px;
          height: 70px;
          border: 1px solid #d2d2d2;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: .3s ease;
          @include mq {
            width: 134px;
            height: 50px;
          }
          &:after {
            content: '\f067';
            font-family: $font-awesome;
            font-size: 16px;
            font-weight: bold;
            margin-left: 19px;
          }
          &:hover {
            background: #f1f1f1;
          }
        }
      }
      .link-more {
        margin-top: 55px !important;
      }
      .top-footer {
        @include mq {
          display: none;
        }
        color: #c5c5c5;
        font-family: $font-en;
        font-size: 1rem;
        @include ls(100);
        font-weight: bold;
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 30px;
        left: 0;
      }
      h3 strong {
        font-size: 5.4rem;
        font-family: "Ubuntu", "Noto Sans JP", sans-serif;
        font-weight: 500;
        letter-spacing: 0.08em;
        line-height: 1.22222;
        display: block;
        margin-top: 15px;
        font-style: italic;
        color: $white;
        @include mq() {
          font-size: 2.4rem;
        }
      }
    }
  }
  .modal {
    display: none;
    position: relative;
    z-index: 999;
    .overlay {
      width: 100vw;
      height: 100vh;
      background: #0067ba;
      opacity: .9;
      position: fixed;
      top: 0;
      left: 0;
    }
    .conts {
      background: $white;
      width: 90vw;
      max-width: 800px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .btn-close {
        color: $white;
        font-size: 2.4rem;
        position: absolute;
        top: -35px;
        right: 0;
        cursor: pointer;
      }
      &-inner {
        padding: 55px 67px;
        @include mq {
          padding: 35px 30px;
        }
      }
      h3 {
        color: #0067ba;
        font-family: $font-en;
        font-size: 4.4rem;
        @include ls(25);
        font-style: italic;
        font-weight: bold;
        @include mq {
          font-size: 3.5rem;
        }
      }
      dl {
        margin-top: 30px;
        border-top: 1px solid #d2d2d2;
        @include mq {
          margin-top: 22px;
        }
        >div {
          border-bottom: 1px solid #d2d2d2;
          padding: 15px 0;
          @include mq(pc) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
          }
        }
        dt {
          color: #0067ba;
          font-size: 1.4rem;
          font-weight: bold;
          @include ls(50);
          padding-right: 20px;
          @include mq(pc) {
            width: 150px;
          }
        }
        dd {
          color: #333;
          font-size: 1.3rem;
          @include ls(100);
          @include lh(13, 22);
          @include mq(pc) {
            width: calc(100% - 150px);
          }
          @include mq {
            font-size: 1.2rem;
            margin-top: 15px;
          }
        }
      }
    }
  }
  .en_lang {
    .bnr-recruit {
      a {
        .bnr-jp {
          display: none;
        }
        .bnr-en {
          display: inherit;
        }
      }
    }
    .bnr-business {
      a {
        .bnr-jp {
          display: none;
        }
        .bnr-en {
          display: inherit;
        }
      }
    }
    .ttl {
      .en {
        font-size: 1.5rem;
      }
      .jp {
        font-size: 2.8rem;
        line-height: 1.4;
      }
    }
    .sec {
      &._01 {
        .catch {
          span {
            font-family: "Ubuntu", "Noto Sans JP", sans-serif;
            font-weight: 600;
            letter-spacing: .075rem;
            font-size: 1.6rem;
          }
        }
      }
      &._08 {
        h2 {
          font-size: 4.8rem;
        }
        .lead {
          line-height: 1.5;
        }
        .text {
          margin-top: 20px;
          font-size: 1.3rem;
        }
      }
    }
    .cmn-text {
      margin-top: 20px;
    }
    .link-more {
      margin-top: 40px;
    }
    #sec06 {
      img {
        &.img-jp {
          display: none;
        }
        &.img-en {
          display: inherit;
        }
      }
    }
  }
}
.fa-youtube:before {
  content: "\f167";
}