.page-strengths {
  .sec-vision {
    color: $white;
    background: $main-color;
    padding: 150px 0;
    position: relative;
    overflow: hidden;
    @include mq {
      padding: 100px 0 85px;
    }
    &:before {
      content: '';
      display: block;
      width: 70vw;
      height: 70vw;
      border-radius: 50%;
      border: 180px solid #246fab;
      position: absolute;
      @include mq(pc) {
        bottom: -10vw;
        right: -20vw;
      }
      @include mq {
        border-width: 90px;
        top: -35vw;
        right: -70vw;
      }
    }
    .blc {
      @include mq(pc) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .box {
      @include mq(pc) {
        width: calc(50% - 60px);
      }
    }
    .catch {
      font-size: 1.8rem;
      font-weight: 400;
      @include ls(80);
      @include lh(20, 38);
      margin-top: 35px;
      @include mq {
        font-weight: 300;
        font-size: 2.5rem;
        margin: 40px 0 0;
      }
    }
    .text {
      @include lh(14, 30);
      @include ls(50);
      margin-top: 55px;
      font-size: 1.3rem;
      @include mq {
        margin-top: 30px;
        font-weight: 300;
        font-size: 1.5rem;
      }
    }
    .img-wrap {
      @include mq(pc) {
        width: 50%;
      }
      @include mq {
        margin-top: 50px;
      }
      img {
        max-width: 398px;
        margin: 0 auto;
      }
    }
  }
  .sec-strengths {
    padding: 115px 0 140px;
    @include mq {
      padding: 90px 0;
    }
    h2 {
      @include mq(pc) {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
      }
      img {
        @include mq(pc) {
          width: auto;
        }
        @include mq {
          width: 100%;
          max-width: 480px;
        }
      }
      >span {
        font-size: 1.8rem;
        font-weight: 500;
        @include ls(50);
        @include lh(20, 38);
        @include mq(pc) {
          margin-left: 30px;
        }
        @include mq {
          margin-top: 25px;
          display: block;
        }
      }
    }
    .list {
      margin-top: 110px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      counter-reset: number 0;
      @include mq {
        margin-top: 50px;
        padding: 0 30px;
      }
      li {
        border-bottom: 1px solid #ccc;
        position: relative;
        @include mq(pc) {
          padding-bottom: 55px;
          width: 47%;
          display: flex;
          flex-wrap: wrap;
        }
        @include mq {
          padding-bottom: 35px;
          width: 100%;
        }
        +li {
          @include mq {
            margin-top: 35px;
          }
        }
        &:nth-child(n+3) {
          @include mq(pc) {
            margin-top: 50px;
          }
        }
        &:before {
          color: #aaa;
          font-family: $font-en;
          counter-increment: number 1;
          content: "0"counter(number);
          position: absolute;
          top: 0;
          left: 0;
        }
        .icon-wrap {
          @include mq(pc) {
            padding-top: 40px;
            max-width: 140px;
            width: 30%;
          }
          @include mq {
            padding-top: 15px;
          }
          img {
            @include mq(pc) {
              width: auto;
              max-width: 100%;
            }
            @include mq {
              max-width: 120px;
              margin: 0 auto;
              display: block;
            }
          }
        }
        dl {
          @include mq(pc) {
            margin-left: 38px;
            width: calc(70% - 38px);
          }
          @include mq {
            margin-top: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            flex-wrap: wrap;
          }
          dt {
            @include mq {
              text-align: center;
            }
            >span {
              display: block;
            }
            .en {
              color: $main-color;
              font-family: $font-en;
              font-size: 3rem;
              font-style: italic;
              font-weight: 300;
              @include ls(25);
              @include mq {
                font-size: 3.6rem;
              }
            }
            .jp {
              font-size: 1.6rem;
              font-weight: 500;
              @include lh(17, 27);
              @include ls(50);
              margin-top: 9px;
              @include mq {
                font-size: 1.8rem;
                margin-top: 12px;
              }
            }
          }
          dd {
            font-size: 1.3rem;
            @include lh(13, 27);
            @include ls(25);
            margin-top: 27px;
          }
        }
      }
    }
  }
  .sec-technical {
    background: #f5f5f9;
    padding: 135px 0 138px;
    position: relative;
    overflow: hidden;
    @include mq {
      padding: 95px 0;
    }
    &:before {
      content: '';
      display: block;
      width: 60vw;
      height: 60vw;
      border-radius: 50%;
      border: 180px solid #f1f1f5;
      position: absolute;
      top: -35vw;
      right: -25vw;
      @include mq {
        border-width: 90px;
      }
    }
    &:after {
      content: '';
      display: block;
      width: 60vw;
      height: 60vw;
      border-radius: 50%;
      border: 180px solid #f1f1f5;
      position: absolute;
      left: -55vw;
      bottom: -55vw;
      @include mq {
        border-width: 90px;
      }
    }
    .list {
      margin-top: 40px;
      /* -40px */
      counter-reset: number 0;
      @include mq(pc) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
      }
      li {
        border-bottom: 1px solid #ccc;
        padding-bottom: 35px;
        @include mq(pc) {
          width: 28.5%;
          &:nth-of-type(3n+1) {
            margin-top: 40px;
          }
          &:nth-of-type(3n+2) {
            margin-top: 80px;
          }
          &:nth-of-type(3n) {
            margin-top: 120px;
          }
        }
        @include mq {
          width: 100%;
          +li {
            margin-top: 35px;
          }
        }
        &:before {
          color: $main-color;
          font-family: $font-en;
          font-size: 2rem;
          counter-increment: number 1;
          content: "0"counter(number);
          @include mq {
            font-size: 2rem;
          }
        }
        dl {
          margin-top: 25px;
          @include mq {
            margin-top: 15px;
          }
          dt {
            >span {
              display: block;
            }
            .en {
              color: $main-color;
              font-family: $font-en;
              font-size: 3rem;
              font-style: italic;
              font-weight: 300;
              @include ls(25);
            }
            .jp {
              font-size: 1.7rem;
              font-weight: 500;
              @include lh(17, 27);
              @include ls(50);
              margin-top: 9px;
              @include mq {
                font-size: 2.2rem;
              }
            }
          }
          dd {
            font-size: 1.3rem;
            @include lh(13, 27);
            @include ls(25);
            margin-top: 27px;
            @include mq {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
  .sec-shikaku {
    padding: 130px 0 140px;
    @include mq {
      padding: 85px 0 100px;
    }
    .list {
      margin-top: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      @include mq(pc) {
        margin-bottom: -20px;
      }
      @include mq {
        margin-top: 40px;
      }
      li {
        font-weight: 500;
        @include ls(50);
        border: 1px solid #ddd;
        line-height: 18px;
        @include mq(pc) {
          margin-bottom: 20px;
        }
        @include mq {
          text-align: center;
          width: 100%;
          padding: 15px;
        }
        &:not(:last-child) {
          @include mq(pc) {
            margin-right: 20px;
          }
          @include mq {
            margin-bottom: 10px;
          }
        }
        p {
          padding: 22px;
        }
        a {
          display: block;
          height: 100%;
          width: 100%;
        }
        img {
          width: auto;
          height: 18px;
          margin-right: 10px;
        }
      }
    }
  }
  .sec-world {
    color: $white;
    background: url(/img/strengths/bg_world.jpg) no-repeat right center;
    background-size: cover;
    padding: 140px 0;
    min-height: 100vh;
    @include mq {
      background-position: center center;
      padding: 100px 0;
    }
    .ttl02 {
      .jp {
        margin-top: 30px;
        @include mq {
          white-space: nowrap;
          line-height: 1.6;
          font-size: 2.5rem;
          margin: 40px 0 0;
        }
      }
    }
    .text {
      font-weight: 400;
      line-height: 2.5;
      margin-top: 70px;
      @include lh(15, 30);
      margin-top: 70px;
      @include mq(pc) {
        @include ls(75);
      }
      @include mq {
        font-size: 1.4rem;
        margin-top: 30px;
      }
    }
  }
  .en_lang {
    .sec-world {
      .ttl02 {
        .jp {
          line-height: 1.8;
        }
      }
      .text {
        width: 50%;
        @include mq {
          width: 100%;
        }
      }
    }
  }
}
