.btn-spmenu {
  $this: &;
  @include mq(pc) {
    display: none;
  }
  width: $sp-header-height;
  height: $sp-header-height;
  background: $main-color;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &__bar-wrap {
    width: 25px;
    height: 21px;
    position: relative;
    #{$this}.active & {
      width: 27px;
      height: 21px;
    }
  }
  &__bar {
    width: 25px;
    height: 3px;
    border-radius: 1.5px;
    background: $white;
    position: absolute;
    left: 0;
    @include transition;
    &:nth-child(1) {
      top: 0;
      #{$this}.active & {
        width: 28px;
        top: 9px;
        transform: rotate(45deg);
      }
    }
    &:nth-child(2) {
      top: 9px;
      #{$this}.active & {
        transform: scale(0);
      }
    }
    &:nth-child(3) {
      bottom: 0;
      #{$this}.active & {
        width: 28px;
        top: 9px;
        transform: rotate(-45deg);
      }
    }
  }
}
