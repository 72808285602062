@import "../mixin/mixin";
html,
body {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
}

html {
    font-size: 10px;
    @include mq(spc) {
        font-size: 9px;
    }
    @include mq(lsp) {
        font-size: 12.5px;
    }
    @include mq(msp) {
        font-size: 8px;
    }
    @media screen and (max-height: 700px) {
        font-size: 7px;
    }
}

body {
    font-family: $font-sans_serif;
    font-weight: 400;
    color: $text-color;
    @include ls(50);
}

img {
    width: 100%;
}

.fa-youtube {
    font-family: $font-awesome4 !important;
    font-weight: 100 !important;
}


/*  link
-----------------------------------------------------------------------------*/

a {
    transition: .3s ease;
}

a,
a:visited,
a:hover {
    color: $text-color;
    text-decoration: none;
}

a img {
    border: 0;
}

.clearfix:after {
    height: 0;
    display: block;
    clear: both;
    content: " ";
}

@include mq(pc) {
    .sp {
        display: none !important;
    }
}

@include mq {
    .pc {
        display: none !important;
    }
}


/*  reset
-----------------------------------------------------------------------------*/


/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
input,
button,
textarea,
select {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    background: transparent;
    box-sizing: border-box;
}

body {
    line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ul,
ol {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

a {
    vertical-align: baseline;
    background: transparent;
}


/* change colours to suit your needs */

ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}


/* change colours to suit your needs */

mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

del {
    text-decoration: line-through;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}


/* change border colour to suit your needs */

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
}

input,
select {
    vertical-align: middle;
}

// フォーム部品の完全リセット用
// input {
//   -webkit-appearance: none;
// }
// select {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
// }
// select::-ms-expand {
//   display: none;
// }
input,
button,
select,
textarea {
    font-family: $font-sans_serif;
}

button,
input[type="submit"] {
    cursor: pointer;
    transition: .3s ease;
}

input::placeholder,
textarea::placeholder {
    color: #D6DADF;
}


/* IE */

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #D6DADF;
}


/* Edge */

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
    color: #D6DADF;
}

img {
    vertical-align: top;
}

main {
    // IE11
    display: block;
}