.roder_page {
    font-family: 'Zen Kaku Gothic New', sans-serif;
    .sec {
        &-header {
            position: fixed;
            @include transition;
            top: 0;
            z-index: 9999;
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 123px 120px;
            grid-template-rows: 1fr;
            grid-auto-columns: 1fr;
            gap: 0px 26px;
            grid-auto-flow: row;
            grid-template-areas: "top_logo roder contact";
            width: 100%;
            height: 100px;
            padding: 0 40px;
            @include mq() {
                grid-template-columns: none;
                gap: 0px;
                display: flex;
                justify-content: space-between;
                height: auto;
                padding: 15px;
            }
            .roder {
                grid-area: roder;
                @include mq() {
                    width: 80px;
                }
            }
            .contact {
                grid-area: contact;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #fff;
                border: 2px solid #fff;
                width: 120px;
                height: 53px;
                gap: 5px 0;
                font-size: 1.4rem;
                @include mq() {
                    width: 45px;
                    height: 45px;
                    gap: 0;
                    font-size: 0.9rem;
                    border-radius: 50%;
                    padding-top: 7px;
                    text-indent: -9999px;
                }
                img {
                    width: 20px;
                    height: 14px;
                    @include mq() {
                        content: url(./../../img/bin/icon_contact_wh.png);
                        width: 19px;
                        height: 15px;
                    }
                }
                &:hover {
                    color: #fff;
                    background: #e3000f;
                    border: 2px solid #e3000f;
                }
            }
            .top_logo {
                grid-area: top_logo;
                img {
                    width: 290px;
                    height: auto;
                    @include mq() {
                        width: 190px;
                    }
                }
            }
        }
        &-main {
            display: flex;
            justify-content: flex-start;
            position: relative;
            @include mq(lsp) {
                height: 768px;
                @include mq(msp) {
                    height: 100vh;
                }
            }
            &:after {
                width: 100%;
                @include mq(lsp) {
                    height: 768px;
                    @include mq(msp) {
                        height: 100vh;
                    }
                }
            }
            .header-logo {
                @include mq() {
                    width: 32vw;
                    max-width: 120px;
                    margin-bottom: 20px;
                }
            }
            h1 {
                text-align: left;
                &.lead {
                    position: absolute;
                    top: 50%;
                    left: 84px;
                    transform: translateY(-50%);
                    @include mq() {
                        top: 50%;
                        left: 15px;
                        transform: translate(0, -50%);
                        width: calc(100% - 30px);
                    }
                    .eng_lead {
                        font-family: 'Ubuntu', sans-serif;
                        font-size: 9.2rem;
                        font-weight: 500;
                        line-height: 10.12rem;
                        letter-spacing: 0.05em;
                        margin-bottom: 26px;
                        position: relative;
                        @include mq() {
                            font-size: 50px;
                            line-height: 55px;
                            letter-spacing: 0;
                            margin-bottom: 12px;
                        }
                    }
                    .jp_lead {
                        display: flex;
                        flex-direction: column;
                        align-content: flex-start;
                        font-size: 4.2rem;
                        font-weight: 600;
                        line-height: 1.5;
                        letter-spacing: 0;
                        position: relative;
                        width: 340px;
                        @include mq() {
                            font-size: 30px;
                            line-height: 45px;
                            @include mq(msp) {
                                width: 250px;
                            }
                        }
                        span {
                            display: inline;
                        }
                    }
                    @keyframes noise-anim {
                        $steps: 20;
                        @for $i from 0 through $steps {
                            #{percentage($i*(1/$steps))} {
                                clip: rect(random(100) + px, 9999px, random(100) + px, 0);
                            }
                        }
                    }
                    @keyframes noise-anim-2 {
                        $steps: 20;
                        @for $i from 0 through $steps {
                            #{percentage($i*(1/$steps))} {
                                clip: rect(random(100) + px, 9999px, random(100) + px, 0);
                            }
                        }
                    }
                    .glitch {
                        color: $white;
                        display: inline-block;
                        position: relative;
                        &::after {
                            content: attr(data-text);
                            position: absolute;
                            left: 2px;
                            text-shadow: -1px 0 red;
                            top: 0;
                            color: white;
                            background: transparent;
                            overflow: hidden;
                            clip: rect(0, 900px, 0, 0);
                            animation: noise-anim 1s 2s linear alternate-reverse;
                        }
                        &::before {
                            content: attr(data-text);
                            position: absolute;
                            left: -2px;
                            text-shadow: 1px 0 red;
                            top: 0;
                            color: white;
                            background: transparent;
                            overflow: hidden;
                            clip: rect(0, 900px, 0, 0);
                            animation: noise-anim-2 1s 2s linear alternate-reverse;
                        }
                    }
                }
            }
            .background {
                width: 100%;
            }
            .block-revealer__element {
                position: absolute;
                top: 15%;
                left: 0;
                width: 100%;
                height: 80%;
                background: #000;
                pointer-events: none;
                opacity: 0;
            }
            .slick-slide {
                @include mq() {
                    background-image: url(./../../img/bin/slide-01.jpg);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    height: 100vh;
                }
                &:nth-child(2) {
                    @include mq() {
                        background-image: url(./../../img/bin/slide-02.jpg);
                    }
                }
                &:nth-child(3) {
                    @include mq() {
                        background-image: url(./../../img/bin/slide-03.jpg);
                    }
                }
                &:nth-child(4) {
                    @include mq() {
                        background-image: url(./../../img/bin/slide-04.jpg);
                    }
                }
                &:nth-child(5) {
                    @include mq() {
                        background-image: url(./../../img/bin/slide-05.jpg);
                    }
                }
                img {
                    width: auto;
                    height: auto;
                    min-width: 100vw;
                    min-height: 100vh;
                    @include mq() {
                        width: 100%;
                        height: 100%;
                        display: none;
                    }
                    @include mq(lsp) {
                        height: 768px;
                        @include mq(msp) {
                            height: 100vh;
                        }
                    }
                }
            }
            .slick-list {
                height: 100vh;
                @include mq(lsp) {
                    height: 768px;
                    @include mq(msp) {
                        height: 100vh;
                    }
                }
            }
            .slick-dots {
                z-index: 9999;
                list-style: none;
                position: absolute;
                display: flex;
                justify-content: space-around;
                left: 50%;
                bottom: 40px;
                height: 8px;
                width: 140px;
                transform: translate(-50%, 0);
                li {
                    margin: 0 !important;
                    position: relative;
                    &.slick-active button:before {
                        background-color: #e3000f;
                    }
                    button {
                        text-indent: -9999px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 8px !important;
                        height: 8px !important;
                        width: auto !important;
                        height: auto !important;
                        &:before {
                            position: absolute;
                            left: 0;
                            top: 0;
                            content: "" !important;
                            background-color: #fff;
                            width: 8px !important;
                            height: 8px !important;
                            border-radius: 4px;
                        }
                    }
                }
            }
            @keyframes shake {
                0% {
                    transform: skewX(-15deg);
                }
                5% {
                    transform: skewX(15deg);
                }
                10% {
                    transform: skewX(-15deg);
                }
                15% {
                    transform: skewX(15deg);
                }
                20% {
                    transform: skewX(0deg);
                }
                100% {
                    transform: skewX(0deg);
                }
            }
        }
        &-about {
            .inner {
                padding: calc(100vw - 95%);
                max-width: unset;
                .content {
                    position: relative;
                    display: grid;
                    background: no-repeat center url(./../../img/bin/about_01.jpg);
                    background-size: cover;
                    grid-template-columns: 100px 700px 1fr;
                    grid-template-rows: 1fr 128px 2fr 100px;
                    grid-auto-columns: 1fr;
                    gap: 20px 0px;
                    grid-auto-flow: row;
                    grid-template-areas: ". . ." ". title ." ". desc ." ". . .";
                    width: 100%;
                    height: calc(min(100vh, 45vw));
                    overflow: hidden;
                    @include mq() {
                        grid-template-columns: 10px auto 1fr;
                        grid-template-rows: 0.5fr 20px 2fr 100px;
                        display: block;
                        height: auto;
                        padding: 20.667vw 3% 16vw;
                        @include mq(msp) {
                            background: url(./../../img/bin/sp_about01.jpg) no-repeat top left;
                            background-size: 100% 100%;
                        }
                    }
                    .title {
                        color: #e3000f;
                        grid-area: title;
                        font-size: 4.2rem;
                        font-weight: 700;
                        line-height: 1.67em;
                        text-shadow: 0px 0px 16px rgba(239, 239, 241, 0.78);
                        position: relative;
                        z-index: 99;
                        @include mq() {
                            font-size: 26px;
                            line-height: 42px;
                        }
                    }
                    .desc {
                        color: #14130e;
                        grid-area: desc;
                        font-size: 1.8rem;
                        font-weight: 700;
                        line-height: 1.8;
                        text-shadow: #f6f5f8 0px 0px 8px;
                        position: relative;
                        z-index: 99;
                        @include mq() {
                            font-size: 14px;
                            line-height: 28px;
                            word-break: break-all;
                        }
                    }
                    .bg_scroll {
                        overflow: hidden;
                        top: 50%;
                        position: absolute;
                        display: flex;
                        animation: hscroll 5s linear infinite;
                        height: calc(min(15vh, 10vw));
                        transform: translateY(-50%);
                        @include mq(msp) {
                            height: calc(min(15vh, 25.600vw));
                        }
                        img {
                            width: auto;
                            height: calc(min(15vh, 10vw));
                            padding: 0 5px;
                            @include mq(msp) {
                                height: calc(min(15vh, 25.600vw));
                            }
                            &:first-child {
                                animation: loop 50s -25s linear infinite;
                            }
                            &:last-child {
                                animation: loop2 50s linear infinite;
                            }
                        }
                    }
                    @keyframes loop {
                        0% {
                            transform: translateX(100%);
                        }
                        to {
                            transform: translateX(-100%);
                        }
                    }
                    @keyframes loop2 {
                        0% {
                            transform: translateX(0);
                        }
                        to {
                            transform: translateX(-200%);
                        }
                    }
                }
            }
        }
        &-case {
            .inner {
                padding: 0;
                max-width: unset;
            }
            .case {
                &._01 {
                    .drawer:after {
                        background: url(./../../img/bin/background_01.jpg) no-repeat center center;
                        background-size: cover;
                    }
                }
                &._02 {
                    .drawer:after {
                        background: url(./../../img/bin/background_02.jpg) no-repeat center center;
                        background-size: cover;
                    }
                }
                &._03 {
                    .drawer:after {
                        background: url(./../../img/bin/background_03.jpg) no-repeat center center;
                        background-size: cover;
                    }
                }
            }
            .drawer {
                font-family: "Ubuntu";
                padding: 115px 66px 40px 38px;
                color: #fff;
                width: 100%;
                height: 100%;
                display: grid;
                grid-auto-columns: 1fr;
                grid-template-rows: 112px 61px 45px 36px;
                grid-template-columns: 1fr;
                grid-template-areas: "title " "desc " "more ";
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                text-align: center;
                background-color: #000;
                @include mq() {
                    padding: 15px 20px 16px 20px;
                    grid-template-rows: 60px 30px 45px auto;
                }
                &:after {
                    pointer-events: none;
                    position: absolute;
                    z-index: 0;
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    background-size: cover;
                    transition: all 0.3s ease-out;
                }
                &:hover:after {
                    transform: scale(1.05);
                }
                &:before {
                    content: "";
                    z-index: 10;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #0002;
                }
                .lead {
                    width: 310px;
                    height: auto;
                    z-index: 10;
                    @include mq() {
                        width: 180px;
                        margin: auto 0;
                    }
                }
                .desc {
                    font-family: "Zen Kaku Gothic New";
                    display: flex;
                    gap: 0 15px;
                    align-items: center;
                    z-index: 10;
                    @include mq() {
                        gap: 0 10px;
                    }
                    p {
                        grid-area: desc;
                        font-size: 4.2rem;
                        font-weight: 500;
                        @include mq() {
                            font-size: 21px;
                        }
                    }
                    span {
                        display: block;
                        background-color: #fff;
                        width: 30px;
                        height: 2px;
                        @include mq() {
                            width: 15px;
                        }
                    }
                }
                .view-more {
                    display: flex;
                    gap: 0 15px;
                    align-items: center;
                    justify-content: flex-end;
                    z-index: 10;
                    @include mq() {}
                    p {
                        text-align: right;
                        grid-area: more;
                        font-size: 3.2rem;
                        @include mq() {
                            font-size: 20px;
                        }
                    }
                    .view-state {
                        position: relative;
                        @include mq() {
                            margin-right: 20px;
                        }
                    }
                    span {
                        position: absolute;
                        display: block;
                        background-color: #fff;
                        width: 30px;
                        height: 2px;
                        transition-duration: 200ms;
                        @include mq() {
                            width: 20px;
                        }
                        &::after {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translate(0, -5px);
                            content: "";
                            width: 11px;
                            height: 2px;
                            background-color: #fff;
                            @include mq() {
                                width: 7px;
                                height: 2px;
                            }
                        }
                        &:nth-last-child(1) {
                            transform: rotateZ(-90deg) rotateX(180deg);
                        }
                    }
                }
                &.close {
                    .view-more {
                        span:nth-last-child(1) {
                            transform: rotateZ(0deg) rotateX(0deg);
                            transition-duration: 200ms;
                        }
                    }
                }
            }
            .content {
                height: 400px;
                @include mq(msp) {
                    height: 270px;
                }
                &-viewer {
                    background-color: #1d1d1b;
                    .viewbox {
                        width: 33.6%;
                        background-color: #fff;
                        position: relative;
                        width: 100%;
                    }
                }
                .slick-initialized .slick-slide {
                    display: flex;
                    justify-content: center;
                    a {
                        display: block;
                        margin: 0 10px;
                        position: relative;
                        &:hover .viewbox_cover {
                            opacity: 0.92;
                            transition-duration: 200ms;
                        }
                    }
                }
                .viewbox_cover {
                    color: #fff;
                    background-color: #d70210;
                    font-family: "Zen Kaku Gothic New";
                    font-size: 2.4rem;
                    font-weight: bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 10px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    text-align: center;
                    z-index: 10;
                }
                .slick-arrow:before {
                    content: "" !important;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 999;
                    @include mq() {
                        height: 60px;
                    }
                }
                .slick {
                    position: relative;
                    display: grid;
                    grid-template-columns: 100px 1fr 100px;
                    grid-template-rows: 80px 1fr 80px;
                    grid-auto-columns: 1fr;
                    gap: 0px 0px;
                    grid-auto-flow: row;
                    grid-template-areas: ". . ." "prev disp next" ". . .";
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    @include mq() {
                        grid-template-columns: 70px 1fr 70px;
                        grid-template-rows: 0px 1fr 0px;
                    }
                    &-slide {
                        position: relative;
                    }
                    &-list {
                        grid-area: disp;
                    }
                    &-next {
                        grid-area: next;
                        position: relative;
                        &:before {
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 30px;
                            height: 95px;
                            background: url(./../../img/bin/arrow_R.png) no-repeat !important;
                            background-size: contain !important;
                        }
                    }
                    &-prev {
                        grid-area: prev;
                        position: relative;
                        &:before {
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 30px;
                            height: 95px;
                            background: url(./../../img/bin/arrow_L.png) no-repeat !important;
                            background-size: contain !important;
                        }
                    }
                }
            }
            .title {
                position: relative;
            }
            .box {
                display: none;
            }
        }
        &-verify {
            .inner {
                max-width: unset;
                width: 100%;
                height: 100%;
                background-color: #1d1d1b;
                display: flex;
                flex-direction: column;
                padding: 120px 0;
                align-items: center;
                @include mq() {
                    padding: 60px 20px;
                    height: 100vh;
                    justify-content: center;
                }
                .verify-logo {
                    width: 256px;
                    height: auto;
                    @include mq() {
                        width: 160px;
                    }
                }
                .verify-area {
                    padding: 40px 0 0 40px;
                    display: grid;
                    grid-template-columns: 80px 630px;
                    grid-template-rows: 132px 50px;
                    grid-auto-columns: 1fr;
                    gap: 16px 0px;
                    grid-auto-flow: row;
                    grid-template-areas: "logo caps" ". link";
                    width: auto;
                    height: 100%;
                    @include mq() {
                        padding: 0;
                        grid-template-columns: auto;
                        grid-template-rows: auto auto auto;
                        grid-auto-columns: 1fr;
                        justify-items: center;
                        gap: 0px 0px;
                        grid-auto-flow: row;
                        grid-template-areas: "logo" "caps" "link";
                        width: calc(100% - 40px);
                        display: flex;
                        flex-direction: column;
                        height: auto;
                    }
                    &-logo {
                        grid-area: logo;
                        width: 53px;
                        height: 102px;
                    }
                    &-caption {
                        grid-area: caps;
                        color: #ffffff;
                        @include mq() {
                            margin: 50px 0 0 0;
                        }
                        dt {
                            font-size: 3.2rem;
                            font-weight: bold;
                            padding-bottom: 16px;
                            @include mq() {
                                font-size: 24px;
                                line-height: 32px;
                                padding-bottom: 20px;
                                text-align: left;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img {
                                    width: 34px;
                                    margin-right: 15px;
                                }
                                div {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                }
                            }
                        }
                        dd {
                            font-size: 1.6rem;
                            letter-spacing: 0.05em;
                            line-height: 1.8;
                            @include mq() {
                                font-size: 16px;
                                line-height: 28px;
                                text-align: left;
                            }
                        }
                    }
                    &-link {
                        grid-area: link;
                        display: flex;
                        align-items: center;
                        color: #ffffff !important;
                        @include mq() {
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                        }
                        a {
                            color: #ffffff !important;
                            font-size: 3rem;
                            margin-right: 20px;
                            @include mq() {
                                font-size: 30px;
                                margin-right: 25px;
                            }
                            .fa-youtube {
                                font-family: "Font Awesome 5 Brands" !important;
                            }
                        }
                        >.border {
                            font-family: "Zen Kaku Gothic New";
                            border: 1px solid #ffffff;
                            color: #ffffff;
                            border-radius: 2px;
                            height: 40px;
                            width: 300px;
                            margin-right: 40px;
                            font-size: 1.6rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            @include mq() {
                                font-size: 14px;
                                flex-basis: 100%;
                                margin: 20px 20px 20px 0;
                                width: 100%;
                                max-width: 300px;
                                @include mq(msp) {
                                    margin: 30px auto 20px;
                                }
                            }
                            &:hover {
                                background-color: #e3000f;
                                border: none;
                            }
                            &:after {
                                right: 10px;
                                position: absolute;
                                width: 16px;
                                height: 16px;
                                content: "";
                                background: url(./../../img/bin/tar_blank.png) no-repeat;
                                background-size: contain;
                                @include mq() {
                                    background: url(./../../img/bin/sp_tar_blank.png) no-repeat;
                                    background-size: contain;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-contact {
            .inner {
                max-width: unset;
                padding: 70px 70px 0;
                width: 100%;
                overflow: hidden;
                @include mq() {
                    padding: 20px 20px 0;
                    overflow: unset;
                }
                .content {
                    position: relative;
                    height: 448px;
                    margin-bottom: 30px;
                    width: 100%;
                    background: no-repeat center url(./../../img/bin/contact_01.jpg);
                    background-size: cover;
                    gap: 0 10.981vw;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    color: #ffffff;
                    @include mq() {
                        height: auto;
                        gap: 0 0;
                        flex-direction: column;
                        padding: 50px 20px;
                    }
                    >* {
                        z-index: 10;
                    }
                    .title {
                        dt {
                            width: 292px;
                            height: auto;
                            font-family: "Ubuntu";
                            font-weight: 500;
                            font-size: 6rem;
                            padding-bottom: 24px;
                            @include mq() {
                                width: 200px;
                                margin: 0 auto 30px;
                                padding-bottom: 0;
                            }
                        }
                        dd {
                            font-size: 1.8rem;
                            font-weight: 500;
                            font-family: 'Zen Kaku Gothic New', sans-serif;
                            line-height: 1.8;
                            letter-spacing: 0.05rem;
                            @include mq() {
                                text-align: center;
                                font-size: 16px;
                            }
                            img {
                                @include mq() {
                                    height: 16px;
                                }
                            }
                        }
                    }
                    .number {
                        font-family: "Zen Kaku Gothic New";
                        @include mq() {
                            width: 100%;
                        }
                        dl {
                            padding-bottom: 37px;
                            text-align: center;
                            @include mq() {
                                padding: 24px 0 16px;
                            }
                            dt {
                                color: $white;
                                font-family: "Ubuntu";
                                font-size: 3.4rem;
                                margin-bottom: 12px;
                                letter-spacing: 0.075em;
                                @include mq() {
                                    font-size: 34px;
                                    margin-bottom: 6px;
                                }
                                a {
                                    color: $white;
                                    cursor: context-menu;
                                    @include mq() {
                                        cursor: pointer;
                                    }
                                }
                            }
                            dd {
                                font-size: 1.2rem;
                                letter-spacing: 0.05em;
                                @include mq() {
                                    font-size: 12px;
                                }
                            }
                        }
                        dt {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img {
                                vertical-align: baseline;
                                padding-right: 16px;
                                width: auto;
                                height: 24px;
                                @include mq() {
                                    padding-right: 12px;
                                }
                            }
                        }
                        a.btn {
                            font-family: 'Zen Kaku Gothic New', sans-serif;
                            font-weight: 500;
                            padding: 32px 0;
                            width: 380px;
                            margin: auto;
                            font-size: 2.2rem;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            align-items: flex-end;
                            border-radius: 4px;
                            background-color: #ffffff;
                            color: #14130e;
                            @include mq() {
                                padding: 18px 0;
                                max-width: 380px;
                                width: 100%;
                                font-size: 18px;
                            }
                            &:hover {
                                background-color: #e3000f;
                                color: #fff;
                                span {
                                    background: url(./../../img/bin/contact_mail_icon_wh.png) no-repeat;
                                    background-size: contain;
                                    padding-right: 16px;
                                    width: 24px;
                                    height: 18px;
                                    transition-duration: 200ms;
                                    @include mq() {
                                        background: url(./../../img/bin/sp_contact_mail_icon_wh.png) no-repeat;
                                        background-size: contain;
                                        padding-right: 10px;
                                        width: 18px;
                                        height: 14px;
                                    }
                                }
                            }
                            span {
                                background: url(./../../img/bin/contact_mail_icon_bk.png) no-repeat;
                                background-size: contain;
                                padding-right: 16px;
                                width: 24px;
                                height: 18px;
                                transition-duration: 200ms;
                                @include mq() {
                                    background: url(./../../img/bin/sp_contact_mail_icon_bk.png) no-repeat;
                                    background-size: contain;
                                    padding-right: 10px;
                                    width: 18px;
                                    height: 14px;
                                }
                            }
                        }
                    }
                }
                .btn {
                    span {
                        margin-right: 13px;
                    }
                    .border {
                        border: 1px solid #000;
                        border-radius: 2px;
                        color: #000;
                        font-size: 1.4rem;
                        font-weight: 500;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 auto;
                        height: 40px;
                        width: 300px;
                        position: relative;
                        @include mq() {
                            font-size: 14px;
                            max-width: 300px;
                            width: 100%;
                        }
                        &:after {
                            right: 10px;
                            position: absolute;
                            width: 16px;
                            height: 16px;
                            content: "";
                            background: url(./../../img/bin/tar_blank_bk.png) no-repeat;
                            background-size: contain;
                            @include mq() {
                                background: url(./../../img/bin/sp_tar_blank_bk.png) no-repeat;
                                background-size: contain;
                                width: 14px;
                                height: 14px;
                            }
                        }
                        &:hover {
                            background-color: #e3000f;
                            border: 1px solid #e3000f;
                            color: #fff;
                            &:after {
                                background: url(./../../img/bin/tar_blank.png) no-repeat;
                                @include mq() {
                                    background: url(./../../img/bin/sp_tar_blank.png) no-repeat;
                                    background-size: contain;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-footer {
            color: #1d1d1b;
            font-family: "Ubuntu";
            height: 66px;
            font-size: 1.1rem;
            text-align: center;
            display: flex;
            align-items: center;
            font-weight: 500;
            justify-content: center;
            @include mq() {
                font-size: 11px;
                font-weight: 400;
                letter-spacing: 0.025em;
            }
            #pagetop {
                display: block;
                position: absolute;
                right: 70px;
                bottom: 60px;
                z-index: 11;
                @include mq() {
                    right: 20px;
                    bottom: 20px;
                }
                img {
                    width: auto;
                    @include mq() {
                        width: 40px;
                    }
                }
            }
        }
    }
    .ptn3 {
        .top_logo {
            img {
                content: url(./../../img/common/logo.png);
                opacity: 1;
            }
        }
        .contact {
            border-color: $black01;
            color: $black01;
            img {
                content: url(./../../img/bin/icon_contact_bk.png);
                opacity: 1;
                @include mq() {
                    content: url(./../../img/bin/sp_contact_mail_icon_bk.png);
                }
            }
            &:hover {
                img {
                    content: url(./../../img/bin/icon_contact_wh.png);
                    @include mq() {
                        content: url(./../../img/bin/sp_contact_mail_icon_wh.png);
                    }
                }
            }
        }
        .roder {
            img {
                content: url(./../../img/bin/roder-logo-invert-bk.png);
                opacity: 1;
            }
        }
    }
}