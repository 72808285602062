.case {
  .label {
    background: #2167a0;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    color: $white;
    display: inline-block !important;
    font-size: 12px;
    line-height: 17px;
    padding: 3px 10px;
    @include mq {
      padding: 3px 8px;
      font-size: 1.2rem;
    }
    &.label02 {
      background: $white;
      border: 1px solid #2167a0;
      color: #2167a0;
    }
  }
  .date-txt {
    color: #a1a1a1;
    font-size: 1.3rem;
    line-height: 1.4;
  }
  .sec-01 {
    background: #f5f5f9;
    padding: 120px 0 140px;
    @include mq {
      padding: 60px 0 70px;
    }
    .btn-download {
      margin-bottom: 20px;
      text-align: center;
      a {
        background: #3ca0e6;
        color: $white;
        display: inline-block;
        padding: 20px 0;
        width: 450px;
        text-align: center;
        @include mq {
          padding: 10px;
          width: 100%;
        }
        svg {
          fill: #fff;
          margin-right: 10px;
          vertical-align: middle;
          height: 18px;
          width: 15px;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .note-txt {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
    }
    .blc {
      align-items: flex-start;
      margin-top: 80px;
      @include mq {
        margin-top: 40px;
      }
    }
    .box-filter {
      background: #2167a0;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      color: $white;
      padding: 35px 30px;
      width: 27%;
      @include mq {
        padding: 15px;
        width: 100%;
      }
      .filer-ttl {
        font-size: 20px;
        font-family: $font-en;
        line-height: 30px;
        padding-bottom: 30px;
        @include mq {
          padding-bottom: 20px;
        }
      }
      .filter-list {
        padding-bottom: 25px;
        @include mq {
          padding-bottom: 15px;
        }
        li {
          margin-bottom: 15px;
          @include mq {
            margin-bottom: 10px;
          }
          select {
            border: none;
            background: #246fab;
            border-bottom: 1px solid $white;
            color: $white;
            padding: 10px;
            height: 50px;
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            appearance: none;
            outline: 0;
            box-shadow: none;
            background-image: none;
            @include mq {
              height: 40px;
            }
          }
          .select {
            position: relative;
            &::after {
              content: '';
              border: solid #fff;
              border-width: 0 2px 2px 0;
              display: inline-block;
              padding: 3px;
              position: absolute;
              top: 50%;
              right: 20px;
              transform: translateY(-50%) rotate(45deg);
              cursor: pointer;
              pointer-events: none;
              -webkit-transition: .25s all ease;
              -o-transition: .25s all ease;
              transition: .25s all ease;
            }
          }
        }
      }
      .txt {
        border-bottom: 1px solid $white;
        display: inline-block;
        cursor: pointer;
      }
      .check-list {
        padding-top: 20px;
        @include mq {
          display: flex;
          flex-wrap: wrap;
        }
        li {
          margin-bottom: 10px;
          @include mq {
            width: 48%;
          }
        }
      }
      .btn-box {
        padding-top: 20px;
        label {
          border-radius: 5px;
          -webkit-border-radius: 5px;
          cursor: pointer;
          display: block;
          padding: 15px 0;
          margin-bottom: 15px;
          width: 100%;
          transition: ease 0.3s;
          text-align: center;
          input {
            display: none;
          }
          &:hover {
            opacity: 0.7;
          }
          &.search-btn {
            background: $white;
            color: #2167a0;
            i {
              margin-right: 10px;
            }
          }
          &.canel-btn {
            background: #2167a0;
            border: 1px solid $white;
          }
        }
      }
    }
    .case-content {
      width: 70%;
      @include mq {
        margin-top: 50px;
        width: 100%;
      }
      h2 {
        font-size: 3.2rem;
        font-weight: normal;
        line-height: 1.5;
        padding-bottom: 40px;
        @include mq {
          font-size: 24px;
          line-height: 30px;
          padding-bottom: 20px;
        }
      }
      .list {
        margin-top: -50px;
        @include mq {
          margin-top: -30px;
        }
        li {
          background: $white;
          margin-top: 50px;
          @include mq {
            margin-top: 30px;
          }
          a {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            &:hover {
              opacity: 0.7;
            }
          }
          span {
            display: block;
          }
          .img {
            background: #e4e4ea;
            padding-bottom: 27%;
            position: relative;
            overflow: hidden;
            width: 48%;
            @include mq {
              padding-bottom: 55%;
              width: 100%;
            }
            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
            }
            &.logo {
              img {
                width: inherit;
              }
            }
          }
          .box-txt {
            font-size: 1.6rem;
            padding-left: 40px;
            width: 52%;
            @include mq {
              font-size: 13px;
              padding: 15px;
              line-height: 18px;
              width: 100%;
            }
            .fl-item {
              justify-content: flex-start !important;
              margin-bottom: 10px;
              @include mq {
                margin-bottom: 5px;
              }
              .label {
                margin-right: 10px;
                @include mq {
                  margin-right: 5px;
                }
              }
            }
            .date-txt {
              margin-top: 15px;
              @include mq {
                margin-top: 10px;
                font-size: 1.2rem;
              }
            }
          }
        }
        &.list02 {
          display: flex;
          flex-wrap: wrap;
          padding: 50px 0 0;
          margin: -30px -1.5% 0;
          @include mq {
            margin: -20px 0 0;
          }
          li {
            margin: 30px 1.5% 0;
            width: 30.2%;
            @include mq {
              margin: 20px 0 0;
              width: 100%;
            }
            a {
              display: block;
            }
            .img {
              padding-bottom: 55%;
              width: 100%;
            }
            .box-txt {
              padding: 30px;
              width: 100%;
              @include mq {
                padding: 15px;
              }
            }
          }
        }
      }
    }
    .case-detail {
      h3 {
        font-size: 30px;
        font-weight: normal;
        line-height: 40px;
        padding-bottom: 50px;
        &.ttl-detail01 {
          padding: 20px 0 25px;
        }
        @include mq {
          font-size: 20px;
          line-height: 30px;
          padding-bottom: 30px;
          &.ttl-detail01 {
            padding: 10px 0 15px;
          }
        }
      }
      p.txt {
        padding-bottom: 50px;
        @include mq {
          padding-bottom: 25px;
        }
      }
      .slider-case {
        margin: 60px 0 0;
        position: relative;
        overflow: hidden;
        height: 750px;
        width: 100%;
        @include mq {
          height: auto;
          margin: 30px 0 0;
        }
        .item {
          width: 100%;
          max-width: 1400px;
          height: 750px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mq {
            padding: 0 5px;
            width: 350px;
            max-width: 100%;
            height: 200px;
          }
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            @include mq {}
          }
        }
      }
      .slide-num {
        margin: 30px 0 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        @include mq {
          margin: 20px 0 30px;
          justify-content: flex-end;
        }
        .num {
          color: #2167a0;
          font-family: $font-en;
          margin: 0 16px;
        }
        .num1 {
          padding-right: 10px;
          &:after {
            content: '/';
            padding-left: 10px;
            opacity: .6;
          }
        }
        .num2 {
          opacity: .6;
        }
      }
      .slide-arrow {
        width: 45px;
        height: 45px;
        border: 1px solid #2167a0;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s ease;
        @include mq {
          width: 40px;
          height: 40px;
        }
        &:before {
          content: '';
          width: 13px;
          height: 13px;
          background: url(/img/common/icon_arrow04.png) no-repeat;
          background-size: contain;
        }
        &:hover {
          background: $hover-white;
        }
        &._prev {
          left: 0;
          transform: rotate(180deg);
          &:hover {
            left: -3px;
          }
        }
        &._next {
          right: 0;
          &:hover {
            right: -3px;
          }
        }
      }
      .video-box {
        padding-bottom: 56%;
        position: relative;
        overflow: hidden;
        margin: 80px auto 0;
        width: 90%;
        @include mq {
          margin-top: 40px;
          width: 100%;
        }
        iframe {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .sec-02 {
    background: #2167a0;
    color: $white;
    padding: 130px 0 140px;
    position: relative;
    line-height: 30px;
    overflow: hidden;
    @include mq {
      line-height: 24px;
      padding: 60px 0 50px;
    }
    &::before {
      content: "";
      background: $white;
      height: 68px;
      position: absolute;
      left: 0;
      top: 130px;
      width: 18%;
      z-index: 1;
      @include mq {
        top: 60px;
        width: 50px;
        height: 50px;
      }
    }
    &::after {
      content: "";
      background: url(/img/case/detail/bg_01.png) no-repeat left top;
      background-size: 100% 100%;
      height: 973px;
      width: 763px;
      position: absolute;
      top: 0;
      right: 0;
      @include mq {
        height: 319px;
        width: 250px;
      }
    }
    h2 {
      background: #2167a0;
      font-size: 58px;
      font-family: $font-en;
      font-weight: 300;
      line-height: 68px;
      display: inline-block;
      margin-bottom: 30px;
      position: relative;
      z-index: 2;
      @include mq {
        background: none;
        font-size: 45px;
        line-height: 50px;
      }
      span {
        background: $white;
        color: #2167a0;
        display: inline-block;
        padding: 0 10px 0 0;
        margin-right: 10px;
        height: 68px;
        @include mq {
          height: 50px;
        }
      }
    }
    .txt01 {
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 50px;
      @include mq {
        padding-bottom: 25px;
      }
    }
    .case-list {
      margin-top: 60px;
      @include mq {
        margin-top: 30px;
      }
      li {
        width: 46%;
        @include mq {
          width: 100%;
          &:nth-child(n+2) {
            margin-top: 20px;
          }
        }
        span {
          display: block;
          padding-top: 30px;
          @include mq {
            padding-top: 15px;
          }
        }
      }
    }
  }
}
