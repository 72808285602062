@font-face {
    font-family: "ZenB";
    src: url("../fonts/ZenKakuGothicNew-Bold.ttf");
}

@font-face {
    font-family: "ZenM";
    src: url("../fonts/ZenKakuGothicNew-Medium.ttf");
}

* {
    box-sizing: border-box;
}

.font_T {
    font-family: "Titillium Web";
    font-weight: 500;
}

.tekmar_page {
    font-family: "ZenM";
    font-size: 14px;
    line-height: 150%;
    min-width: 1000px;
    @include mq() {
        min-width: 100%;
    }
    #wrapper {
        position: relative;
    }
    /* header */
    #header {
        position: fixed;
        top: 0;
        left: 0;
        padding: 30px 40px;
        width: 100%;
        z-index: 999;
        @include mq() {
            padding: 4%;
        }
        >div {
            @include mq() {
                align-items: center;
            }
        }
        .header_inner {
            align-items: center;
        }
        .headerR {
            align-items: center;
        }
        #logo {
            @include mq() {
                max-width: 217px;
            }
        }
        #btn_contact {
            background: url(./../../img/tekmar/icon_mail.png) no-repeat center 8px;
            border: 1px solid #fff;
            color: #fff;
            display: inline-block;
            font-size: 14px;
            line-height: 27px;
            margin-left: 25px;
            max-width: 120px;
            padding: 20px 12px 5px;
            position: relative;
            z-index: 9999;
            @include mq() {
                background: url(./../../img/tekmar/icon_mail_sp.png) no-repeat center 8px;
                background-position: center center;
                background-size: 19px 15px;
                border-radius: 50%;
                -moz-border-radius: 50%;
                -webkit-border-radius: 50%;
                line-height: 1;
                max-width: unset;
                padding: 0;
                text-indent: -9999px;
                width: 45px;
                height: 45px;
            }
            &:hover {
                background-color: #0292cd;
                border: 1px solid #0292cd;
            }
        }
    }
    #header.ptn3 {
        #logo {
            img {
                content: url(./../../img/tekmar/logo-bk.png);
                opacity: 1;
            }
        }
        #btn_contact {
            border-color: #1797d5;
            color: #1797d5;
            background: url(./../../img/tekmar/icon_mail_bl.png) no-repeat center 8px;
            opacity: 1;
            @include mq() {
                background: url(./../../img/bin/sp_contact_mail_icon_BL.png) no-repeat center 14px;
                background-size: 19px 15px;
            }
            &:hover {
                background: #1797d5 url(./../../img/tekmar/icon_mail.png) no-repeat center 8px;
                color: $white;
                @include mq() {
                    background: #1797d5 url(./../../img/bin/sp_contact_mail_icon_WH.png) no-repeat center 14px;
                    background-size: 19px 15px;
                }
            }
        }
        .tekmar {
            img {
                content: url(./../../img/tekmar/tekmar-energy-bl.png);
                opacity: 1;
            }
        }
    }
    /* footer */
    #footer {
        padding: 30px 0 10px;
        position: relative;
        @include mq() {
            padding: 40px 0 0;
        }
        .btn {
            background: #fff;
            color: #0292cd;
            display: inline-block;
            border-radius: 5px;
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            &:hover {
                background: #0292cd;
                color: #fff;
            }
        }
        .btn_site {
            border: 2px solid #fff;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 0.025em;
            margin: 40px auto 0;
            max-width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            height: 40px;
            position: relative;
            width: 300px;
            @include mq() {
                max-width: 300px;
                width: 100%;
            }
            &:hover::before {
                background: url(./../../img/tekmar/exlink_white.svg) no-repeat center 96%;
                background-size: 16px 16px;
                width: 16px;
                height: 16px;
            }
            &::before {
                content: "";
                background: url(./../../img/tekmar/exlink.svg) no-repeat center 96%;
                background-size: 16px 16px;
                position: absolute;
                top: 10px;
                right: 15px;
                width: 16px;
                height: 16px;
            }
        }
        #copyright {
            color: #fff;
            font-size: 11px;
            line-height: 60px;
        }
        #pagetop {
            position: absolute;
            bottom: 60px;
            right: 60px;
            z-index: 999;
            @include mq() {
                bottom: 100px;
                right: 3%;
            }
            img {
                @include mq() {
                    width: 30px;
                }
            }
            &:hover {
                opacity: 0.7;
            }
        }
    }
    /* contents */
    .inner {
        width: 1000px;
        margin: 0 auto;
        padding: 0;
        @include mq() {
            width: 92%;
            padding: 0;
        }
    }
    @media screen and (max-width: 1100px) {
        .inner {
            width: 92%;
        }
    }
    img {
        @include mq() {
            width: 100%;
            height: auto;
        }
    }
    .fl-item {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .align_r {
        text-align: right;
    }
    .align_c {
        text-align: center;
    }
    .align_l {
        text-align: left;
    }
    .bg_bl {
        background: #0096d4;
    }
    .bg_01 {
        background: #005b85;
    }
    .bg_02 {
        background: #00856b;
    }
    .bg_03 {
        background: #032d63;
    }
    .bg_04 {
        background: #1797d5;
    }
    .bg_05 {
        background: #00496b;
    }
    .bg_06 {
        background: #fafafa;
    }
    .cl_01 {
        color: #0292cd;
    }
    .cl_02 {
        color: #005b85;
    }
    .cl_03 {
        color: #00856b;
    }
    .cl_04 {
        color: #032d63;
    }
    .cl_05 {
        color: #1797d5;
    }
    .cl_06 {
        color: #00496b;
    }
    .txt14 {
        font-size: 14px;
    }
    .btn_bl {
        background: #0096d4;
        border: 1px solid #0096d4;
        color: #fff;
        display: inline-block;
        position: relative;
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        &::before {
            content: "";
            background: url(./../../img/tekmar/exlink_white.svg) no-repeat center 96%;
            background-size: 16px 16px;
            position: absolute;
            top: 11px;
            right: 15px;
            width: 16px;
            height: 16px;
            @include mq() {
                top: 16px;
            }
        }
        &:hover {
            background: #fff;
            color: #0292cd;
            &::before {
                background: url(./../../img/tekmar/exlink.svg) no-repeat center 96%;
                background-size: 16px 16px;
                width: 16px;
                height: 16px;
            }
        }
    }
    .h2_ttl {
        font-size: 48px;
        line-height: 32px;
        margin-bottom: 70px;
        position: relative;
        @include mq() {
            font-size: 32px;
            margin-bottom: 40px;
        }
        &::before {
            content: "";
            background: url(./../../img/tekmar/dot.svg) no-repeat top center;
            background-size: contain;
            position: absolute;
            bottom: -44px;
            left: 50%;
            transform: translateX(-50%);
            width: 8px;
            height: 29px;
            @include mq() {
                background-size: 5px 20px;
                bottom: -25px;
                width: 5px;
                height: 20px;
            }
        }
    }
    .h3_ttl {
        font-family: "ZenB";
        font-size: 22px;
        font-weight: 900;
        line-height: 1;
        letter-spacing: 0.025em;
        margin-bottom: 16px;
        @include mq() {
            font-size: 18px;
            line-height: 1.4;
            margin-bottom: 12px;
        }
    }
    /* main_visual */
    #main_visual {
        position: relative;
        &::before {
            content: "";
            background: url(./../../img/tekmar/bg_wave0.png) repeat-x bottom left;
            background-size: contain;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 11;
        }
        .box_txt {
            background: rgba(255, 255, 255, 0.75);
            color: #0096d4;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.025em;
            padding: 20px 0;
            position: absolute;
            bottom: 100px;
            left: 0;
            right: 0;
            border-radius: 80px;
            -moz-border-radius: 80px;
            -webkit-border-radius: 80px;
            z-index: 9999;
            @include mq() {
                font-size: 13px;
                line-height: 18px;
                letter-spacing: 0.01em;
                padding: 5% 7% 4%;
                bottom: 70px;
                text-align: left;
            }
            img {
                width: auto;
                @include mq() {
                    width: 200px;
                }
            }
            img.mr40 {
                @include mq() {
                    width: 80px;
                    margin-right: 10px;
                }
            }
            .txt {
                margin-top: 5px;
            }
            .fl-item {
                justify-content: center;
                align-items: flex-start;
                @include mq() {
                    text-align: center;
                    justify-content: center;
                }
            }
        }
    }
    #main_slider {
        overflow: hidden;
        .item {
            width: 100%;
            height: 100vh;
            position: relative;
            &::after {
                content: "";
                background-color: rgba(20, 19, 14, 0.16);
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
        }
        .item {
            background: url(./../../img/tekmar/slide-01.jpg) no-repeat center center;
            background-size: cover;
        }
        .item:nth-child(2) {
            background: url(./../../img/tekmar/slide-02.jpg) no-repeat center center;
            background-size: cover;
        }
        .item:nth-child(3) {
            background: url(./../../img/tekmar/slide-03.jpg) no-repeat center center;
            background-size: cover;
        }
        .item:nth-child(4) {
            background: url(./../../img/tekmar/slide-04.jpg) no-repeat center center;
            background-size: cover;
        }
        .item:nth-child(5) {
            background: url(./../../img/tekmar/slide-05.jpg) no-repeat center center;
            background-size: cover;
        }
        .item:nth-child(4)::after {
            content: "";
            background-color: transparent;
        }
        .slick-dots {
            list-style: none;
            position: absolute;
            display: flex;
            justify-content: space-around;
            left: 50%;
            bottom: 60px;
            height: 10px;
            width: 140px;
            transform: translate(-50%, 0);
            z-index: 111;
            @include mq() {
                bottom: 30px;
            }
            li {
                margin: 0;
                position: relative;
                &.slick-active button:before {
                    background-color: #0096d4;
                }
                button {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 9px;
                    height: 9px;
                    text-indent: -9999px;
                    &:before {
                        content: "" !important;
                        background-color: #fff;
                        border-radius: 4px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 9px;
                        height: 9px;
                    }
                }
            }
        }
    }
    .mr30 {
        margin-right: 30px;
    }
    .mr40 {
        margin-right: 40px;
        @include mq() {
            margin-right: 0;
        }
    }
    /* content_contact */
    #content_contact {
        background: #0096d4;
        color: #fff;
        padding: 60px 0 40px;
        @include mq() {
            padding: 0px 0 40px;
        }
        .inner {
            background: #0096d4 url(./../../img/tekmar/main_img_ft.png) no-repeat top left;
            background-size: 100% 100%;
            width: 92%;
            margin: 0 auto;
            max-width: 1230px;
            position: relative;
            padding: 160px 9.517vw 110px;
            @include mq() {
              background: #0096d4 url(./../../img/tekmar/main_img_ft.png) no-repeat top left 64%;
              background-size: auto 100%;
                padding: 40px 0;
            }
        }
        img {
            width: auto;
        }
        .h2_ttl {
            font-size: 60px;
            line-height: 1;
            margin-bottom: 35px;
            text-transform: uppercase;
            @include mq() {
                font-size: 34px;
                margin-bottom: 20px;
            }
        }
        .h2_ttl::before {
            display: none;
        }
        .box_contact {
            font-family: "ZenB";
            font-size: 18px;
            line-height: 28px;
            width: 50%;
            @include mq() {
                font-size: 15px;
                line-height: 24px;
                width: 100%;
                text-align: center;
            }
        }
        .box_contactDetail {
            max-width: 380px;
            @include mq() {
                max-width: 100%;
            }
        }
        .txt_tel {
            font-size: 34px;
            line-height: 27px;
            letter-spacing: 0.075em;
            @include mq() {
                // font-size: 22px;
                line-height: 1;
                margin-top: 24px;
            }
            .txt_phone {
                display: inline-block;
                background: url(./../../img/tekmar/icon_tel.png) no-repeat 0 2px;
                padding-left: 40px;
                @include mq() {
                    display: none;
                }
            }
            .txt_phone_sp {
                @include mq() {
                    color: #fff;
                    background: url(./../../img/tekmar/icon_tel.png) no-repeat 0 6px;
                    // background-size: 18px 18px;
                    display: inline-block;
                    padding-left: 30px;
                }
            }
            .txt_time {
                display: block;
                font-family: "ZenM";
                font-size: 12px;
                letter-spacing: 0.05em;
                margin: 5px 0 35px;
                @include mq() {
                    margin: 6px 0 16px;
                }
            }
            img {
                @include mq() {
                    width: 18px;
                    height: 18px;
                }
            }
        }
        .btn {
            background: #fff;
            color: #0292cd;
            display: inline-block;
            border-radius: 5px;
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            &:hover {
                background: #0292cd;
                color: #fff;
            }
        }
        .btn_contact {
            font-family: "ZenM";
            font-size: 22px;
            line-height: 27px;
            margin: auto;
            padding: 32px 0;
            position: relative;
            width: 380px;
            @include mq() {
                font-size: 18px;
                line-height: 24px;
                padding: 16px 0;
                width: 100%;
                max-width: 300px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            span {
                background-image: url(./../../img/tekmar/icon-out-mail.png);
                background-position: 0 6px;
                background-repeat: no-repeat;
                display: inline-block;
                padding-left: 40px;
                @include mq() {
                    background-image: url(./../../img/tekmar/icon-out-mail.png);
                    background-size: 20px 14px;
                    padding-left: 30px;
                }
            }
            &:hover {
                span {
                    background-image: url(./../../img/tekmar/icon-on-mail.png);
                    @include mq() {
                        background-image: url(./../../img/tekmar/icon-on-mail.png);
                    }
                }
            }
        }
    }
    /* content_about */
    #content_about {
        background: url(./../../img/tekmar/main_img.jpg) no-repeat top center;
        background-size: cover;
        font-family: "ZenB";
        font-size: 18px;
        font-weight: 700;
        line-height: 1.8;
        letter-spacing: 0;
        padding: 75px 0 140px;
        position: relative;
        @include mq() {
            background-size: cover;
            background-position: top center;
            font-size: 16px;
            line-height: 32px;
            letter-spacing: 0.025em;
            padding: 60px 0 80px;
        }
        &::before {
            content: "";
            background: url(./../../img/tekmar/bg_wave05.png) repeat-x bottom left;
            background-size: contain;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        .h2_ttl {
            font-family: "ZenB";
            font-size: 46px;
            font-weight: 900;
            line-height: 1.5;
            letter-spacing: 0.1em;
            @include mq() {
                font-size: 24px;
                line-height: 34px;
                margin-bottom: 30px;
            }
        }
        .h2_ttl::before {
            display: none;
        }
        .box_txt {
            @include mq() {
                font-family: 'ZenM';
                font-weight: 500;
                padding: 0 5%;
                text-align: left;
            }
        }
        .box_info {
            background-color: rgba(255, 255, 255, 0.7);
            border-radius: 15px;
            -moz-border-radius: 15px;
            -webkit-border-radius: 15px;
            margin-bottom: 85px;
            padding: 30px 40px 52px 40px;
            text-align: left;
            @include mq() {
                font-family: 'ZenM';
                font-size: 13px;
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 70px;
                padding: 4% 4% 8% 4%;
            }
            .ttl {
                color: #0292cd;
                font-size: 32px;
                line-height: 1;
                letter-spacing: 0.01em;
                margin-bottom: 15px;
                text-align: center;
                @include mq() {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 25px;
                    position: relative;
                }
                &::before {
                    @include mq() {
                        content: "";
                        background: url(./../../img/tekmar/sp_dot.png) no-repeat top center;
                        background-size: contain;
                        position: absolute;
                        bottom: -25px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 5px;
                        height: 20px;
                    }
                }
            }
            .clone {
                    border-bottom: 1px dotted #ccc;
                    padding-bottom: 6px;
                    margin-bottom: 8px;
                    &:hover {
                      opacity: 0.8;
                      transition: .3s ease;
                    }
            }
            .in_url {
                  display: flex;
                  @include mq() {
                   flex-flow: column wrap;
                }
            }
            .in_reg_date {
                  width: 11%;
                  color: #737679;
                  // padding-right: 16px;
                  font-family: "Titillium Web";
                  font-weight: 500;
                  font-size: 16px;
                  @include mq() {
                    font-size: 14px;
                    width: 100%;
                 }
            }
            .in_title {
                  width: 89%;
                  color: #737679;
                  font-size: 15px;
                  line-height: 24px;
                  @include mq() {
                    width: 100%;
                    font-size: 13px;
                 }
            }
            .btn_new {
                  position: relative;
            }
            .btn_more {
              background: url(./../../img/tekmar/icon-arrow_blue.svg) no-repeat center right;
              color: $blue02;
              margin-left: auto;
              display: inline-block;
              padding-right: 24px;
              text-transform: uppercase;
              transition: .3s ease;
              position: absolute;
              top: 0;
              right: 0;
              z-index: 10;
              &:hover {
                right: -10px;
                transition: .3s ease;
              }
            @include mq() {
              font-size: 16px;
              padding-right: 20px;
           }
        }
        }
    }
    .mb35 {
        margin-bottom: 35px;
        @include mq() {
            margin-bottom: 25px;
        }
    }
    /* content_service */
    #content_service {
        background: #fff url(./../../img/tekmar/bg_wave04.png) repeat-x bottom left;
        background-size: contain;
        padding: 80px 45px 120px;
        @include mq() {
            padding: 40px 0 80px;
        }
        .inner {
            width: auto;
            @include mq() {
                width: 82%;
            }
        }
        .list_service {
            justify-content: center;
            max-width: 1400px;
            margin: auto;
            li {
                color: #fff;
                font-size: 15px;
                line-height: 21px;
                margin: 0 40px 40px 0;
                max-width: 400px;
                position: relative;
                width: calc((100% - 80px) / 3);
                border-radius: 15px;
                -moz-border-radius: 15px;
                -webkit-border-radius: 15px;
                display: flex;
                flex-direction: column;
                @include mq() {
                    font-size: 14px;
                    margin: 0 0 30px 0;
                    max-width: 100%;
                    border-radius: 10px;
                    -moz-border-radius: 10px;
                    -webkit-border-radius: 10px;
                    width: 100%;
                }
                &>a {
                    color: $white;
                    display: block;
                    flex: 1;
                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                        .link_more {
                            right: 15px;
                            transition: .3s ease;
                        }
                    }
                }
                &:nth-child(3n),
                &:last-child {
                    margin-right: 0;
                }
                &:nth-child(3n) {
                    @include mq() {
                        margin-right: 0;
                    }
                }
                &:last-child {
                    @include mq() {
                        margin-bottom: 0;
                    }
                }
                .img {
                    overflow: hidden;
                    img {
                        width: 100%;
                        transition: transform 0.2s;
                    }
                }
                .box_txt {
                    letter-spacing: 0.06em;
                    padding: 30px 30px 60px;
                    @include mq() {
                        padding: 20px 15px 50px;
                    }
                }
                .ttl {
                    font-family: "ZenB";
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: 0.05em;
                    margin-bottom: 15px;
                }
                .link_more {
                    background: url(./../../img/tekmar/icon-arrow.svg) no-repeat center right;
                    color: #fff;
                    display: inline-block;
                    font-size: 16px;
                    line-height: 24px;
                    padding-right: 20px;
                    text-transform: uppercase;
                    transition: .3s ease;
                    position: absolute;
                    bottom: 25px;
                    right: 30px;
                    z-index: 10;
                    @include mq() {
                        font-weight: bold;
                        letter-spacing: 0.05em;
                        bottom: 15px;
                        right: 20px;
                    }
                }
            }
        }
    }
    /* content_products */
    #content_products {
        background: #fafafa url(./../../img/tekmar/bg_wave03.png) repeat-x bottom left;
        background-size: contain;
        padding: 80px 0 120px;
        @include mq() {
            padding: 40px 0 80px;
        }
        .inner {
            @include mq() {
                width: 82%;
            }
        }
        .list_products {
            margin: 40px 0 0;
            justify-content: flex-start;
            @include mq() {
                margin: 20px 0 0;
            }
            li {
                width: calc((100% - 60px) / 5);
                margin-right: 15px;
                margin-bottom: 20px;
                border-radius: 4px;
                -moz-border-radius: 4px;
                -webkit-border-radius: 4px;
                overflow: hidden;
                @include mq() {
                    width: 48%;
                    margin-right: 4%;
                    margin-bottom: 4%;
                }
                &:nth-child(5n) {
                    margin-right: 0;
                    @include mq() {
                        margin-right: 4%;
                    }
                }
                &:nth-child(2n) {
                    @include mq() {
                        margin-right: 0;
                    }
                }
                .txt {
                    background: #737679;
                    color: #fff;
                    font-size: 13px;
                    line-height: 14.3px;
                    letter-spacing: 0;
                    height: 38px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include mq() {
                        height: 50px;
                        line-height: 18px;
                        padding: 0 2px;
                    }
                }
                a:hover .txt {
                    background: #0096d4;
                }
                p.last {
                    letter-spacing: -0.05em;
                }
            }
        }
    }
    /* popup */
    .popup {
        display: none;
    }
    .modaal-inline {
        .modaal-container {
            background: #fafafa;
            font-size: 14px;
            line-height: 22.4px;
            max-width: 600px;
            border-radius: 5px;
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
        }
        .modaal-content-container {
            text-align: center;
            padding: 40px;
            @include mq() {
                padding: 40px 20px;
            }
        }
        .modaal-close {
            top: -25px;
            right: -25px;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
            width: 44px;
            height: 44px;
            @include mq() {
                width: 40px;
                height: 40px;
                top: -20px;
                right: -15px;
            }
        }
        .modaal-close:before,
        .modaal-close:after {
            width: 3px;
            height: 20px;
            top: 13px;
            left: 21px;
            @include mq() {
                top: 10px;
                left: 19px;
            }
        }
        .img img {
            width: auto;
        }
        .ttl {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 15px;
            text-align: center;
        }
        .txt {
            text-align: left;
            letter-spacing: 0;
            margin: 16px 0 40px;
        }
        .btn_more {
            background: #0096d4 url(./../../img/tekmar/icon-arrow.svg) no-repeat 96% center;
            color: #fff;
            display: block;
            font-size: 16px;
            line-height: 40px;
            max-width: 300px;
            margin: auto;
            text-transform: uppercase;
            border-radius: 5px;
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            &:hover {
                background-position: 98% center;
            }
        }
    }
    /* content_sector */
    #content_sector {
        background: #f1fdfe url(./../../img/tekmar/bg_wave02.png) repeat-x bottom left;
        background-size: contain;
        padding: 80px 0 120px;
        @include mq() {
            padding: 40px 0 80px;
        }
        .inner {
            width: 100%;
            @include mq() {}
        }
        .list_sector li {
            width: 50%;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            @include mq() {
                width: 100%;
                margin-bottom: 30px;
            }
            img {
                width: 100%;
            }
            &:last-child {
                @include mq() {
                    margin-bottom: 0;
                }
            }
        }
        .box_txt {
            background: #fff;
            border-radius: 20px;
            -moz-border-radius: 20px;
            -webkit-border-radius: 20px;
            margin: -80px 35px 0;
            padding: 35px;
            position: relative;
            flex: 1;
            z-index: 10;
            @include mq() {
                border-radius: 15px;
                -moz-border-radius: 15px;
                -webkit-border-radius: 15px;
                margin: -35px 9% 0;
                padding: 20px 20px 25px;
            }
            p {
                font-family: "ZenM";
                font-size: 14px;
                line-height: 1.6;
                letter-spacing: 0;
                margin-bottom: 25px;
                @include mq() {
                    font-size: 13px;
                    line-height: 21px;
                    margin-bottom: 15px;
                }
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    /* content_provision */
    #content_provision {
        background: url(./../../img/tekmar/bg_wave.png) repeat-x bottom left;
        background-size: contain;
        padding: 90px 0 180px;
        overflow: hidden;
        @include mq() {
            padding: 40px 0 80px;
            text-align: left;
        }
        .box_info {
            color: #0292cd;
            font-family: "ZenB";
            font-size: 17px;
            line-height: 1.8;
            letter-spacing: 0.025em;
            width: 45%;
        }
        .h2_ttl {
            margin-bottom: 25px;
            img {
                width: auto;
                @include mq() {
                    width: 210px;
                    margin: auto;
                }
            }
        }
        .h2_ttl::before {
            display: none;
        }
        .h5_ttl {
            color: #333;
            font-family: "ZenB";
            font-size: 24px;
            font-weight: 900;
            line-height: 34px;
            letter-spacing: 0.06em;
            margin-bottom: 90px;
            @include mq() {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 25px;
                text-align: left;
            }
        }
        .inner {
            position: relative;
        }
        .img {
            position: absolute;
            top: 120px;
            left: 47%;
            @include mq() {
                position: unset;
                margin-bottom: 30px;
            }
            img {
                @include mq() {
                    width: 100%;
                }
            }
        }
        .box_info {
            @include mq() {
                font-size: 16px;
                line-height: 28px;
                width: 100%;
            }
        }
        .box_links {
            align-items: center;
            justify-content: flex-start;
            margin-top: 35px;
            @include mq() {
                margin-top: 20px;
                align-items: flex-end;
            }
        }
        .btn_bl {
            font-family: "ZenM";
            font-size: 14px;
            line-height: 30px;
            margin-right: 30px;
            max-width: 300px;
            padding: 4px 0;
            width: 100%;
            @include mq() {
                max-width: 250px;
                margin-right: 20px;
                line-height: 28px;
                padding: 10px 0;
            }
            & ::before {
                @include mq() {
                    top: 15px;
                }
            }
        }
        .btn_twitter {
            line-height: 1;
        }
        .btn_twitter .fa {
            color: #0096d4;
            font-size: 40px;
            line-height: 40px;
        }
        .btn_twitter:hover .fa {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: 1250px) {
        #content_provision .img img {
            width: 50.462vw;
            @include mq() {
                width: 100%;
                margin: auto;
            }
        }
    }
    @media screen and (max-width: 1000px) {
        #content_provision .img {
            top: 150px;
        }
    }
    @media screen and (width: 320px) {
        #content_provision .btn_bl {
            margin-right: 14px;
        }
    }
}

.service_page {
    footer #footer {
        .footer_btn {
            justify-content: center;
            @include mq() {
                flex-direction: column-reverse;
                align-items: center;
            }
        }
        .btn_site {
            margin: 0 15px;
        }
        #btn_back {
            @include mq() {
                margin: 20px 0 0;
            }
            &::before {
                background: url(./../../img/tekmar/icon_arrow.png) no-repeat center 4%;
                right: unset;
                left: 12px;
                top: 11px;
            }
            &:hover {
                &::before {
                    background: url(./../../img/tekmar/icon_arrow_wh.png) no-repeat center 4%;
                }
            }
        }
    }
    .content_service {
        font-size: 18px;
        line-height: 21px;
        @include mq() {
            font-size: 16px;
        }
        img {
            width: auto;
            @include mq() {
                width: 100%;
            }
        }
        .inner {
            position: relative;
            z-index: 20;
            a {
                display: block;
            }
        }
        .logo_ttl {
            span {
                color: $white;
                display: block;
                font-family: "ZenB";
                font-size: 32px;
                line-height: 45px;
                margin-top: 25px;
                @include mq() {
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 15px;
                }
            }
        }
        .box_info {
            padding: 0 0 90px;
            @include mq() {
                padding: 0 0 20px;
            }
            position: relative;
            &::before {
                content: "";
                background: url(./../../img/tekmar/wave-s02.png) repeat-x bottom left;
                background-size: contain;
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 11;
            }
            .sbox {
                line-height: 28px;
                padding: 90px 0;
                @include mq() {
                    line-height: 24px;
                    padding: 40px 0;
                }
            }
        }
        .box_ttl {
            background-image: url(./../../img/tekmar/service_img02.jpg);
            background-repeat: no-repeat;
            background-position: top center;
            background-size: 100% 100%;
            padding: 130px 0 210px;
            @include mq() {
                padding: 60px 0;
            }
        }
        .box_link {
            color: $white;
            position: relative;
            padding: 90px 0 130px;
            @include mq() {
                padding: 40px 0 60px;
            }
            &::before {
                content: "";
                background-image: url(./../../img/tekmar/wave-s03.png);
                background-position: left bottom;
                background-repeat: repeat-x;
                background-size: contain;
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 11;
            }
            a {
                color: $white;
                background: $white;
                max-width: 480px;
                margin: 10px auto 0;
                padding: 18px 0;
                position: relative;
                border-radius: 5px;
                -moz-border-radius: 5px;
                -webkit-border-radius: 5px;
                @include mq() {
                    max-width: 360px;
                    padding: 12px 0;
                }
                img {
                    @include mq() {
                        width: 95px;
                    }
                }
                &:hover {
                    opacity: 0.8;
                }
                &::before {
                    content: "";
                    background-image: url(./../../img/tekmar/exlink-01.svg);
                    background-repeat: no-repeat;
                    background-position: center 96%;
                    background-size: contain;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 25px;
                    width: 27px;
                    height: 27px;
                    @include mq() {
                        background-size: 16px 16px;
                        right: 20px;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
        .h2_ttl {
            font-size: 38px;
            font-weight: 500;
            letter-spacing: 0.1em;
            @include mq() {
                font-size: 25px;
            }
            &::before {
                background-image: url(./../../img/tekmar/dot-01.svg);
                bottom: -44px;
                @include mq() {
                    background-size: 5px 20px;
                    bottom: -25px;
                    height: 20px;
                    width: 5px;
                }
            }
        }
        .list-01 {
            font-size: 16px;
            line-height: 26px;
            @include mq() {
                font-size: 14px;
                line-height: 21px;
            }
            li {
                width: 31%;
                @include mq() {
                    width: 100%;
                    margin-bottom: 30px;
                }
                img {
                    @include mq() {
                        width: 35px;
                    }
                }
            }
            .ttl {
                font-family: 'ZenB';
                font-size: 20px;
                margin: 25px 0 12px;
            }
        }
        .list-02 {
            justify-content: flex-start;
            li {
                background: #005b85;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0.01em;
                margin: 0 20px 20px 0;
                position: relative;
                width: 18.4%;
                max-width: 260px;
                height: 160px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                border-radius: 20px;
                -moz-border-radius: 20px;
                -webkit-border-radius: 20px;
                @include mq() {
                    font-size: 16px;
                    width: 30.666%;
                    margin-right: 4%;
                    height: 140px;
                    @include mq(msp) {
                        width: 48% !important;
                        margin-right: 4%;
                    }
                }
                &>a {
                    color: #fff;
                    display: flex;
                    align-items: center;
                    flex: 1;
                    padding: 0 15px 0 20px;
                    @include mq() {
                        padding: 0 15px;
                    }
                    &:hover {
                        .link_more {
                            right: 15px;
                            transition: .3s ease;
                        }
                    }
                }
                &:nth-child(2n) {
                    @include mq() {
                        margin-right: 4%;
                        @include mq(msp) {
                            margin-right: 0;
                        }
                    }
                }
                &:nth-child(3n) {
                    @include mq() {
                        margin-right: 0;
                        @include mq(msp) {
                            margin-right: 4%;
                        }
                    }
                }
                &:nth-child(6n) {
                    @include mq() {
                        margin-right: 0;
                        @include mq(msp) {
                            margin-right: 0;
                        }
                    }
                }
                &:nth-child(5n),
                &:last-child {
                    margin-right: 0;
                    @include mq() {
                        margin-right: 4%;
                    }
                }
            }
            .link_more {
                background: url(./../../img/tekmar/icon-arrow.svg) no-repeat center right;
                color: #fff;
                display: inline-block;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.05em;
                padding-right: 20px;
                text-transform: uppercase;
                transition: .3s ease;
                position: absolute;
                bottom: 15px;
                right: 20px;
                z-index: 10;
                @include mq() {
                    background-size: 14px 12px;
                    font-size: 14px;
                    bottom: 10px;
                }
            }
        }
    }
    #main_visual {
        &::before {
            background: url(./../../img/tekmar/wave-s01.png) repeat-x bottom left;
            background-size: contain;
        }
        .item {
            background: url(./../../img/tekmar/service_img01.jpg) no-repeat top center;
            background-size: cover;
            width: 100%;
            height: 100vh;
            @include mq() {
                height: 60vh;
            }
        }
        .h2_ttl {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            &::before {
                display: none;
            }
            img {
                width: auto;
                @include mq() {
                    width: 50vw;
                }
            }
        }
    }
    #link_services {
        background: #fff;
        padding: 70px 0 140px;
        position: relative;
        @include mq() {
            padding: 40px 0;
        }
        &::before {
            content: "";
            background: url(./../../img/tekmar/wave-s02.png) repeat-x bottom left;
            background-size: contain;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 11;
        }
        a:hover {
            opacity: 0.8;
        }
        ul {
            @include mq() {
                justify-content: flex-start;
            }
        }
        ul>li {
            position: relative;
            @include mq() {
                width: 30.5%;
                margin: 0 4% 5% 0;
            }
            &:nth-child(3n) {
                @include mq() {
                    margin-right: 0;
                }
            }
            &::before {
                content: '';
                background: #7e7d7e;
                width: 2px;
                height: 40px;
                position: absolute;
                right: -22px;
                top: 50%;
                transform: translateY(-50%);
                @include mq() {
                    display: none;
                }
            }
            &:last-child {
                &::before {
                    display: none;
                }
            }
            img {
                @include mq() {
                    width: 100%;
                }
            }
        }
    }
    #content_ryder {
        .logo_ttl {
            img {
                @include mq() {
                    width: 41.867vw;
                }
            }
        }
    }
    #content_agiletek {
        .box_ttl {
            background-image: url(./../../img/tekmar/service_img03.jpg);
        }
        .box_info::before {
            background-image: url(./../../img/tekmar/wave-s03.png);
        }
        .box_link::before {
            background-image: url(./../../img/tekmar/wave-s04.png);
        }
        .h2_ttl::before {
            background-image: url(./../../img/tekmar/dot-02.svg);
        }
        .logo_ttl {
            img {
                @include mq() {
                    width: 42.133vw;
                }
            }
        }
        a::before {
            background-image: url(./../../img/tekmar/exlink-02.svg);
        }
        .list-02 {
            li {
                background: #00856b;
                width: 26%;
            }
        }
    }
    #content_subsea {
        .box_ttl {
            background-image: url(./../../img/tekmar/service_img04.jpg);
        }
        .box_info::before {
            background-image: url(./../../img/tekmar/wave-s04.png);
        }
        .box_link::before {
            background-image: url(./../../img/tekmar/wave-s05.png);
        }
        .h2_ttl::before {
            background-image: url(./../../img/tekmar/dot-03.svg);
        }
        .logo_ttl {
            img {
                @include mq() {
                    width: 36.533vw;
                }
            }
        }
        a::before {
            background-image: url(./../../img/tekmar/exlink-03.svg);
        }
        .list-02 {
            li {
                background: #032d63;
            }
        }
    }
    #content_tekmar {
        .box_ttl {
            background-image: url(./../../img/tekmar/service_img05.jpg);
        }
        .box_info::before {
            background-image: url(./../../img/tekmar/wave-s05.png);
        }
        .box_link::before {
            background-image: url(./../../img/tekmar/wave-s06.png);
        }
        .h2_ttl::before {
            background-image: url(./../../img/tekmar/dot-04.svg);
        }
        .logo_ttl {
            img {
                @include mq() {
                    width: 37.467vw;
                }
            }
        }
        a::before {
            background-image: url(./../../img/tekmar/exlink-04.svg);
        }
        .list-02 {
            li {
                background: #1797d5;
                width: 23.5%;
                &:nth-child(5n) {
                    margin-right: 20px;
                }
                &:nth-child(4n) {
                    margin-right: 0;
                }
            }
        }
    }
    #content_pipeshield {
        .box_ttl {
            background-image: url(./../../img/tekmar/service_img06.jpg);
        }
        .box_info::before {
            background-image: url(./../../img/tekmar/wave-s06.png);
        }
        .box_link::before {
            background: none;
        }
        .h2_ttl::before {
            background-image: url(./../../img/tekmar/dot-05.svg);
        }
        .logo_ttl {
            img {
                @include mq() {
                    width: 40.267vw;
                }
            }
        }
        a::before {
            background-image: url(./../../img/tekmar/exlink-05.svg);
        }
        .list-02 {
            li {
                background: #00496b;
                width: 23.5%;
                @include mq() {
                    width: 30.666%;
                }
                &:nth-child(5n) {
                    margin-right: 20px;
                    @include mq() {
                        margin-right: 4%;
                    }
                }
                &:nth-child(4n) {
                    margin-right: 0;
                    @include mq() {
                        margin-right: 4%;
                        @include mq(msp) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}