.contact {
  h2 {
    font-weight: normal;
    margin-bottom: 60px;
    .en {
      color: #2167a0;
      font-size: 58px;
      font-family: $font-en;
      line-height: 68px;
      display: block;
      margin-bottom: 30px;
      @include mq {
        font-size: 45px;
        line-height: 45px;
        margin-bottom: 15px;
      }
    }
    .jp {
      font-size: 20px;
      line-height: 30px;
      display: block;
    }
  }
  .sec-01 {
    background: #f5f5f9;
    padding: 120px 0 140px;
    position: relative;
    @include mq {
      padding: 60px 0 70px;
    }
    &::after,
    &::before {
      content: "";
      background: url(/img/contact/bg_01.png) no-repeat left top;
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      right: 0;
      height: 760px;
      width: 820px;
      @include mq {
        height: 204px;
        width: 220px;
      }
    }
    &::before {
      background: url(/img/contact/bg_02.png) no-repeat left top;
      background-size: 100% 100%;
      top: auto;
      right: auto;
      left: 0;
      bottom: 0;
    }
    .check-code {
      position: absolute;
      bottom: 170px;
      right: 0;
      width: 95px;
      z-index: 10;
      @include mq {
        bottom: 370px;
        width: 60px;
      }
    }
    .form-block {
      border-top: 2px solid #ccc;
      padding: 80px 0 0;
      position: relative;
      z-index: 5;
      @include mq {
        padding: 40px 0 0;
      }
    }
    .step-list {
      position: absolute;
      top: -132px;
      right: 0;
      @include mq {
        top: -92px;
      }
      li {
        color: #b2b2b6;
        font-size: 16px;
        padding: 0 30px 5px;
        text-align: center;
        position: relative;
        @include mq {
          font-size: 14px;
        }
        &:not(:last-child)::after {
          content: "";
          background: #b2b2b6;
          height: 40px;
          width: 1px;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%) rotate(40deg);
        }
        span {
          display: block;
          font-size: 13px;
          font-family: $font-en;
          @include mq {
            font-size: 12px;
          }
        }
        &.current {
          color: #2167a0;
          &::before {
            content: "";
            background: #2167a0;
            height: 2px;
            width: 50px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .blc {
      .box-l {
        line-height: 30px;
        width: 22%;
        @include mq {
          margin-top: 50px;
          order: 2;
          width: 100%;
        }
        .txt01 {
          padding: 50px 0 0;
          font-size: 16px;
          @include mq {
            padding: 30px 0 0;
          }
          span {
            color: #2167a0;
            font-family: $font-en;
          }
          a {
            color: #2167a0;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
        .txt02 {
          font-size: 13px;
        }
      }
      .box-r {
        width: 65%;
        @include mq {
          order: 1;
          width: 100%;
        }
        dl {
          dt {
            font-size: 16px;
            @include mq {
              font-size: 14px;
            }
            .en-txt {
              display: inline-block;
              color: #4e5c6b;
              font-size: 13px;
              font-family: $font-en;
              margin: 0 10px;
              @include mq {
                font-size: 12px;
                margin: 0 5px;
              }
            }
            &:nth-child(n + 2) {
              margin-top: 40px;
              @include mq {
                margin-top: 20px;
              }
            }
          }
          dd {
            margin-top: 10px;
            input[type="text"],
            input[type="password"],
            input[type="tel"],
            textarea,
            select {
              background: #fff;
              border: 1px solid #e4e4ec;
              font-size: 16px;
              padding: 16px;
              outline: none;
              width: 100%;
              border-radius: 3px;
              @include mq {
                font-size: 14px;
                padding: 12px;
              }
            }
            ::-webkit-input-placeholder {
              /* Edge */
              color: #b6b6b6;
            }
            :-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #b6b6b6;
            }
            ::placeholder {
              color: #b6b6b6;
            }
            textarea {
              height: 140px;
            }
            select {
              -webkit-appearance: none;
              appearance: none;
              cursor: pointer;
              &::-ms-expand {
                display: none;
              }
            }
            &.cnt_item09 {
              position: relative;
              &:after {
                border-top: 6.5px solid #767689;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                content: "";
                position: absolute;
                right: 20px;
                top: 46%;
                width: 0;
              }
            }
          }
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .error-txt {
        color: #e81818;
        padding-top: 5px;
        img {
          margin-right: 5px;
          width: 16px;
          vertical-align: middle;
        }
      }
      .request-txt {
        background: #2167a0;
        display: inline-block;
        font-size: 12px;
        color: #fff;
        width: 40px;
        text-align: center;
      }
    }
    .box {
      background: #e6e6f0;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      padding: 40px 70px;
      margin-top: 40px;
      @include mq {
        padding: 10px;
        margin-top: 20px;
      }
      .error-txt {
        text-align: center;
      }
    }
    .policy-box {
      background: $white;
      border: 1px solid #ccc;
      font-size: 13px;
      height: 200px;
      overflow: auto;
      padding: 40px 20px;
      margin-bottom: 20px;
      @include mq {
        padding: 20px 10px;
      }
      .policy-ttl {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 30px;
        text-align: center;
        @include mq {
          padding-bottom: 15px;
        }
      }
      .sub-ttl {
        border-bottom: 1px solid #e0e0e0;
        color: #2167a0;
        font-size: 13px;
        margin: 20px 0 10px;
      }
      /* width */
      &::-webkit-scrollbar {
        width: 5px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #dedede;
        border-radius: 50px;
        -webkit-border-radius: 50px;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #dedede;
      }
    }
    .agree {
      text-align: center;
      .checkmark {
        background: #fff;
        border-color: #cdcdcd;
      }
      .checkmark:after {
        border-color: #2167a0;
      }
      a {
        text-decoration: underline;
        color: $color-yt;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .submit-box {
      margin-top: 30px;
      text-align: center;
      label {
        background: #2167a0;
        display: inline-block;
        cursor: pointer;
        color: #fff;
        font-size: 17px;
        padding: 20px 0;
        width: 400px;
        text-align: center;
        transition: ease 0.3s;
        @include mq {
          font-size: 14px;
          padding: 15px 0;
          width: 100%;
        }
        input {
          display: none;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  &.en_lang {
    h2 {
      .jp {
        display: none;
      }
    }
    .sec-01 {
      .blc {
        .box-r {
          dl {
            dt {
              .en-txt {
                display: none;
              }
            }
          }
        }
      }
      .request-txt {
        width: auto;
        margin-left: 10px;
        padding: 0 4px 0 4px;
      }
    }
  }
}
