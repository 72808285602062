.business_overview {
    .header .h-inner .logo a:after {
        background: url(./../../img/common/logo03.png) no-repeat;
        background-size: contain;
    }
    // .footer .sns-list li a,
    // .footer .sns-list li a:visited,
    // .footer .sns-list li a:hover {
    //     color: #0766b4;
    // }
    .cmn-mainv:after {
        background: url(./../../img/business_overview/mask.png) no-repeat top left;
        background-size: cover;
    }
    .cmn-mainv .sns-list li a {
        color: $white;
        &:hover {
            color: #7c7c7c;
        }
    }
    .breadcrumb ul li {
        line-height: 24px;
    }
    .breadcrumb ul li:first-child:before {
        background: url(./../../img/common/icon_home.svg) no-repeat;
        background-size: contain;
    }
    section {
        width: 100%;
        min-height: 680px;
        padding: 150px 0;
        @include mq {
            padding: 80px 0;
        }
        .box {
            color: $white;
            font-size: 14px;
            line-height: 32px;
            letter-spacing: 0.05em;
            text-align: justify;
            width: 50%;
            @include mq {
                line-height: 28px;
                width: 100%;
            }
            .ttl01 {
                font-size: 34px;
                font-weight: 400;
                line-height: 50px;
                margin-bottom: 30px;
                @include mq {
                    font-size: 22px;
                    line-height: 30px;
                    margin-bottom: 15px;
                }
            }
            .btn01 {
                background: #ffffff;
                color: #2167a0;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-top: 50px;
                height: 70px;
                width: 365px;
                position: relative;
                @include mq {
                    width: 100%;
                    max-width: 365px;
                    margin: 50px auto 0;
                    height: 60px;
                }
                &:hover {
                    background: #2167a0;
                    color: #ffffff;
                    &::after {
                        background: url(./../../img/common/icon_arrow03.png) no-repeat center center;
                        background-size: 100% 100%;
                    }
                }
                &::after {
                    content: "";
                    background: url(./../../img/common/icon_arrow04.png) no-repeat center center;
                    background-size: 100% 100%;
                    height: 14px;
                    width: 14px;
                    position: absolute;
                    top: 50%;
                    right: 30px;
                    transform: translateY(-50%);
                    transition: ease 0.3s;
                }
            }
        }
        .boxR {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
    .sec {
        &-business {
            background: url(./../../img/business_overview/01.jpg) no-repeat bottom center;
            background-size: cover;
            @include mq {
              background: url(./../../img/business_overview/01_sp.jpg) no-repeat bottom center;
            }
        }
        &-operation {
            background: url(./../../img/business_overview/02.jpg) no-repeat bottom center;
            background-size: cover;
        }
        &-project {
            background: url(./../../img/business_overview/03.jpg) no-repeat bottom center;
            background-size: cover;
        }
        &-service {
            background: url(./../../img/business_overview/service.jpg) no-repeat bottom center;
            background-size: cover;
        }
    }
    .jp_ttl {
        font-size: 24px;
        line-height: 56px;
        margin-top: 15px;
        @include mq {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
        }
    }
}

.domestic {
    .domestic_bg {
        position: relative;
        padding: 140px 0;
        min-height: unset;
        z-index: 0;
        overflow: hidden;
        .img_bg {
          content: '';
          background: inherit;
          -webkit-filter: blur(2px);
          -moz-filter: blur(2px);
          -o-filter: blur(2px);
          -ms-filter: blur(2px);
          filter: blur(2px);
          position: absolute;
          top: -2px;
          left: -2px;
          right: -2px;
          bottom: -2px;
          z-index: -1;
          @include mq {
            content: unset;
            height: 100%;
            width: auto;
            object-fit: cover;
        }
      }
      @include mq {
          padding: 60px 0;
      }
    }
    .domestic_bg::after {
        content: '';
        background: url(./../../img/business_overview/mask-02.png) no-repeat top center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
    }
    section .box {
        width: 100%;
        font-size: 18px;
        line-height: 36px;
        letter-spacing: normal;
        @include mq {
            font-size: 14px;
            line-height: 28px;
        }
    }
    .domestic_work {
        background: #f5f5f9;
        letter-spacing: 0;
        padding: 80px 0;
        @include mq {
            padding: 40px 0;
        }
        .box_work {
            margin: 0 0 40px;
            @include mq {
                margin: 0 0 20px;
            }
            .img {
                width: 56%;
                @include mq {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
            .box_info {
                font-size: 15px;
                line-height: 32px;
                width: 40%;
                @include mq {
                    font-size: 13px;
                    line-height: 24px;
                    width: 100%;
                }
                .h3_ttl {
                    font-size: 30px;
                    font-weight: 400;
                    line-height: 38px;
                    letter-spacing: 0.05em;
                    margin: 15px 0 30px;
                    @include mq {
                        font-size: 20px;
                        line-height: 24px;
                        margin: 10px 0 15px;
                    }
                }
                .txt_tag {
                    background: #2167a0;
                    border-radius: 20px;
                    color: $white;
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 32px;
                    margin-right: 12px;
                    padding: 0 20px;
                    @include mq {
                        font-size: 12px;
                        line-height: 24px;
                        margin-right: 8px;
                        padding: 0 10px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .list_links {
            li {
                margin: 0 20px 20px 0;
                position: relative;
                text-align: center;
                width: calc((100% - 80px) / 5);
                @include mq {
                    width: calc((100% - 20px) / 2);
                }
                a {
                    background: #3ca0e6;
                    border: 1px solid #3ca0e6;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    line-height: 24px;
                    min-height: 130px;
                    padding: 15px;
                    width: 100%;
                    @include mq {
                        font-size: 14px;
                    }
                    &:hover {
                        background: $white;
                        color: #3ca0e6;
                        .txt_tag {
                            border: 1px solid #3ca0e6;
                            color: #3ca0e6;
                        }
                        &::after {
                            content: '';
                            background-image: url(./../../img/business_overview/icon_arrow_bl.png);
                        }
                    }
                    &::after {
                        content: '';
                        background-image: url(./../../img/business_overview/icon_arrow_wh.png);
                        background-repeat: no-repeat;
                        background-position: 0 0;
                        background-size: contain;
                        width: 14px;
                        height: 15px;
                        position: absolute;
                        right: 15px;
                        bottom: 15px;
                    }
                }
                &:nth-child(5n) {
                    margin-right: 0;
                    @include mq {
                        margin-right: 20px;
                    }
                }
                &:nth-child(2n) {
                    @include mq {
                        margin-right: 0;
                    }
                }
            }
            .tags {
                font-size: 12px;
                line-height: 22px;
                position: absolute;
                left: 15px;
                top: 15px;
                @include mq {
                    font-size: 10px;
                    line-height: 18px;
                }
            }
            .txt_tag {
                background: transparent;
                border: 1px solid #fff;
                border-radius: 20px;
                color: $white;
                display: inline-block;
                margin-right: 10px;
                padding: 0 10px;
                @include mq {
                    padding: 0 05px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}.business_overview {
    .header .h-inner .logo a:after {
        background: url(./../../img/common/logo03.png) no-repeat;
        background-size: contain;
    }
    // .footer .sns-list li a,
    // .footer .sns-list li a:visited,
    // .footer .sns-list li a:hover {
    //     color: #0766b4;
    // }
    .cmn-mainv:after {
        background: url(./../../img/business_overview/mask.png) no-repeat top left;
        background-size: cover;
    }
    .cmn-mainv .sns-list li a {
        color: $white;
        &:hover {
            color: #7c7c7c;
        }
    }
    .breadcrumb ul li {
        line-height: 24px;
    }
    .breadcrumb ul li:first-child:before {
        background: url(./../../img/common/icon_home.svg) no-repeat;
        background-size: contain;
    }
    section {
        width: 100%;
        min-height: 680px;
        padding: 150px 0;
        @include mq {
            padding: 80px 0;
        }
        .box {
            color: $white;
            font-size: 14px;
            line-height: 32px;
            letter-spacing: 0.05em;
            text-align: justify;
            width: 50%;
            @include mq {
                line-height: 28px;
                width: 100%;
            }
            .ttl01 {
                font-size: 34px;
                font-weight: 400;
                line-height: 50px;
                margin-bottom: 30px;
                @include mq {
                    font-size: 22px;
                    line-height: 30px;
                    margin-bottom: 15px;
                }
            }
            .btn01 {
                background: #ffffff;
                color: #2167a0;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-top: 50px;
                height: 70px;
                width: 365px;
                position: relative;
                @include mq {
                    width: 100%;
                    max-width: 365px;
                    margin: 50px auto 0;
                    height: 60px;
                }
                &:hover {
                    background: #2167a0;
                    color: #ffffff;
                    &::after {
                        background: url(./../../img/common/icon_arrow03.png) no-repeat center center;
                        background-size: 100% 100%;
                    }
                }
                &::after {
                    content: "";
                    background: url(./../../img/common/icon_arrow04.png) no-repeat center center;
                    background-size: 100% 100%;
                    height: 14px;
                    width: 14px;
                    position: absolute;
                    top: 50%;
                    right: 30px;
                    transform: translateY(-50%);
                    transition: ease 0.3s;
                }
            }
        }
        .boxR {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
    .sec {
        &-business {
            background: url(./../../img/business_overview/01.jpg) no-repeat bottom center;
            background-size: cover;
        }
        &-operation {
            background: url(./../../img/business_overview/02.jpg) no-repeat bottom center;
            background-size: cover;
        }
        &-project {
            background: url(./../../img/business_overview/03.jpg) no-repeat bottom center;
            background-size: cover;
        }
        &-service {
            background: url(./../../img/business_overview/service.jpg) no-repeat bottom center;
            background-size: cover;
        }
    }
    .jp_ttl {
        font-size: 24px;
        line-height: 56px;
        margin-top: 15px;
        @include mq {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
        }
    }
}

.domestic {
    .domestic_bg {
        position: relative;
        padding: 140px 0;
        min-height: unset;
        z-index: 0;
        overflow: hidden;
    }

    section .box {
        width: 100%;
        font-size: 18px;
        line-height: 36px;
        letter-spacing: normal;
        @include mq {
            font-size: 14px;
            line-height: 28px;
        }
    }
    .domestic_work {
        background: #f5f5f9;
        letter-spacing: 0;
        padding: 80px 0;
        @include mq {
            padding: 40px 0;
        }
        .box_work {
            margin: 0 0 40px;
            @include mq {
                margin: 0 0 20px;
            }
            .img {
                width: 56%;
                @include mq {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
            .box_info {
                font-size: 15px;
                line-height: 32px;
                width: 40%;
                @include mq {
                    font-size: 13px;
                    line-height: 24px;
                    width: 100%;
                }
                .h3_ttl {
                    font-size: 30px;
                    font-weight: 400;
                    line-height: 38px;
                    letter-spacing: 0.05em;
                    margin: 15px 0 30px;
                    @include mq {
                        font-size: 20px;
                        line-height: 24px;
                        margin: 10px 0 15px;
                    }
                }
                .txt_tag {
                    background: #2167a0;
                    border-radius: 20px;
                    color: $white;
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 32px;
                    margin-right: 12px;
                    padding: 0 20px;
                    @include mq {
                        font-size: 12px;
                        line-height: 24px;
                        margin-right: 8px;
                        padding: 0 10px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .list_links {
            justify-content: flex-start;
            li {
                margin: 0 20px 20px 0;
                position: relative;
                text-align: center;
                width: calc((100% - 80px) / 5);
                @include mq {
                    width: calc((100% - 20px) / 2);
                }
                a {
                    background: #3ca0e6;
                    border: 1px solid #3ca0e6;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    line-height: 24px;
                    min-height: 130px;
                    padding: 15px;
                    width: 100%;
                    @include mq {
                        font-size: 14px;
                    }
                    &:hover {
                        background: $white;
                        color: #3ca0e6;
                        .txt_tag {
                            border: 1px solid #3ca0e6;
                            color: #3ca0e6;
                        }
                        &::after {
                            content: '';
                            background-image: url(./../../img/business_overview/icon_arrow_bl.png);
                        }
                    }
                    &::after {
                        content: '';
                        background-image: url(./../../img/business_overview/icon_arrow_wh.png);
                        background-repeat: no-repeat;
                        background-position: 0 0;
                        background-size: contain;
                        width: 14px;
                        height: 15px;
                        position: absolute;
                        right: 15px;
                        bottom: 15px;
                    }
                }
                &:nth-child(5n) {
                    margin-right: 0;
                    @include mq {
                        margin-right: 20px;
                    }
                }
                &:nth-child(2n) {
                    @include mq {
                        margin-right: 0;
                    }
                }
            }
            .tags {
                font-size: 12px;
                line-height: 22px;
                position: absolute;
                left: 15px;
                top: 15px;
                @include mq {
                    font-size: 10px;
                    line-height: 18px;
                }
            }
            .txt_tag {
                background: transparent;
                border: 1px solid #fff;
                border-radius: 20px;
                color: $white;
                display: inline-block;
                margin-right: 10px;
                padding: 0 10px;
                @include mq {
                    padding: 0 05px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}